import { Instance, types } from 'mobx-state-tree';

const AccessLevel = types.model('AccessLevel', {
  code: types.string,
  name: types.string
});

export const allAccessLevel: IAccessLevel[] = [
  
  AccessLevel.create({ name: 'All', code: 'All' }),
  AccessLevel.create({ name: 'All Devices', code: 'AllDevices' }),
  AccessLevel.create({ name: 'Case', code: 'Case' }),
  //HCMA CR
  AccessLevel.create({ name: 'Create Customer', code: 'CreateCustomer' }),
  AccessLevel.create({ name: 'Dealer Devices', code: 'DealerDevices' }),
  AccessLevel.create({ name: 'Delivery Reports', code: 'DeliveryReports' }),
  AccessLevel.create({ name: 'Device Back Orders', code: 'DeviceBackOrders' }),
  AccessLevel.create({ name: 'Device Inquiries', code: 'DeviceInquiries' }),
  AccessLevel.create({ name: 'Device Invoices', code: 'DeviceInvoices' }),
  AccessLevel.create({ name: 'Device Orders', code: 'DeviceOrders' }),
  AccessLevel.create({ name: 'Device Quotations', code: 'DeviceQuotation' }),
  AccessLevel.create({ name: 'Device Status', code: 'DeviceStatus' }),
  //HCMA CR
  AccessLevel.create({ name: 'Machine Movements', code: 'MachineMovements' }),
  AccessLevel.create({ name: 'Part Inquiry', code: 'PartInquiry' }),
  AccessLevel.create({ name: 'Part Orders', code: 'PartsOrders' }),
  AccessLevel.create({ name: 'Parts Back Orders', code: 'PartsBackOrders' }),
  AccessLevel.create({ name: 'Parts Invoices', code: 'PartsInvoices' }),
  AccessLevel.create({ name: 'Parts Quotation', code: 'PartsQuotation' }),
  AccessLevel.create({ name: 'Parts Return Orders', code: 'ReturnOrders' }),
  AccessLevel.create({ name: 'Parts Transaction', code: 'PartsTransactions' }),
  AccessLevel.create({ name: 'Register Schedule Service', code: 'RegisterMaintenance' }),
  AccessLevel.create({ name: 'Service Campaigns', code: 'ServiceCampaigns' }),
  AccessLevel.create({ name: 'Warranty Claims', code: 'WarrantyClaims' }),
];

export default AccessLevel;
export interface IAccessLevel extends Instance<typeof AccessLevel> {}
