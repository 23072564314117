import { Instance, types } from 'mobx-state-tree';

const Theme = types.model('Theme', {
  logo: types.maybeNull(types.string),
  menuLogo: types.maybeNull(types.string),
  backgroundLogo: types.maybeNull(types.string),
  headerLogoStyles: types.maybeNull(types.string),
  loginLogoStyles: types.maybeNull(types.string),

  loginImage: types.maybeNull(types.string),
  frontPagePartsImage: types.maybeNull(types.string),
  frontPageDeviceImage: types.maybeNull(types.string),
  frontPageServiceImage: types.maybeNull(types.string),
  frontPageWarrantyImage: types.maybeNull(types.string),
  frontPageAdminImage: types.maybeNull(types.string),
  //HCMA CR
  frontPageCustomerIamge: types.maybeNull(types.string),

  // Colors
  mainColor: types.maybeNull(types.string),
  secondaryColor: types.maybeNull(types.string),
  errorColor: types.maybeNull(types.string),
  mainButtonColor: types.maybeNull(types.string),
  secondaryButtonColor: types.maybeNull(types.string),
  errorButtonColor: types.maybeNull(types.string),
  mainFieldColor: types.maybeNull(types.string),
  secondaryFieldColor: types.maybeNull(types.string),
  errorFieldColor: types.maybeNull(types.string)
});

export default Theme;
export interface ITheme extends Instance<typeof Theme> {}
