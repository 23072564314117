import { OktaAuth, TokenManagerError } from "@okta/okta-auth-js";
import { user } from "../models/User";
import history from "../../../config/history";
import { OktaAuthService } from "./okta-auth-service";

export class OktaAuthenticationContext {
  public isAuthenticationConfigured = false;
  private oktaAuth: OktaAuth;

  constructor() {
    this.oktaAuth = OktaAuthService.getOktaAuthInstance();
  }

  public async login(): Promise<any> {
    this.oktaAuth.token.getWithRedirect({
      responseType: ["token", "id_token"],
    });


  }

  public logout() {
    this.oktaAuth.signOut();
    this.oktaAuth.tokenManager.clear();
    this.oktaAuth.stop();
  }

  public async handleAlreadyLoggedIn() {
    const tokens = this.oktaAuth.tokenManager.getTokensSync();

    if (tokens.idToken && tokens.accessToken) {
      //user.setOktaAccessToken(tokens.accessToken.accessToken);
      user.returnedOktaAccountInfo(tokens.idToken);

      if (user.dealer === null) {
        const status: any = await user.getDealers();
        if (status === "success") {
          if (user.dealers.length <= 1) {
            if (user.dealers.length === 0) {
              user.setDealerError("No dealers found on your account please contact your system administrator");
            } else {
              await user.setDealer(user.dealers[0].id);
              user.hideOktaSignInDialog();
              if (user.dealer) {
                return true;
              }
            }
          } else {
            user.showOktaDealerDialog();
          }
        } else {
          user.setMembershipError(
            "Your account is not activated in service portal. Please contact your system administrator to send the invite."
          );
          user.showSignInDialogAfterOkta();
        }
      } else {
        return true;
      }
    }
  }

  public handleRedirect() {
    const isLoginRedirect = this.oktaAuth.isLoginRedirect();

    if (isLoginRedirect) {
      user.setAuthenticating(true);
      this.oktaAuth.storeTokensFromRedirect().then(async () => {
        const tokens = this.oktaAuth.tokenManager.getTokensSync();
        if (tokens.idToken && tokens.accessToken) {
          //user.setOktaAccessToken(tokens.accessToken.accessToken);
          user.returnedOktaAccountInfo(tokens.idToken);

          if (user.dealer === null) {
            const status: any = await user.getDealers();
            if (status === "success") {
              if (user.dealers.length <= 1) {
                if (user.dealers.length === 0) {
                  user.setDealerError("No dealers found on your account please contact your system administrator");
                } else {
                  user.setDealer(user.dealers[0].id);
                  user.hideOktaSignInDialog();
                }
              } else {
                user.showOktaDealerDialog();
              }
            } else {
              user.setMembershipError(
                "Your account is not activated in service portal. Please contact your system administrator to send the invite."
              );
              user.showSignInDialogAfterOkta();
            }
          } else {
            user.hideOktaSignInDialog();
            history.push("/");
          }
        }
        user.setAuthenticating(false);
      });
    }
  }
}
