import { OktaAuth, TokenManagerError } from "@okta/okta-auth-js";
import { getOIDCConfig } from "./okta-authentication-config";

export class OktaAuthService {
  private static oktaAuthInstance: OktaAuth;

  public static getOktaAuthInstance(): OktaAuth {
    if (!this.oktaAuthInstance) {
      console.log("create new OktaAuth instance");
      this.oktaAuthInstance = new OktaAuth(getOIDCConfig());
      this.oktaAuthInstance.start();
    }

    return this.oktaAuthInstance;
  }

  public static async getAccessToken(): Promise<string | undefined> {
    const oktaAuth = this.getOktaAuthInstance();

    let token = oktaAuth.getAccessToken();

    if (token && oktaAuth.tokenManager.hasExpired(token)) {
        await oktaAuth.tokenManager.renew("accessToken");
        token = oktaAuth.getAccessToken();

        if (!token || oktaAuth.tokenManager.hasExpired(token)) {
          token = undefined;
        }
    }

    return token;
  }
}
