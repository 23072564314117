import { ISite } from "../../../helpers/typeHelpers";
import { user } from "../../userManagement/models/User";

const customerSiteMap: ISite[] = [];

if (user.hasAccessLevel(['CreateCustomer', 'All'])) {
    customerSiteMap.push({
      label: 'Create Customer',
      path: '/customer/create-customer',
      children: [],
      showInMenu: true
    });
}  

export default customerSiteMap;