import { AccountInfo } from "@azure/msal-browser";
import { applySnapshot, cast, clone, flow, getSnapshot } from "mobx-state-tree";

import { IRedeemUser, IUser } from ".";
import history from "../../../../config/history";
import { getPath } from "../../../../helpers/helperFunctions";
import { A365Online } from "../../../../helpers/requests";
import { activity } from "../../../base/models/Activity/index";
import { config } from "../../../base/models/Config";
import AzureAuthenticationContext from "../../azure/azure-authentication-context";
import { allAccessLevel, IAccessLevel } from "../AccessLevel";
import { IDealer } from "../Dealer/interface";
import { dealerConfiguration } from "../DealerConfiguration";
import { allRoles, IRole } from "../Role";
import { formatMemberResponse } from "./helpers";
import { IDToken } from "@okta/okta-auth-js";
import { OktaAuthenticationContext } from "../../okta/okta-authentications-context";

export default (self: any) => ({
  setEmail(email: string | React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    if (typeof email === "string") {
      self.userEmail = email;
    } else {
      self.userEmail = email.target.value;
    }
  },

  setPhoneNumber(phoneNumber: string | React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    if (typeof phoneNumber === "string") {
      self.phoneNumber = phoneNumber;
    } else {
      self.phoneNumber = phoneNumber.target.value;
    }
  },

  setemail(email: string | React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    if (typeof email === "string") {
      self.userEmail = email;
    } else {
      self.userEmail = email.target.value;
    }
  },

  setErrorMessage(error: string) {
    self.errorMessage = error;
  },

  returnedAccountInfo: flow(function* returnedAccountInfo(b2CUser: AccountInfo) {
    if (b2CUser.username) {
      self.b2cUserName = b2CUser.username;
      self.b2cAuthenticated = true;
      self.localAccountId = b2CUser.localAccountId;
    }
  }),

  returnedOktaAccountInfo: flow(function* returnedOktaAccountInfo(oktaUser: IDToken) {
    if (oktaUser.claims.email || oktaUser.claims.preferred_username) {
      self.oktaUserName = oktaUser.claims.email ? oktaUser.claims.email : oktaUser.claims.preferred_username;
      self.oktaAuthenticated = true;
    }
  }),

  authenticate: flow(function* authenticate(password: string, email: string, rememberMe?: boolean) {
    self.authenticating = true;
    self.errorMessage = null;
    self.userEmail = email;
    try {
      const authResp: any = yield A365Online.Authenticate(email, password, rememberMe || false);

      if (authResp && authResp.status === "success") {
        self.lockSignInDialog = false;
        self.lastLoggedIn = new Date();
        applySnapshot(self, { ...self, ...authResp });

        if (authResp.dealers && authResp.dealers.length === 1) {
          self.authenticating = false;
          self.displaySignInDialog = false;
          yield self.setDealer(authResp.dealers[0].id);
        }

        self.timeoutToken(true);
      } else {
        self.errorMessage = "Invalid e-mail or password. Please try again.";
      }

      return authResp.status;
    } catch (err: any) {
      return "error";
    } finally {
      self.authenticating = false;
    }
  }),

  timeoutToken: flow(function* timeoutToken(signingIn = false) {
    const expireAfter = self.jwt.exp * 1000 - new Date().valueOf();

    if (signingIn && expireAfter < 0) {
      activity.addMessage("server error sign in expired", "error");
      return;
    }

    yield new Promise((resolve) => {
      setTimeout(() => {
        resolve(self.refreshToken());
      }, Math.max(expireAfter - 60000, 0));
    });
  }),

  refreshToken: flow(function* refreshToken() {
    if (self.signedIn) {
      try {
        if (!self.lastLoggedIn || activity.lastActivity.valueOf() > self.lastLoggedIn.valueOf()) {
          const authResp: any = yield A365Online.Post("users/refresh");
          self.authToken = authResp ? authResp.authToken : null;
          self.lastLoggedIn = new Date();
        } else {
          self.showSigninDialog(true);
        }
      } catch (err: any) {
        self.showSigninDialog(true);
      }
    }
  }),

  showSignOutDialog(show: boolean) {
    if (!self.authenticating) {
      self.displaySignOutDialog = show;
    }
  },

  signOut() {
    try {
      if (self.userLoginB2C) {
        const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();
        self.authenticating = true;
        authenticationModule.logoutB2C();
        dealerConfiguration.clear();
        sessionStorage.clear();
        self.clear();
        config.updateUserLoginB2C(false);
      } else if (self.oktaAuthenticated){
        const oktaAuthContext = new OktaAuthenticationContext();
        oktaAuthContext.logout();
        dealerConfiguration.clear();
        sessionStorage.clear();
        self.clear();
      } else {
        self.authenticating = true;
        dealerConfiguration.clear();
        sessionStorage.clear();
        self.clear();
        history.push("/sign-in");
        window.location.reload();
      }
    } finally {
      self.authenticating = false;
      // TODO: find a better fix
    }
  },

  clear() {
    applySnapshot(self, {});
    sessionStorage.clear();
    localStorage.removeItem("user");
    localStorage.removeItem("dealerConfiguration");
  },

  showSigninDialog(lockDialog = false) {
    if (
      getPath() === "/forgot-password" ||
      getPath()
        .substr(1)
        .split("/")[0] === "redeem"
    ) {
      self.displaySignInDialog = false;
      return;
    }
    if (self.userLoginB2C) {
      self.signOut();
    } else {
      self.dealer = null;
      self.dealers = cast([]);
      applySnapshot(self, {});
      self.displaySignInDialog = !self.resettingPassword;
      self.lockSignInDialog = lockDialog;
    }
  },

  showSignInDialogAfterB2C(lockDialog = false) {
    self.displayB2CSignInDialog = true;
    self.lockB2CSignInDialog = lockDialog;
  },

  hideOktaSignInDialog(){
    self.displayOktaSignInDialog = false;
  },

  showSignInDialogAfterOkta() {
    self.displayOktaSignInDialog = true;
  },

  hideSignInDialog() {
    if (!self.lockSignInDialog) {
      self.displaySignInDialog = false;
    }
  },

  hideB2CSignInDialog() {
    if (!self.lockB2CSignInDialog) {
      self.displayB2CSignInDialog = false;
    }
  },

  showDealerDialog() {
    self.displayDealerDialog = true;
  },

  hideDealerDialog() {
    self.displayDealerDialog = false;
  },

  showB2CDealerDialog() {
    self.displayB2CDealerDialog = true;
  },

  hideB2CDealerDialog() {
    self.displayB2CDealerDialog = false;
  },

  showOktaDealerDialog() {
    self.displayOktaDealerDialog = true;
  },

  hideOktaDealerDialog() {
    self.displayOktaDealerDialog = false;
  },

  setDealer: flow(function* setDealer(dealerId) {
    try {
      if ((self.dealer && dealerId !== self.dealer.id) || !self.dealer) {
        sessionStorage.clear();
        self.authenticating = true;
        // self.roles = allRoles.find((role: IRole) => role.name === self.jwt.role);
        if (self.b2cAuthenticated) {
          if (config.useADOption === "true") {
            const body = {
              dealerId: dealerId,
              email: self.b2cUserName,
            };

            const resp = yield A365Online.ADPost("AuthorizeAzureAd", body);
            self.authToken = resp ? resp.authToken : null;
          } else {
            const body = {
              dealerId: dealerId,
              email: self.b2cUserName,
            };

            const resp = yield A365Online.ADPost("AuthorizeAzureAdB2C", body);
            self.authToken = resp ? resp.authToken : null;
          }
        } else if (self.oktaAuthenticated) {
          const body = {
            dealerId: dealerId,
            email: self.oktaUserName,
          };

          const resp = yield A365Online.OktaPost("AuthorizeOkta", body);
          self.authToken = resp ? resp.authToken : null;
        } else {
          const body = { dealerId };
          const resp = yield A365Online.Post("Authorize", body);
          self.authToken = resp ? resp.authToken : null;
        }
        self.lastLoggedIn = new Date();
        self.authenticating = false;
        self.dealer = dealerId;

        const roles: IRole[] = [];

        for (const role of allRoles) {
          if (self.jwt[role.code] === "1") {
            roles.push(role);
          }
        }

        self.setRoles(roles);
        if (self.jwt["systemUser"] === "1") {
          config.updateSystemUser(true);
        } else {
          config.updateSystemUser(false);
        }
        const accessLevels: IAccessLevel[] = [];

        for (const accessLevel of allAccessLevel) {
          if (self.jwt[accessLevel.code] === "1") {
            accessLevels.push(accessLevel);
          }
        }

        self.setAccessLevel(accessLevels);

        if (getPath() === "/sign-in") {
          history.push("/");
        }

        window.location.reload();
      }
    } catch (err: any) {
      activity.addMessage(err.Message || "An unexpected error occurred while authenticating dealer", "error");
      self.authenticating = false;
    }
  }),

  setDealerError(error: string) {
    self.dealerError = error;
  },

  setMembershipError(error: string) {
    self.membershipError = error;
  },

  setResettingPassword(resettingPassword: boolean) {
    self.resettingPassword = resettingPassword;
  },

  setRoles(roles: IRole[] | React.ChangeEvent<any>) {
    if (Array.isArray(roles)) {
      self.roles = roles.map((role) => clone(role));
    } else {
      self.roles = [roles.target.value].reduce((newRoles: IRole[], roleId: string) => {
        const newRole = allRoles.find((role) => role.code === roleId);
        if (newRole) {
          newRoles.push(clone(newRole));
        }
        return newRoles;
      }, []);
    }
  },

  setDateFormat(dateFormat: string | React.ChangeEvent<any>) {
    self.dateFormat = dateFormat;
  },

  setAccessLevel(accessLevels: IAccessLevel[] | React.ChangeEvent<any>) {
    if (Array.isArray(accessLevels)) {
      self.accessLevels = accessLevels.map((accessLevel) => clone(accessLevel));
    } else {
      self.accessLevels = accessLevels.target.value.reduce((newAccessLevels: IAccessLevel[], accessLevelId: string) => {
        const newAccessLevel = allAccessLevel.find((accessLevel) => accessLevel.code === accessLevelId);
        if (newAccessLevel) {
          newAccessLevels.push(clone(newAccessLevel));
        }
        return newAccessLevels;
      }, []);
    }
  },

  resetPassword: flow(function* resetPassword(password: string, token: string) {
    try {
      yield A365Online.Post("PasswordReset/claim", { token, password }, true);
      activity.addMessage("Password changed", "success");
      history.push("/sign-in");
    } catch {
      activity.addMessage("An error occurred while resetting the password", "error");
    }
  }),

  forgotPassword: flow(function* forgotPassword(email: string) {
    try {
      yield A365Online.Post(
        "PasswordReset",
        {
          email,
          callbackUrl: `${window.location.origin}/forgot-password/{0}`,
        },
        true
      );
      history.push("/sign-in");
      activity.addMessage("E-mail has been sent check your email for the instructions", "success");
    } catch (e) {
      self.resetPasswordEmail = false;
      activity.addMessage("An error occurred while processing forgotPassword", "error");
    }
  }),

  setName(name: string | React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    if (typeof name === "string") {
      self.name = name;
    } else {
      self.name = name.target.value;
    }
  },

  setDisabled(disabled: boolean | React.ChangeEvent<HTMLInputElement>) {
    if (typeof disabled === "boolean") {
      self.disabled = disabled;
    } else {
      self.disabled = disabled.target.checked;
    }
  },

  sendInvitation: flow(function* sendInvitation() {
    try {
      const userRoles = self.roles.map((role) => role.code);
      const userAccessLevels = self.accessLevels.map((accessLevel) => accessLevel.code);
      const resp: any = yield A365Online.Post("Invites", {
        email: self.userEmail,
        name: self.name,
        callbackUrl: `${window.location.origin}/redeem/{0}?inviteB2C=${config.userLoginB2C ? "true" : "false"}`,
        userRoles,
        userAccessLevels,
      });
      activity.addMessage("Invitation email has been sent to the user", "success");
      if (resp.warningMessage) {
        activity.addMessage(resp.warningMessage, "warning");
      }
    } catch (error: any) {
      activity.addMessage(error.Message || "An error occurred while inviting the user", "error");
    }
  }),

  updateUser: flow(function* updateUser(
    roles: IRole[],
    accessLevels: IAccessLevel[],
    masterAdmin: boolean,
    disabled: boolean
  ) {
    self.updating = true;
    try {
      const body: any = {
        id: self.id,
        roles: roles.map((role) => role.code),
        accessLevels: accessLevels.map((accessLevel) => accessLevel.code),
        masterAdmin: masterAdmin,
        disabled: disabled,
      };

      yield A365Online.Put(`Memberships/${self.id}`, body);

      self.roles = clone(roles);
      self.accessLevels = clone(accessLevels);
      self.masterAdmin = masterAdmin;
      self.disabled = disabled;
      self.updating = false;
    } catch (err: any) {
      self.updating = false;
    }
  }),
  updateCurrentUser: flow(function* updateCurrentUser(modifiedUser?: any) {
    self.updating = true;
    try {
      if (modifiedUser) {
        self.name = modifiedUser.name;
        self.phoneNumber = modifiedUser.phoneNumber;
      }
      const body: any = {};
      if (self.name) {
        body.name = self.name;
      }
      if (self.phoneNumber) {
        body.phoneNumber = self.phoneNumber;
      }
      yield A365Online.Put("CurrentUser", body);
      self.updating = false;
    } catch (err: any) {
      self.updating = false;
      activity.addMessage("An error occurred while updating the user", "error");
    }
  }),

  redeemUser: flow(function* redeemUser(password: string, userLoginB2C: boolean) {
    const body: IRedeemUser = {
      token: self.inviteToken || "",
      password,
      userLoginB2C,
    };
    self.updating = true;
    try {
      const resp: any = yield A365Online.Post("/Invites/claim", body, true);
      activity.addMessage(
        "Your account has been successfully activated, You may now sign in and get started with dealer portal",
        "success"
      );
      if (resp) {
        history.push("/sign-in");
      }
    } catch {
      activity.addMessage("Failed to activate user", "error");
      return null;
    } finally {
      self.updating = false;
    }
  }),

  validateUser: flow(function* validateUser(token: string) {
    self.updating = true;
    self.isAuthTokenException = true;
    try {
      const resp: any = yield A365Online.Get(`Invites/validate/${token}`);
      if (resp.status === 200) {
        self.isValidate = true;
      }
      if (resp.StatusCode === 409) {
        activity.addMessage(resp.Message, "error");
        self.isValidate = false;
      }
    } catch (err: any) {
      self.isValidate = false;
      activity.addMessage(err.Message || "Failed to validate user", "error");
    } finally {
      self.updating = false;
    }
  }),

  downloadUserTemplate: flow(function* downloadUserTemplate() {
    try {
      self.updating = true;
      yield A365Online.OpenWithAuthentication(`Invites/importXLSM`, "Users.xlsm");
    } catch (err: any) {
      activity.addMessage(err.Message || 'Failed to download user template', 'error');
    } finally {
      self.updating = false;
    }
  }),

  importUsers: flow(function* importUsers(file: File) {
    try {
      self.updating = true;
      const callBackUrl = `${window.location.origin}/redeem/{0}`;
      const resp: any = yield A365Online.fileUpload(`Invites/import?callbackUrl=${callBackUrl}`, file);
      activity.addMessage("Invitation email has been sent to the all imported users", "success");
      if (resp.warningMessage) {
        activity.addMessage(resp.warningMessage, "warning");
      }
    } catch (error: any) {
      activity.addMessage(
        error.Message || "An error occurred while sending the invitation to the imported users",
        "error"
      );
    } finally {
      self.updating = false;
    }
  }),

  setUser(user: IUser) {
    const snap = getSnapshot(user);
    applySnapshot(self, { ...self, ...snap });
  },

  updateToken(token: string) {
    self.authToken = token;
  },

  updateInviteToken(token: string) {
    self.inviteToken = token;
  },

  deleteUser: flow(function* deleteUser() {
    try {
      yield A365Online.Delete(`/Memberships/${self.id}`);
      activity.addMessage("Successfully deleted user", "success");
      return "success";
    } catch (err: any) {
      activity.addMessage(err.Message || "Failed to delete user", "error");
      return "error";
    }
  }),

  getCurrentUserFromAPI: flow(function* getCurrentUserFromAPI() {
    try {
      const resp: any = yield A365Online.Get("CurrentUser");
      if (resp) {
        applySnapshot(self, { ...self, ...resp });
      }
    } catch (err: any) {
      activity.addMessage(err.Message || "Failed to get updated information on user", "error");
    }
  }),

  isAdmin: flow(function* isAdmin() {
    if (self.admin) {
      return self.admin;
    }
    yield self.getCurrentUserFromAPI();
    return self.admin;
  }),

  reloadStart: flow(function* reload(item: string) {
    try {
      if (self.reloading) {
        activity.addMessage("You are already loading something, please wait wile it finishes", "info");
      } else {
        self.reloading = true;
        yield A365Online.Post(`admin/reloads/${item}/start`, {});
        self.reloading = false;
      }
    } catch {
      activity.addMessage("Failed to get reload", "error");
      self.reloading = false;
    }
  }),

  reloadStop: flow(function* reload(item: string) {
    try {
      if (self.reloading) {
        activity.addMessage("You are already loading something, please wait wile it finishes", "info");
      } else {
        self.reloading = true;
        yield A365Online.Post(`admin/reload/${item}/stop`, {});
        self.reloading = false;
      }
    } catch {
      activity.addMessage("Failed to get reload", "error");
      self.reloading = false;
    }
  }),

  setFieldsConfigurationsTab(tab: number) {
    self.fieldConfigurationsTab = tab;
  },

  setDeviceModuleFieldConfigurationsTab(tab: number) {
    self.deviceModuleFieldConfigurationsTab = tab;
  },

  setPartModuleFieldConfigurationTab(tab: number) {
    self.partModuleFieldConfigurationTab = tab;
  },

  setServiceModuleFieldConfigurationTab(tab: number) {
    self.serviceModuleFieldConfigurationTab = tab;
  },

  setSyncStatusConfigurationTab(tab: number) {
    self.syncStatusConfigurationTab = tab;
  },

  updateDateFormatConfiguration: flow(function* updateDateFormatConfiguration() {
    self.updating = true;
    try {
      const resp: any = yield A365Online.Put("ConfigurableFields/Date", { dateFormat: self.dateFormat });
      if (resp) {
        applySnapshot(self, { ...self, ...resp.dateFormat });
      }
    } catch (err: any) {
      self.updating = false;
      activity.addMessage("An error occurred while updating the date format", "error");
    } finally {
      self.updating = false;
    }
  }),

  updatePortalMaintenanceMessage: flow(function* updatePortalMaintenanceMessage() {
    self.updating = true;
    try {
      const resp: any = yield A365Online.Put("ConfigurableFields/PortalMaintenance", {
        portalMaintenanceMessage: self.portalMaintenanceMessage,
        portalMaintenanceURL: self.portalMaintenanceURL,
      });
      if (resp) {
        applySnapshot(self, { ...self, ...resp.portalMaintenanceMessage });
        applySnapshot(self, { ...self, ...resp.portalMaintenanceURL });
      }
    } catch (err: any) {
      self.updating = false;
      activity.addMessage("An error occurred while updating the portal maintenance message", "error");
    } finally {
      self.updating = false;
    }
  }),

  setPortalMaintenanceMessage(
    message: string | React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) {
    if (typeof message === "string") {
      self.portalMaintenanceMessage = message;
    } else {
      self.portalMaintenanceMessage = message.target.value;
    }
  },

  setPortalMaintenanceLink(
    link: string | React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) {
    if (typeof link === "string") {
      self.portalMaintenanceURL = link;
    } else {
      self.portalMaintenanceURL = link.target.value;
    }
  },

  updateUserLoginB2C() {
    self.userLoginB2C = true;
  },

  enableAuthentication() {
    self.authenticating = true;
  },

  disableAuthentication() {
    self.authenticating = false;
  },

  setMasterAdmin() {
    self.masterAdmin = !self.masterAdmin;
  },

  chooseDealer(dealer: IDealer) {
    if (self !== null) {
      self.setDealer(dealer.id);
      self.hideDealerDialog();
      self.hideB2CDealerDialog();
      self.hideOktaDealerDialog();
      self.hideSignInDialog();
      self.hideB2CSignInDialog();
      self.hideOktaSignInDialog();
    }
  },

  setADAccessToken(token: string) {
    self.adAccessToken = token;
  },

  setAuthenticating(value: boolean) {
    self.authenticating = value;
  },

  getDealers: flow(function* getDealers() {
    try {
      self.authenticating = true;
      let resp: any;
      if (self.oktaAuthenticated) {
        resp = yield A365Online.GetDealersOkta(self.oktaUserName);
      }
      else {
        resp = yield A365Online.GetDealers(self.b2cUserName);
      }

      if (resp.results) {
        self.dealers = resp.results;
      }
      return "success";
    } catch (err: any) {
      return "error";
    } finally {
      self.authenticating = false;
    }
  }),
});
