import { applySnapshot, flow } from 'mobx-state-tree';
import {
  IConfigurationDeviceOrderRequest,
  IConfigurationPartsOrderRequest,
  IConfigurationPartsTransactionRequest,
  IConfigurationWarrantyClaimRequest,
  IConfigurationDeviceInquiryRequest,
  IConfigurationDeliveryReportRequest,
  IConfigurationPartInquiryRequest,
  IConfigurationAllDevicesRequest,
  IConfigurationServiceCampaignRequest,
  IConfigurationPartInvoicesRequest,
  IConfigurationDeviceInvoicesRequest,
  IConfigurationDeviceBackOrderRequest,
  IConfigurationPartBackOrderRequest,
  IConfigurationPartReturnOrderRequest,
  IConfigurationCaseRequest,
  IConfigurationDealerDevicesRequest,
  IConfigurationPartsQuotationRequest,
  IConfigurationDeviceQuotationRequest
} from '.';
import { A365Online } from '../../../../helpers/requests';
import { activity } from '../../../base/models/Activity';


export default (self: any) => ({
  getDealerPortalFieldsConfiguration: flow(
    function* getDealerPortalFieldsConfiguration() {
      try {
        self.configurationLoading = true;
        const resp: any = yield A365Online.Get("CurrentUser");
        if (
          resp.caseConfigurableFieldsViewModels ||
          resp.deliveryReportConfigurableFields ||
          resp.deviceAllConfigurableFields ||
          resp.deviceBackOrderConfigurableFieldsViewModels ||
          resp.deviceInquiryConfigurableFields ||
          resp.deviceInvoiceConfigurableFieldsViewModels ||
          resp.deviceOrderConfigurableFields ||
          resp.partBackOrderConfigurableFieldsViewModels ||
          resp.partInquiryConfigurableFields ||
          resp.partInvoiceConfigurableFieldsViewModels ||
          resp.partOrderConfigurableFields ||
          resp.partsTransactionConfigurableFieldsViewModels ||
          resp.partReturnOrderConfigurableFieldsViewModels ||
          resp.serviceCampaignConfigurableFieldsViewModels ||
          resp.warrantyConfigurableFields ||
          resp.deviceDealerConfigurableFields ||
          resp.partsQuotationConfigurableFieldsViewModels ||
          resp.deviceQuotationConfigurableFieldsViewModels
        ) {
          applySnapshot(self, {
            ...self,
            ...resp.caseConfigurableFieldsViewModels,
          });
          applySnapshot(self, {
            ...self,
            ...resp.deliveryReportConfigurableFields,
          });
          applySnapshot(self, { ...self, ...resp.deviceAllConfigurableFields });
          applySnapshot(self, {
            ...self,
            ...resp.deviceBackOrderConfigurableFieldsViewModels,
          });
          applySnapshot(self, {
            ...self,
            ...resp.deviceInquiryConfigurableFields,
          });
          applySnapshot(self, {
            ...self,
            ...resp.deviceInvoiceConfigurableFieldsViewModels,
          });
          applySnapshot(self, {
            ...self,
            ...resp.deviceOrderConfigurableFields,
          });
          applySnapshot(self, {
            ...self,
            ...resp.partBackOrderConfigurableFieldsViewModels,
          });
          applySnapshot(self, {
            ...self,
            ...resp.partsTransactionConfigurableFieldsViewModels,
          });
          applySnapshot(self, {
            ...self,
            ...resp.partInquiryConfigurableFields,
          });
          applySnapshot(self, {
            ...self,
            ...resp.partInvoiceConfigurableFieldsViewModels,
          });
          applySnapshot(self, { ...self, ...resp.partOrderConfigurableFields });
          applySnapshot(self, {
            ...self,
            ...resp.partReturnOrderConfigurableFieldsViewModels,
          });
          applySnapshot(self, {
            ...self,
            ...resp.serviceCampaignConfigurableFieldsViewModels,
          });
          applySnapshot(self, { ...self, ...resp.warrantyConfigurableFields });
          applySnapshot(self, {
            ...self,
            ...resp.deviceDealerConfigurableFields,
          });
          applySnapshot(self, {
            ...self,
            ...resp.partQuotationConfigurableFieldsViewModels,
          });
          applySnapshot(self, {
            ...self,
            ...resp.deviceQuotationConfigurableFieldsViewModels,
          });
        }
      } catch (err: any) {
        activity.addMessage(
          err.Message || "Failed to get updated information on user",
          "error"
        );
      } finally {
        self.configurationLoading = false;
      }
    }
  ),

  updateWarrantyClaimConfiguration: flow(
    function* updateWarrantyClaimConfiguration() {
      self.fetching = true;
      try {
        const body = self.createWarrantyClaimRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/Warranty",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),
  updatePartsOrderConfiguration: flow(
    function* updatePartsOrderConfiguration() {
      self.fetching = true;
      try {
        const body = self.createPartsOrderRequest();
        const resp: any = yield A365Online.Put("ConfigurableFields/Part", body);
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),
  updatePartsTransactionConfiguration: flow(
    function* updatePartsTransactionConfiguration() {
      self.fetching = true;
      try {
        const body = self.createPartsTransactionRequest();
        const resp: any = yield A365Online.Put("ConfigurableFields/PartTransaction", body);
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),
  updateDeviceOrderConfiguration: flow(
    function* updateDeviceOrderConfiguration() {
      self.fetching = true;
      try {
        const body = self.createDeviceOrderRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/Device",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),
  updateDeviceInquiryConfiguration: flow(
    function* updateDeviceInquiryConfiguration() {
      self.fetching = true;
      try {
        const body = self.createDeviceInquiryRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/DeviceInquiry",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),
  updateDeliveryReportConfiguration: flow(
    function* updateDeliveryReportConfiguration() {
      self.fetching = true;
      try {
        const body = self.createDeliveryReportRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/DeliveryReport",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),
  updateAllDevicesConfiguration: flow(
    function* updateAllDevicesConfiguration() {
      self.fetching = true;
      try {
        const body = self.createAllDevicesRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/DeviceAll",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),

  updateDealerDevicesConfiguration: flow(
    function* updateDealerDevicesConfiguration() {
      self.fetching = true;
      try {
        const body = self.createDealerDevicesRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/DeviceDealer",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),

  updatePartInquiryConfiguration: flow(
    function* updatePartInquiryConfiguration() {
      self.fetching = true;
      try {
        const body = self.createPartInquiryRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/PartInquiry",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),

  updateServiceCampaignConfiguration: flow(
    function* updateServiceCampaignConfiguration() {
      self.fetching = true;
      try {
        const body = self.createServiceCampaignRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/ServiceCampaign",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),

  updatePartInvoicesConfiguration: flow(
    function* updatePartInvoicesConfiguration() {
      self.fetching = true;
      try {
        const body = self.createPartInvoicesRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/PartInvoice",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),

  updateDeviceInvoicesConfiguration: flow(
    function* updateDeviceInvoicesConfiguration() {
      self.fetching = true;
      try {
        const body = self.createDeviceInvoicesRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/DeviceInvoice",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),

  updateDeviceBackOrderConfiguration: flow(
    function* updateDeviceBackOrderConfiguration() {
      self.fetching = true;
      try {
        const body = self.createDeviceBackOrderRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/DeviceBackOrder",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),

  updatePartBackOrderConfiguration: flow(
    function* updatePartBackOrderConfiguration() {
      self.fetching = true;
      try {
        const body = self.createPartBackOrderRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/PartBackOrder",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),

  updatePartReturnOrderConfiguration: flow(
    function* updatePartReturnOrderConfiguration() {
      self.fetching = true;
      try {
        const body = self.createPartReturnOrderRequest();
        const resp: any = yield A365Online.Put(
          "ConfigurableFields/PartReturnOrder",
          body
        );
        if (resp) {
          applySnapshot(self, { ...self, ...resp });
        }
      } catch (err) {
        self.fetching = false;
        activity.addMessage(
          "An error occurred while updating the configuration",
          "error"
        );
      } finally {
        self.fetching = false;
      }
    }
  ),

  updateCaseConfiguration: flow(function* updateCaseConfiguration() {
    self.fetching = true;
    try {
      const body = self.createCaseRequest();
      const resp: any = yield A365Online.Put("ConfigurableFields/Case", body);
      if (resp) {
        applySnapshot(self, { ...self, ...resp });
      }
    } catch (err) {
      self.fetching = false;
      activity.addMessage(
        "An error occurred while updating the configuration",
        "error"
      );
    } finally {
      self.fetching = false;
    }
  }),

  updatePartsQuotationConfiguration: flow(function* updatePartsQuotationConfiguration() {
    self.fetching = true;
    try {
      const body = self.createPartsQuotationRequest();
      const resp: any = yield A365Online.Put("ConfigurableFields/PartsQuotation", body);
      if (resp) {
        applySnapshot(self, { ...self, ...resp });
      }
    } catch (err) {
      self.fetching = false;
      activity.addMessage(
        "An error occurred while updating the configuration",
        "error"
      );
    } finally {
      self.fetching = false;
    }
  }),

  updateDeviceQuotationConfiguration: flow(function* updateDeviceQuotationConfiguration() {
    self.fetching = true;
    try {
      const body = self.createDeviceQuotationRequest();
      const resp: any = yield A365Online.Put("ConfigurableFields/DeviceQuotation", body);
      if (resp) {
        applySnapshot(self, { ...self, ...resp });
      }
    } catch (err) {
      self.fetching = false;
      activity.addMessage(
        "An error occurred while updating the configuration",
        "error"
      );
    } finally {
      self.fetching = false;
    }
  }),

  setWebClaimNumLine() {
    self.webClaimNumLine = !self.webClaimNumLine;
  },
  setClaimNumLine() {
    self.claimNumLine = !self.claimNumLine;
  },
  setClaimNameLine() {
    self.claimNameLine = !self.claimNameLine;
  },
  setDeviceNumberLine() {
    self.deviceNumberLine = !self.deviceNumberLine;
  },
  setClaimTypeLine() {
    self.claimTypeLine = !self.claimTypeLine;
  },
  setCreatedDateLine() {
    self.createdDateLine = !self.createdDateLine;
  },
  setCreatedByLine() {
    self.createdByLine = !self.createdByLine;
  },
  setStatusLine() {
    self.claimStatusLine = !self.claimStatusLine;
  },
  setApprovalStatusLine() {
    self.approvalStatusLine = !self.approvalStatusLine;
  },
  setTotalClaimedAmountLine() {
    self.totalClaimedAmountLine = !self.totalClaimedAmountLine;
  },
  setTotalApprovedAmountLine() {
    self.totalApprovedAmountLine = !self.totalApprovedAmountLine;
  },
  setWarrantyClaimListPreWorkApproval() {
    self.warrantyClaimListPreWorkApproval = !self.warrantyClaimListPreWorkApproval;
  },
  setWarrantyClaimEmailNotification() {
    self.warrantyClaimEmailNotification = !self.warrantyClaimEmailNotification;
  },
  setWarrantyClaimDownloadStatement() {
    self.warrantyClaimDownloadStatement = !self.warrantyClaimDownloadStatement;
  },
  setWarrantyClaimDownloadInvoice() {
    self.warrantyClaimDownloadInvoice = !self.warrantyClaimDownloadInvoice;
  },
  setDeviceBrand() {
    self.deviceBrand = !self.deviceBrand;
  },
  setDeviceClass() {
    self.deviceClass = !self.deviceClass;
  },
  setDeviceModel() {
    self.deviceModel = !self.deviceModel;
  },
  setDeviceModelCode() {
    self.deviceModelCode = !self.deviceModelCode;
  },
  setDeviceServiceModel() {
    self.deviceServiceModel = !self.deviceServiceModel;
  },
  setDeviceNumber() {
    self.deviceNumber = !self.deviceNumber;
  },
  setDeviceRegistrationNumber() {
    self.deviceRegistrationNumber = !self.deviceRegistrationNumber;
  },
  setDeviceSerialNumber() {
    self.deviceSerialNumber = !self.deviceSerialNumber;
  },
  setDeviceVINNumber() {
    self.deviceVINNumber = !self.deviceVINNumber;
  },
  setDeviceUsage() {
    self.deviceUsage = !self.deviceUsage;
  },
  setDeviceBackOrderDeleteOption() {
    self.deviceBackOrderDeleteOption = !self.deviceBackOrderDeleteOption;
  },
  setPartsBackOrderDeleteOption() {
    self.partsBackOrderDeleteOption = !self.partsBackOrderDeleteOption;
  },
  setClaimName() {
    self.claimName = !self.claimName;
  },
  setClaimGroup() {
    self.claimGroup = !self.claimGroup;
  },
  setCausalPart() {
    self.causalPart = !self.causalPart;
  },
  setServiceCampaignNumber() {
    self.serviceCampaignNumber = !self.serviceCampaignNumber;
  },
  setReturnReasonCode() {
    self.returnReasonCode = !self.returnReasonCode;
  },
  setClaimSerialNumber() {
    self.claimSerialNumber = !self.claimSerialNumber;
  },
  setExternalReferenceNumber() {
    self.externalReferenceNumber = !self.externalReferenceNumber;
  },
  setServiceStartDate() {
    self.serviceStartDate = !self.serviceStartDate;
  },
  setServiceEndDate() {
    self.serviceEndDate = !self.serviceEndDate;
  },
  setContactName() {
    self.contactName = !self.contactName;
  },
  setContactEmail() {
    self.contactEmail = !self.contactEmail;
  },
  setContactPhoneNumber() {
    self.contactPhoneNumber = !self.contactPhoneNumber;
  },
  setDamageSymptomCode() {
    self.damageSymptomCode = !self.damageSymptomCode;
  },
  setDamageCauseCode() {
    self.damageCauseCode = !self.damageCauseCode;
  },
  setDamageResolutionCode() {
    self.damageResolutionCode = !self.damageResolutionCode;
  },
  setDamageCodeA() {
    self.damageCodeA = !self.damageCodeA;
  },
  setDamageCodeB() {
    self.damageCodeB = !self.damageCodeB;
  },
  setDamageCodeC() {
    self.damageCodeC = !self.damageCodeC;
  },
  setDetailsSymptoms() {
    self.detailsSymptoms = !self.detailsSymptoms;
  },
  setDetailsCause() {
    self.detailsCause = !self.detailsCause;
  },
  setDetailsSolutions() {
    self.detailsSolutions = !self.detailsSolutions;
  },
  setDetailsNotes() {
    self.detailsNotes = !self.detailsNotes;
  },
  setDetailsPreWorkApproval() {
    self.detailsPreWorkApproval = !self.detailsPreWorkApproval;
  },
  setOperationNumber() {
    self.operationNumber = !self.operationNumber;
  },
  setLaborDescription() {
    self.laborDescription = !self.laborDescription;
  },
  setLaborReference() {
    self.laborReference = !self.laborReference;
  },
  setLaborStandardHours() {
    self.laborStandardHours = !self.laborStandardHours;
  },
  setLaborActualHours() {
    self.laborActualHours = !self.laborActualHours;
  },
  setLaborUnitPrice() {
    self.laborUnitPrice = !self.laborUnitPrice;
  },
  setLaborTotalPrice() {
    self.laborTotalPrice = !self.laborTotalPrice;
  },
  setLaborMainOprId() {
    self.laborMainOprId = !self.laborMainOprId;
  },
  setPartNumber() {
    self.partNumber = !self.partNumber;
  },
  setPartsDescription() {
    self.partsDescription = !self.partsDescription;
  },
  setPartsReference() {
    self.partsReference = !self.partsReference;
  },
  setPartsQty() {
    self.partsQty = !self.partsQty;
  },
  setPartsUnit() {
    self.partsUnit = !self.partsUnit;
  },
  setPartsUnitPrice() {
    self.partsUnitPrice = !self.partsUnitPrice;
  },
  setPartsTotalPrice() {
    self.partsTotalPrice = !self.partsTotalPrice;
  },
  setPartsLineCausalPart() {
    self.partsLineCausalPart = !self.partsLineCausalPart;
  },
  setPartsPartsImage() {
    self.partsPartsImage = !self.partsPartsImage;
  },
  setReturnablePart() {
    self.returnablePart = !self.returnablePart;
  },
  setMiscCodes() {
    self.miscCodes = !self.miscCodes;
  },
  setMiscCodesDescription() {
    self.miscCodesDescription = !self.miscCodesDescription;
  },
  setMiscDealerReference() {
    self.miscDealerReference = !self.miscDealerReference;
  },
  setMiscChargesQty() {
    self.miscChargesQty = !self.miscChargesQty;
  },
  setMiscUnitPrice() {
    self.miscUnitPrice = !self.miscUnitPrice;
  },
  setMiscTotalPrice() {
    self.miscTotalPrice = !self.miscTotalPrice;
  },
  setCurrency() {
    self.currency = !self.currency;
  },
  setClaimNum() {
    self.claimNum = !self.claimNum;
  },
  setClaimStatus() {
    self.claimStatus = !self.claimStatus;
  },
  setApprovalStatus() {
    self.approvalStatus = !self.approvalStatus;
  },
  setClaimSummaryContractCode() {
    self.claimSummaryContractCode = !self.claimSummaryContractCode;
  },
  setClaimContractCode() {
    self.claimContractCode = !self.claimContractCode;
  },
  setClaimSummaryClaimId() {
    self.claimSummaryClaimId = !self.claimSummaryClaimId;
  },
  setClaimSummaryDeviceNum() {
    self.claimSummaryDeviceNum = !self.claimSummaryDeviceNum;
  },
  setClaimSummaryVINNum() {
    self.claimSummaryVINNum = !self.claimSummaryVINNum;
  },
  setClaimSummaryRegistrationNum() {
    self.claimSummaryRegistrationNum = !self.claimSummaryRegistrationNum;
  },
  setClaimSummarySerialNum() {
    self.claimSummarySerialNum = !self.claimSummarySerialNum;
  },
  setClaimSummaryClaimType() {
    self.claimSummaryClaimType = !self.claimSummaryClaimType;
  },
  setClaimSummaryClaimGroup() {
    self.claimSummaryClaimGroup = !self.claimSummaryClaimGroup;
  },
  setClaimSummaryUsage() {
    self.claimSummaryUsage = !self.claimSummaryUsage;
  },
  setLaborApprovedQty() {
    self.laborApprovedQty = !self.laborApprovedQty;
  },
  setPartsApprovedQty() {
    self.partsApprovedQty = !self.partsApprovedQty;
  },
  setMiscApprovedQty() {
    self.miscApprovedQty = !self.miscApprovedQty;
  },
  setClaimSummaryDmgSymptomCode() { 
    self.claimSummaryDmgSymptomCode = !self.claimSummaryDmgSymptomCode;
  },
  setClaimSummaryDmgCauseCode() { 
    self.claimSummaryDmgCauseCode = !self.claimSummaryDmgCauseCode;
  },
  setClaimSummaryDmgResolutionCode() { 
    self.claimSummaryDmgResolutionCode = !self.claimSummaryDmgResolutionCode;
  },
  setClaimSummaryDmgFailureCodeA() { 
    self.claimSummaryDmgFailureCodeA = !self.claimSummaryDmgFailureCodeA;
  },
  setClaimSummaryDmgFailureCodeB() { 
    self.claimSummaryDmgFailureCodeB = !self.claimSummaryDmgFailureCodeB;
  },
  setClaimSummaryDmgFailureCodeC() { 
    self.claimSummaryDmgFailureCodeC = !self.claimSummaryDmgFailureCodeC;
  },
  setClaimSummaryDetailsSymptoms() { 
    self.claimSummaryDetailsSymptoms = !self.claimSummaryDetailsSymptoms;
  },
  setClaimSummaryDetailsCause() { 
    self.claimSummaryDetailsCause = !self.claimSummaryDetailsCause;
  },
  setClaimSummaryDetailsSolutions() { 
    self.claimSummaryDetailsSolutions = !self.claimSummaryDetailsSolutions;
  },
  setClaimSummaryDetailsNptes() { 
    self.claimSummaryDetailsNptes = !self.claimSummaryDetailsNptes;
  },
  setClaimCurrentUsageButton() { 
    self.claimCurrentUsageButton = !self.claimCurrentUsageButton
  },
  setWebOrderIdList() {
    self.webOrderIdList = !self.webOrderIdList;
  },
  setSalesOrderNumberList() {
    self.salesOrderNumberList = !self.salesOrderNumberList;
  },
  setDealerReferenceNumberList() {
    self.dealerReferenceNumberList = !self.dealerReferenceNumberList;
  },
  setOrderDateList() {
    self.orderDateList = !self.orderDateList;
  },
  setCreatedByList() {
    self.createdByList = !self.createdByList;
  },
  setOrderTypeList() {
    self.orderTypeList = !self.orderTypeList;
  },
  setStatusList() {
    self.statusList = !self.statusList;
  },
  setTotalAmountList() {
    self.totalAmountList = !self.totalAmountList;
  },
  setDealerRequisition() {
    self.dealerRequisition = !self.dealerRequisition;
  },
  setDealerReferenceNumber() {
    self.dealerReferenceNumber = !self.dealerReferenceNumber;
  },
  setPartsOrderContactEmail() {
    self.partsOrderContactEmail = !self.partsOrderContactEmail;
  },
  setPartsOrderContactName() {
    self.partsOrderContactName = !self.partsOrderContactName;
  },
  setPartsOrderPhoneNumber() {
    self.partsOrderPhoneNumber = !self.partsOrderPhoneNumber;
  },
  setPartNumberLine() {
    self.partNumberLine = !self.partNumberLine;
  },
  setPartNameLine() {
    self.partNameLine = !self.partNameLine;
  },
  setDeviceReferenceLine() {
    self.deviceReferenceLine = !self.deviceReferenceLine;
  },
  setDealerReferenceLine() {
    self.dealerReferenceLine = !self.dealerReferenceLine;
  },
  setQuantityLine() {
    self.quantityLine = !self.quantityLine;
  },
  setPartsLineSite() {
    self.partsLineSite = !self.partsLineSite;
  },
  setPartsLineWarehouse() {
    self.partsLineWarehouse = !self.partsLineWarehouse;
  },
  setPartsLineOnHand() {
    self.partsLineOnHand = !self.partsLineOnHand;
  },
  setPartsLineConfig() {
    self.partsLineConfig = !self.partsLineConfig;
  },
  setPartsLineSize() {
    self.partsLineSize = !self.partsLineSize;
  },
  setPartsLineColor() {
    self.partsLineColor = !self.partsLineColor;
  },
  setPartsLineStyle() {
    self.partsLineStyle = !self.partsLineStyle;
  },
  setPartsLineConfirmedReceiptDate() {
    self.partsLineConfirmedReceiptDate = !self.partsLineConfirmedReceiptDate;
  },
  setPartsLineConfirmedShipDate() {
    self.partsLineConfirmedShipDate = !self.partsLineConfirmedShipDate;
  },
  setPartsLinePartsImage() {
    self.partsLinePartsImage = !self.partsLinePartsImage;
  },

  setPartsLineAltItemPartsImage() {
    self.partsLineAltItemPartsImage = !self.partsLineAltItemPartsImage;
  },

  setPartsLineRepItemPartsImage() {
    self.partsLineRepItemPartsImage = !self.partsLineRepItemPartsImage;
  },

  setPartsLineSuppItemPartsImage() {
    self.partsLineSuppItemPartsImage = !self.partsLineSuppItemPartsImage;
  },
  setUnitLine() {
    self.unitLine = !self.unitLine;
  },
  setUnitPriceLine() {
    self.unitPriceLine = !self.unitPriceLine;
  },
  setDiscountLine() {
    self.discountLine = !self.discountLine;
  },
  setDiscountPercentageLine() {
    self.discountPercentageLine = !self.discountPercentageLine;
  },
  setTotalAmountLine() {
    self.totalAmountLine = !self.totalAmountLine;
  },
  setPartsOrderCurrency() {
    self.partsOrderCurrency = !self.partsOrderCurrency;
  },
  setSalesOrderNumber() {
    self.salesOrderNumber = !self.salesOrderNumber;
  },
  setStatus() {
    self.status = !self.status;
  },
  setPodDocStatus() {
    self.podDocStatus = !self.podDocStatus;
  },
  setOrderDate() {
    self.orderDate = !self.orderDate;
  },
  setPtListPartNumber() {
    self.ptListPartNumber = !self.ptListPartNumber;
  },
  setPtListPartName() {
    self.ptListPartName = !self.ptListPartName;
  },
  setPtListPhyiscalDate() {
    self.ptListPhyiscalDate = !self.ptListPhyiscalDate;
  },
  setPtListWarehouse() {
    self.ptListWarehouse = !self.ptListWarehouse;
  },
  setPtListReference() {
    self.ptListReference = !self.ptListReference;
  },
  setPtListSalesOrderNum() {
    self.ptListSalesOrderNum = !self.ptListSalesOrderNum;
  },
  setPtListInvoiceNum() {
    self.ptListInvoiceNum = !self.ptListInvoiceNum;
  },
  setPtListQty() {
    self.ptListQty = !self.ptListQty;
  },
  setPtListOrderLineQuantity(){
    self.ptListOrderLineQuantity = !self.ptListOrderLineQuantity
  },
  setPtListLineStatus() {
    self.ptListLineStatus = !self.ptListLineStatus;
  },
  setPtListStatus() {
    self.ptListStatus = !self.ptListStatus;
  },
  setPtListDeviceReference() {
    self.ptListDeviceReference = !self.ptListDeviceReference;
  },
  setPtListConfirmedShipDate() {
    self.ptListConfirmedShipDate = !self.ptListConfirmedShipDate;
  },
  setDeviceOrderListWebOrderId() {
    self.deviceOrderListWebOrderId = !self.deviceOrderListWebOrderId;
  },
  setDeviceOrderListOrderNum() {
    self.deviceOrderListOrderNum = !self.deviceOrderListOrderNum;
  },
  setDeviceOrderListDealerRefNum() {
    self.deviceOrderListDealerRefNum = !self.deviceOrderListDealerRefNum;
  },
  setDeviceOrderListDate() {
    self.deviceOrderListDate = !self.deviceOrderListDate;
  },
  setDeviceOrderListCreatedBy() {
    self.deviceOrderListCreatedBy = !self.deviceOrderListCreatedBy;
  },
  setDeviceOrderListType() {
    self.deviceOrderListType = !self.deviceOrderListType;
  },
  setDeviceOrderListStatus() {
    self.deviceOrderListStatus = !self.deviceOrderListStatus;
  },
  setDeviceOrderListTotalAmount() {
    self.deviceOrderListTotalAmount = !self.deviceOrderListTotalAmount;
  },
  setDodDealerReference() {
    self.dodDealerReference = !self.dodDealerReference;
  },
  setDodDealerRequisition() {
    self.dodDealerRequisition = !self.dodDealerRequisition;
  },
  setDodInvoiceAccount() {
    self.dodInvoiceAccount = !self.dodInvoiceAccount;
  },
  setDodRequestInvoice() {
    self.dodRequestInvoice = !self.dodRequestInvoice;
  },
  setDodContactName() {
    self.dodContactName = !self.dodContactName;
  },
  setDodContactEmail() {
    self.dodContactEmail = !self.dodContactEmail;
  },
  setDodContactPhoneNumber() {
    self.dodContactPhoneNumber = !self.dodContactPhoneNumber;
  },
  setDodSalesOrderNum() {
    self.dodSalesOrderNum = !self.dodSalesOrderNum;
  },
  setDodStatus() {
    self.dodStatus = !self.dodStatus;
  },
  setDodDocStatus() {
    self.dodDocStatus = !self.dodDocStatus;
  },
  setDodDate() {
    self.dodDate = !self.dodDate;
  },
  setDolItemNumber() {
    self.dolItemNumber = !self.dolItemNumber;
  },
  setDolDeviceName() {
    self.dolDeviceName = !self.dolDeviceName;
  },
  setDolDeviceNumber() {
    self.dolDeviceNumber = !self.dolDeviceNumber;
  },
  setDolConfiguration() {
    self.dolConfiguration = !self.dolConfiguration;
  },
  setDolExterior() {
    self.dolExterior = !self.dolExterior;
  },
  setDolInterior() {
    self.dolInterior = !self.dolInterior;
  },
  setDolStyle() {
    self.dolStyle = !self.dolStyle;
  },
  setDolQuantity() {
    self.dolQuantity = !self.dolQuantity;
  },
  setDolUnit() {
    self.dolUnit = !self.dolUnit;
  },
  setDolConfirmedReceiptDate() {
    self.dolConfirmedReceiptDate = !self.dolConfirmedReceiptDate;
  },
  setDolConfirmedShipDate() {
    self.dolConfirmedShipDate = !self.dolConfirmedShipDate;
  },
  setDocItemCode() {
    self.docItemCode = !self.docItemCode;
  },
  setDocConfiguration() {
    self.docConfiguration = !self.docConfiguration;
  },
  setDocExterior() {
    self.docExterior = !self.docExterior;
  },
  setDocInterior() {
    self.docInterior = !self.docInterior;
  },
  setDocStyle() {
    self.docStyle = !self.docStyle;
  },
  setDocCampaign() {
    self.docCampaign = !self.docCampaign;
  },
  setDocOrdered() {
    self.docOrdered = !self.docOrdered;
  },
  setDocInShipping() {
    self.docInShipping = !self.docInShipping;
  },
  setDocInStock() {
    self.docInStock = !self.docInStock;
  },
  setDocConsignment() {
    self.docConsignment = !self.docConsignment;
  },
  setDocRentalFleet() {
    self.docRentalFleet = !self.docRentalFleet;
  },
  setDosCurrency() {
    self.dosCurrency = !self.dosCurrency;
  },
  setDiListName() {
    self.diListName = !self.diListName;
  },
  setDiListBrand() {
    self.diListBrand = !self.diListBrand;
  },
  setDiListClass() {
    self.diListClass = !self.diListClass;
  },
  setDiListModel() {
    self.diListModel = !self.diListModel;
  },
  setDiListModelCode() {
    self.diListModelCode = !self.diListModelCode;
  },
  setDiListModelYear() {
    self.diListModelYear = !self.diListModelYear;
  },
  setDiListDeviceNum() {
    self.diListDeviceNum = !self.diListDeviceNum;
  },
  setDiListSerialNum() {
    self.diListSerialNum = !self.diListSerialNum;
  },
  setDiListRegistrationNum() {
    self.diListRegNum = !self.diListRegNum;
  },
  setDiListVINNum() {
    self.diListVINNum = !self.diListVINNum;
  },
  setDiListStatus() {
    self.diListStatus = !self.diListStatus;
  },
  setDiGeneralDeviceNumber() {
    self.diGeneralDeviceNumber = !self.diGeneralDeviceNumber;
  },
  setDiGeneralDeviceName() {
    self.diGeneralDeviceName = !self.diGeneralDeviceName;
  },
  setDiGeneralMasterNumber() {
    self.diGeneralMasterNum = !self.diGeneralMasterNum;
  },
  setDiGeneralRegNum() {
    self.diGeneralRegNum = !self.diGeneralRegNum;
  },
  setDiGeneralPrivateNum() {
    self.diGeneralPrivateNum = !self.diGeneralPrivateNum;
  },
  setDiGeneralMajorStatus() {
    self.diGeneralMajorStatus = !self.diGeneralMajorStatus;
  },
  setDiSetupBrandNum() {
    self.diSetupBrandNum = !self.diSetupBrandNum;
  },
  setDiSetupClassNum() {
    self.diSetupClassNum = !self.diSetupClassNum;
  },
  setDiSetupModelNum() {
    self.diSetupModelNum = !self.diSetupModelNum;
  },
  setDiSetupModelCodeNum() {
    self.diSetupModelCodeNum = !self.diSetupModelCodeNum;
  },
  setDiSetupModelConfig() {
    self.diSetupModelConfig = !self.diSetupModelConfig;
  },
  setDiSetupInteriorNum() {
    self.diSetupInteriorNum = !self.diSetupInteriorNum;
  },
  setDiSetupExteriorNum() {
    self.diSetupExteriorNum = !self.diSetupExteriorNum;
  },
  setDiSetupModelYear() {
    self.diSetupModelYear = !self.diSetupModelYear;
  },
  setDiSetupProductionDate() {
    self.diSetupProductionDate = !self.diSetupProductionDate;
  },
  setDiSetupCountryOrigin() {
    self.diSetupCountryOrigin = !self.diSetupCountryOrigin;
  },
  setDiSetupEngineDisplacement() {
    self.diSetupEngineDisplacement = !self.diSetupEngineDisplacement;
  },
  setDiSetupEngineHP() {
    self.diSetupEngineHP = !self.diSetupEngineHP;
  },
  setDiSetupEngineType() {
    self.diSetupEngineType = !self.diSetupEngineType;
  },
  setDiSetupEnginekW() {
    self.diSetupEnginekW = !self.diSetupEnginekW;
  },
  setDiSetupEngineBrand() {
    self.diSetupEngineBrand = !self.diSetupEngineBrand;
  },
  setDiSetupEngineModelYear() {
    self.diSetupEngineModelYear = !self.diSetupEngineModelYear;
  },
  setDiSetupEngineCertificationNumberEPA() {
    self.diSetupEngineCertificationNumberEPA = !self.diSetupEngineCertificationNumberEPA;
  },
  setDiSetupEngineCertificationNumberCARB() {
    self.diSetupEngineCertificationNumberCARB = !self.diSetupEngineCertificationNumberCARB;
  },
  setDiSetupEngineEmissionFamilyNameEPA() {
    self.diSetupEngineEmissionFamilyNameEPA = !self.diSetupEngineEmissionFamilyNameEPA;
  },
  setDiSetupEngineEmissionFamilyNameCARB() {
    self.diSetupEngineEmissionFamilyNameCARB = !self.diSetupEngineEmissionFamilyNameCARB;
  },
  setDiSetupBodyStyle() {
    self.diSetupBodyStyle = !self.diSetupBodyStyle;
  },
  setDiSetupFaceLift() {
    self.diSetupFacelift = !self.diSetupFacelift;
  },
  setDiSetupFuelType() {
    self.diSetupFuelType = !self.diSetupFuelType;
  },
  setDiSetupNumCylinder() {
    self.diSetupNumCylinder = !self.diSetupNumCylinder;
  },
  setDiCustodianName() {
    self.diCustodianName = !self.diCustodianName;
  },
  setDiCustodianOwner() {
    self.diCustodianOwner = !self.diCustodianOwner;
  },
  setDiCustodianFirstOwner() {
    self.diCustodianFirstOwner = !self.diCustodianFirstOwner;
  },
  setDiCustodianOperator() {
    self.diCustodianOperator = !self.diCustodianOperator;
  },
  setDiCustodianCoOwner() {
    self.diCustodianCoOwner = !self.diCustodianCoOwner;
  },
  setDiCustodianActive() {
    self.diCustodianActive = !self.diCustodianActive;
  },
  setDiServiceCampaignLinesNum() {
    self.diServiceCampaignLinesNum = !self.diServiceCampaignLinesNum;
  },
  setDiServiceCampaignLinesDescription() {
    self.diServiceCampaignLinesDescription = !self.diServiceCampaignLinesDescription;
  },
  setDiServiceCampaignLinesStartDate() {
    self.diServiceCampaignLinesStartDate = !self.diServiceCampaignLinesStartDate;
  },
  setDiServiceCampaignLinesStatus() {
    self.diServiceCampaignLinesStatus = !self.diServiceCampaignLinesStatus;
  },
  setDiServiceCampaignLinesEndDate() {
    self.diServiceCampaignLinesEndDate = !self.diServiceCampaignLinesEndDate;
  },
  setDiDeviceAccessoriesSpecificationType() {
    self.diDeviceAccessoriesSpecificationType = !self.diDeviceAccessoriesSpecificationType;
  },
  setDiDeviceAccessoriesItemNum() {
    self.diDeviceAccessoriesItemNum = !self.diDeviceAccessoriesItemNum;
  },
  setDiDeviceAccessoriesDescription() {
    self.diDeviceAccessoriesDescription = !self.diDeviceAccessoriesDescription;
  },
  setDiDeviceAccessoriesComponentNum() {
    self.diDeviceAccessoriesComponentNum = !self.diDeviceAccessoriesComponentNum;
  },
  setDiDeviceAccessoriesCategory() {
    self.diDeviceAccessoriesCategory = !self.diDeviceAccessoriesCategory;
  },
  setDiDeviceAccessoriesQuantity() {
    self.diDeviceAccessoriesQuantity = !self.diDeviceAccessoriesQuantity;
  },
  setDiDeviceAccessoriesStocked() {
    self.diDeviceAccessoriesStocked = !self.diDeviceAccessoriesStocked;
  },
  setDiDeviceAccessoriesReplaced() {
    self.diDeviceAccessoriesReplaced = !self.diDeviceAccessoriesReplaced;
  },
  setDiDeviceAccessoriesStatus() {
    self.diDeviceAccessoriesStatus = !self.diDeviceAccessoriesStatus;
  },
  setDiDeviceAccessoriesTransactionDate() {
    self.diDeviceAccessoriesTransactionDate = !self.diDeviceAccessoriesTransactionDate;
  },
  setDiDeviceAccessoriesStatusDate() {
    self.diDeviceAccessoriesStatusDate = !self.diDeviceAccessoriesStatusDate;
  },
  setDiDeviceChildSpecificationType() {
    self.diDeviceChildSpecificationType = !self.diDeviceChildSpecificationType;
  },
  setDiDeviceChildItemNum() {
    self.diDeviceChildItemNum = !self.diDeviceChildItemNum;
  },
  setDiDeviceChildDescription() {
    self.diDeviceChildDescription = !self.diDeviceChildDescription;
  },
  setDiDeviceChildComponentNum() {
    self.diDeviceChildComponentNum = !self.diDeviceChildComponentNum;
  },
  setDiDeviceChildCategory() {
    self.diDeviceChildCategory = !self.diDeviceChildCategory;
  },
  setDiDeviceChildQuantity() {
    self.diDeviceChildQuantity = !self.diDeviceChildQuantity;
  },
  setDiDeviceChildStocked() {
    self.diDeviceChildStocked = !self.diDeviceChildStocked;
  },
  setDiDeviceChildReplaced() {
    self.diDeviceChildReplaced = !self.diDeviceChildReplaced;
  },
  setDiDeviceChildStatus() {
    self.diDeviceChildStatus = !self.diDeviceChildStatus;
  },
  setDiDeviceChildTransactionDate() {
    self.diDeviceChildTransactionDate = !self.diDeviceChildTransactionDate;
  },
  setDiDeviceChildStatusDate() {
    self.diDeviceChildStatusDate = !self.diDeviceChildStatusDate;
  },
  setDiDeviceComponentsSpecificationType() {
    self.diDeviceComponentsSpecificationType = !self.diDeviceComponentsSpecificationType;
  },
  setDiDeviceComponentsItemNum() {
    self.diDeviceComponentsItemNum = !self.diDeviceComponentsItemNum;
  },
  setDiDeviceComponentsDescription() {
    self.diDeviceComponentsDescription = !self.diDeviceComponentsDescription;
  },
  setDiDeviceComponentsNum() {
    self.diDeviceComponentsNum = !self.diDeviceComponentsNum;
  },
  setDiDeviceComponentsCategory() {
    self.diDeviceComponentsCategory = !self.diDeviceComponentsCategory;
  },
  setDiDeviceComponentsQuantity() {
    self.diDeviceComponentsQuantity = !self.diDeviceComponentsQuantity;
  },
  setDiDeviceComponentsStocked() {
    self.diDeviceComponentsStocked = !self.diDeviceComponentsStocked;
  },
  setDiDeviceComponentsReplaced() {
    self.diDeviceComponentsReplaced = !self.diDeviceComponentsReplaced;
  },
  setDiDeviceComponentsStatus() {
    self.diDeviceComponentsStatus = !self.diDeviceComponentsStatus;
  },
  setDiDeviceComponentsTranscationDate() {
    self.diDeviceComponentsTranscationDate = !self.diDeviceComponentsTranscationDate;
  },
  setDiDeviceComponentsStatusDate() {
    self.diDeviceComponentsStatusDate = !self.diDeviceComponentsStatusDate;
  },
  setDiOptionsSpecificationType() {
    self.diOptionsSpecificationType = !self.diOptionsSpecificationType;
  },
  setDiOptionsItemNum() {
    self.diOptionsItemNum = !self.diOptionsItemNum;
  },
  setDiOptionsDescription() {
    self.diOptionsDescription = !self.diOptionsDescription;
  },
  setDiOptionsComponentNum() {
    self.diOptionsComponentNum = !self.diOptionsComponentNum;
  },
  setDiOptionsCategory() {
    self.diOptionsCategory = !self.diOptionsCategory;
  },
  setDiOptionsQuantity() {
    self.diOptionsQuantity = !self.diOptionsQuantity;
  },
  setDiOptionsStocked() {
    self.diOptionsStocked = !self.diOptionsStocked;
  },
  setDiOptionsReplaced() {
    self.diOptionsReplaced = !self.diOptionsReplaced;
  },
  setDiOptionsStatus() {
    self.diOptionsStatus = !self.diOptionsStatus;
  },
  setDiOptionsTransactionDate() {
    self.diOptionsTransactionDate = !self.diOptionsTransactionDate;
  },
  setDiOptionsStatusDate() {
    self.diOptionsStatusDate = !self.diOptionsStatusDate;
  },
  setDiDevicePropertiesDeviceName() {
    self.diDevicePropertiesDeviceName = !self.diDevicePropertiesDeviceName;
  },
  setDiDevicePropertiesDeviceNum() {
    self.diDevicePropertiesDeviceNum = !self.diDevicePropertiesDeviceNum;
  },
  setDiDevicePropertiesPropertyGroup() {
    self.diDevicePropertiesPropertyGroup = !self.diDevicePropertiesPropertyGroup;
  },
  setDiDevicePropertiesPropertyGroupCode() {
    self.diDevicePropertiesPropertyGroupCode = !self.diDevicePropertiesPropertyGroupCode;
  },
  setDiDevicePropertiesNotes() {
    self.diDevicePropertiesNotes = !self.diDevicePropertiesNotes;
  },
  setDiWarrantyType() {
    self.diWarrantyType = !self.diWarrantyType;
  },
  setDiWarrantyDescription() {
    self.diWarrantyDescription = !self.diWarrantyDescription;
  },
  setDiWarrantyStartDate() {
    self.diWarrantStartDate = !self.diWarrantStartDate;
  },
  setDiWarrantyEndDate() {
    self.diWarrantyEndDate = !self.diWarrantyEndDate;
  },
  setDiWarrantyStartUsage() {
    self.diWarrantyStartUsage = !self.diWarrantyStartUsage;
  },
  setDiWarrantyEndUsage() {
    self.diWarrantyEndUsage = !self.diWarrantyEndUsage;
  },
  setDiWorkOrderHistNum() {
    self.diWorkOrderHistNum = !self.diWorkOrderHistNum;
  },
  setDiWorkOrderHistDescription() {
    self.diWorkOrderHistDescription = !self.diWorkOrderHistDescription;
  },
  setDiWorkOrderHistCustomerAccount() {
    self.diWorkOrderHistCustomerAccount = !self.diWorkOrderHistCustomerAccount;
  },
  setDiWorkOrderHistStatus() {
    self.diWorkOrderHistStatus = !self.diWorkOrderHistStatus;
  },
  setDiWorkOrderHistWorkOrderGrp() {
    self.diWorkOrderHistWorkOrderGrp = !self.diWorkOrderHistWorkOrderGrp;
  },
  setDiWorkOrderHistType() {
    self.diWorkOrderHistType = !self.diWorkOrderHistType;
  },
  setDiWorkOrderHistStartDate() {
    self.diWorkOrderHistStartDate = !self.diWorkOrderHistStartDate;
  },
  setDiWorkOrderHistCreatedDate() {
    self.diWorkOrderHistCreatedDate = !self.diWorkOrderHistCreatedDate;
  },
  setDiWorkOrderHistEstimatedHours() {
    self.diWorkOrderHistEstimatedHours = !self.diWorkOrderHistEstimatedHours;
  },
  setDiWorkOrderHistHoursRemaining() {
    self.diWorkOrderHistHoursRemaining = !self.diWorkOrderHistHoursRemaining;
  },
  setDiClaimHistWarrantyClaim() {
    self.diClaimHistWarrantyClaim = !self.diClaimHistWarrantyClaim;
  },
  setDiClaimHistStatus() {
    self.diClaimHistStatus = !self.diClaimHistStatus;
  },
  setDiClaimHistApprovalStatus() {
    self.diClaimHistApprovalStatus = !self.diClaimHistApprovalStatus;
  },
  setDiClaimHistInvoiceStatus() {
    self.diClaimHistInvoiceStatus = !self.diClaimHistInvoiceStatus;
  },
  setDiClaimHistWarrantyClaimGrp() {
    self.diClaimHistWarrantyClaimGrp = !self.diClaimHistWarrantyClaimGrp;
  },
  setDiClaimHistType() {
    self.diClaimHistType = !self.diClaimHistType;
  },
  setDiClaimHistDevice() {
    self.diClaimHistDevice = !self.diClaimHistDevice;
  },
  setDiClaimHistPreWorkApproval() {
    self.diClaimHistPreWorkApproval = !self.diClaimHistPreWorkApproval;
  },
  setDiClaimHistUsage() {
    self.diClaimHistUsage = !self.diClaimHistUsage;
  },
  setDiServicePlanNum() {
    self.diServicePlanNum = !self.diServicePlanNum;
  },
  setDiServicePlanName() {
    self.diServicePlanName = !self.diServicePlanName;
  },
  setDiServicePlanStatus() {
    self.diServicePlanStatus = !self.diServicePlanStatus;
  },
  setDiServicePlanActivation() {
    self.diServicePlanActivation = !self.diServicePlanActivation;
  },
  setDiServicePlanReferenceDate() {
    self.diServicePlanReferenceDate = !self.diServicePlanReferenceDate;
  },
  setDiServicePlanContractNum() {
    self.diServicePlanContractNum = !self.diServicePlanContractNum;
  },
  setDiServicePlanGrp() {
    self.diServicePlanGrp = !self.diServicePlanGrp;
  },
  setDiScheduledServiceDueDate() {
    self.diScheduledServiceDueDate = !self.diScheduledServiceDueDate;
  },
  setDiScheduledServiceUsage() {
    self.diScheduledServiceUsage = !self.diScheduledServiceUsage;
  },
  setDiScheduledServiceUsageUnit() {
    self.diScheduledServiceUsageUnit = !self.diScheduledServiceUsageUnit;
  },
  setDiScheduledServiceUsageDueDate() {
    self.diScheduledServiceUsageDueDate = !self.diScheduledServiceUsageDueDate;
  },
  setDiScheduledServiceName() {
    self.diScheduledServiceName = !self.diScheduledServiceName;
  },
  setDiScheduledServiceServicePlanGrp() {
    self.diScheduledServiceServicePlanGrp = !self.diScheduledServiceServicePlanGrp;
  },
  setDiScheduledServiceStatus() {
    self.diScheduledServiceStatus = !self.diScheduledServiceStatus;
  },
  setDiScheduledServiceStopped() {
    self.diScheduledServiceStopped = !self.diScheduledServiceStopped;
  },
  setDiScheduledServiceWorkOrderNum() {
    self.diScheduledServiceWorkOrderNum = !self.diScheduledServiceWorkOrderNum;
  },
  setDiScheduledServiceWorkOrderStatus() {
    self.diScheduledServiceWorkOrderStatus = !self.diScheduledServiceWorkOrderStatus;
  },
  setDiScheduledServiceClosedDate() {
    self.diScheduledServiceClosedDate = !self.diScheduledServiceClosedDate;
  },
  setDiDocumentsName() {
    self.diDocumentsName = !self.diDocumentsName;
  },
  setDiDocumentsType() {
    self.diDocumentsType = !self.diDocumentsType;
  },
  setDiDocumentsNotes() {
    self.diDocumentsNotes = !self.diDocumentsNotes;
  },
  setDiServiceContractContractId() {
    self.diServiceContractContractId = !self.diServiceContractContractId;
  },
  setDiServiceContractDeviceId() {
    self.diServiceContractDeviceId = !self.diServiceContractDeviceId;
  },
  setDiServiceContractDeviceGroupId() {
    self.diServiceContractDeviceGroupId = !self.diServiceContractDeviceGroupId;
  },
  setDiServiceContractInvoiceContractId() {
    self.diServiceContractInvoiceContractId = !self.diServiceContractInvoiceContractId;
  },
  setDiServiceContractContractType() {
    self.diServiceContractContractType = !self.diServiceContractContractType;
  },
  setDiServiceContractCollectionStatus() {
    self.diServiceContractCollectionStatus = !self.diServiceContractCollectionStatus;
  },
  setDiServiceContractCustAccount() {
    self.diServiceContractCustAccount = !self.diServiceContractCustAccount;
  },
  setDiServiceContractName() {
    self.diServiceContractName = !self.diServiceContractName;
  },
  setDrListWebId() {
    self.drListWebId = !self.drListWebId;
  },
  setDrListCreatedBy() {
    self.drListCreatedBy = !self.drListCreatedBy;
  },
  setDrListCreatedDate() {
    self.drListCreatedDate = !self.drListCreatedDate;
  },
  setDrListStatus() {
    self.drListStatus = !self.drListStatus;
  },
  setDrInfoHeaderWebId() {
    self.drInfoHeaderWebId = !self.drInfoHeaderWebId;
  },
  setDrInfoHeaderReportNum() {
    self.drInfoHeaderReportNum = !self.drInfoHeaderReportNum;
  },
  setDrInfoHeaderCreatedBy() {
    self.drInfoHeaderCreatedBy = !self.drInfoHeaderCreatedBy;
  },
  setDrInfoHeaderCreatedDate() {
    self.drInfoHeaderCreatedDate = !self.drInfoHeaderCreatedDate;
  },
  setDrInfoHeaderStatus() {
    self.drInfoHeaderStatus = !self.drInfoHeaderStatus;
  },
  setDrInfoLinesDeviceInfoVinNum() {
    self.drInfoLinesDeviceInfoVinNum = !self.drInfoLinesDeviceInfoVinNum;
  },
  setDrInfoLinesDeviceInfoDeviceNum() {
    self.drInfoLinesDeviceInfoDeviceNum = !self.drInfoLinesDeviceInfoDeviceNum;
  },
  setDrInfoLinesDeviceInfoSerialNum() {
    self.drInfoLinesDeviceInfoSerialNum = !self.drInfoLinesDeviceInfoSerialNum;
  },
  setDrInfoLinesGeneralUsage() {
    self.drInfoLinesGeneralUsage = !self.drInfoLinesGeneralUsage;
  },
  setDrInfoLinesGeneralUsageGrp() {
    self.drInfoLinesGeneralUsageGrp = !self.drInfoLinesGeneralUsageGrp;
  },
  setDrInfoLinesGeneralMjrStatus() {
    self.drInfoLinesGeneralMjrStatus = !self.drInfoLinesGeneralMjrStatus;
  },
  setDrInfoLinesGeneralFinalSalesDate() {
    self.drInfoLinesGeneralFinalSalesDate = !self.drInfoLinesGeneralFinalSalesDate;
  },
  setDrInfoLinesGeneralRegDate() {
    self.drInfoLinesGeneralRegDate = !self.drInfoLinesGeneralRegDate;
  },
  setDrInfoLinesGeneralRefDate() {
    self.drInfoLinesGeneralRefDate = !self.drInfoLinesGeneralRefDate;
  },
  setDrInfoLinesGeneralWarrantyType() {
    self.drInfoLinesGeneralWarrantyType = !self.drInfoLinesGeneralWarrantyType;
  },
  setDrInfoLinesParentDeviceInfoVinNum() {
    self.drInfoLinesParentDeviceInfoVinNum = !self.drInfoLinesParentDeviceInfoVinNum;
  },
  setDrInfoLinesParentDeviceInfoParentDeviceNum() {
    self.drInfoLinesParentDeviceInfoParentDeviceNum = !self.drInfoLinesParentDeviceInfoParentDeviceNum;
  },
  setDrInfoLinesParentDeviceInfoSerialNum() {
    self.drInfoLinesParentDeviceInfoSerialNum = !self.drInfoLinesParentDeviceInfoSerialNum;
  },
  setDrInfoLinesParentDeviceInfoUpdateParentDeviceCustodian() {
    self.drInfoLinesParentDeviceInfoUpdateParentDeviceCustodian = !self.drInfoLinesParentDeviceInfoUpdateParentDeviceCustodian;
  },
  setDrInfoLinesCustodianName() {
    self.drInfoLinesCustodianName = !self.drInfoLinesCustodianName;
  },
  setDrInfoLinesCustodianEmail() {
    self.drInfoLinesCustodianEmail = !self.drInfoLinesCustodianEmail;
  },
  setDrInfoLinesCustodianPhoneNum() {
    self.drInfoLinesCustodianPhoneNum = !self.drInfoLinesCustodianPhoneNum;
  },
  setDrInfoLinesCustodianAddress() {
    self.drInfoLinesCustodianAddress = !self.drInfoLinesCustodianAddress;
  },
  setDrCreateLineVinNum() {
    self.drCreateLineVinNum = !self.drCreateLineVinNum;
  },
  setDrCreateLineDeviceNum() {
    self.drCreateLineDeviceNum = !self.drCreateLineDeviceNum;
  },
  setDrCreateLineSerialNum() {
    self.drCreateLineSerialNum = !self.drCreateLineSerialNum;
  },
  setDrCreateGeneralUsage() {
    self.drCreateGeneralUsage = !self.drCreateGeneralUsage;
  },
  setDrCreateGeneralUsageGrp() {
    self.drCreateGeneralUsageGrp = !self.drCreateGeneralUsageGrp;
  },
  setDrCreateGeneralMjrStatus() {
    self.drCreateGeneralMjrStatus = !self.drCreateGeneralMjrStatus;
  },
  setDrCreateGeneralFinalSalesDate() {
    self.drCreateGeneralFinalSalesDate = !self.drCreateGeneralFinalSalesDate;
  },
  setDrCreateGeneralRegDate() {
    self.drCreateGeneralRegDate = !self.drCreateGeneralRegDate;
  },
  setDrCreateGeneralRefDate() {
    self.drCreateGeneralRefDate = !self.drCreateGeneralRefDate;
  },
  setDrCreateGeneralWarrantyType() {
    self.drCreateGeneralWarrantyType = !self.drCreateGeneralWarrantyType;
  },
  setDrCreateGeneralRegNum() {
    self.drCreateGeneralRegNum = !self.drCreateGeneralRegNum;
  },
  setDrCreateParentDeviceVinNum() {
    self.drCreateParentDeviceVinNum = !self.drCreateParentDeviceVinNum;
  },
  setDrCreateParentDeviceNum() {
    self.drCreateParentDeviceNum = !self.drCreateParentDeviceNum;
  },
  setDrCreateParentDeviceSerialNum() {
    self.drCreateParentDeviceSerialNum = !self.drCreateParentDeviceSerialNum;
  },
  setDrCreateParentDeviceUpdateParentDeviceCustodian() {
    self.drCreateParentDeviceUpdateParentDeviceCustodian = !self.drCreateParentDeviceUpdateParentDeviceCustodian;
  },
  setDrCreateCustodianName() {
    self.drCreateCustodianName = !self.drCreateCustodianName;
  },
  setDrCreateCustodianEmail() {
    self.drCreateCustodianEmail = !self.drCreateCustodianEmail;
  },
  setDrCreateCustodianPhoneNum() {
    self.drCreateCustodianPhoneNum = !self.drCreateCustodianPhoneNum;
  },
  setDrCreateCustodianAddress() {
    self.drCreateCustodianAddress = !self.drCreateCustodianAddress;
  },
  setDaListDevicNumber() {
    self.daListDevicNumber = !self.daListDevicNumber;
  },
  setDaListDeviceName() {
    self.daListDeviceName = !self.daListDeviceName;
  },
  setDaListItemNumber() {
    self.daListItemNumber = !self.daListItemNumber;
  },
  setDaListModelCodeNumber() {
    self.daListModelCodeNumber = !self.daListModelCodeNumber;
  },
  setDaListConfigName() {
    self.daListConfigName = !self.daListConfigName;
  },
  setDaListModelYear() {
    self.daListModelYear = !self.daListModelYear;
  },
  setDaListRegistrationNumber() {
    self.daListRegistrationNumber = !self.daListRegistrationNumber;
  },
  setDaListChassislNum() {
    self.daListChassislNum = !self.daListChassislNum;
  },
  setDaListMajorStatus() {
    self.daListMajorStatus = !self.daListMajorStatus;
  },
  setDaListSalesPrice() {
    self.daListSalesPrice = !self.daListSalesPrice;
  },
  setDaListCampaignPrice() {
    self.daListCampaignPrice = !self.daListCampaignPrice;
  },
  setDaGeneralDeviceNumber() {
    self.daGeneralDeviceNumber = !self.daGeneralDeviceNumber;
  },
  setDaGeneralDeviceName() {
    self.daGeneralDeviceName = !self.daGeneralDeviceName;
  },
  setDaGeneralMasterNum() {
    self.daGeneralMasterNum = !self.daGeneralMasterNum;
  },
  setDaGeneralRegNum() {
    self.daGeneralRegNum = !self.daGeneralRegNum;
  },
  setDaGeneralPrivateNum() {
    self.daGeneralPrivateNum = !self.daGeneralPrivateNum;
  },
  setDaGeneralMajorStatus() {
    self.daGeneralMajorStatus = !self.daGeneralMajorStatus;
  },
  setDaSetupBrandNum() {
    self.daSetupBrandNum = !self.daSetupBrandNum;
  },
  setDaSetupClassNum() {
    self.daSetupClassNum = !self.daSetupClassNum;
  },
  setDaSetupModelNum() {
    self.daSetupModelNum = !self.daSetupModelNum;
  },
  setDaSetupModelCodeNum() {
    self.daSetupModelCodeNum = !self.daSetupModelCodeNum;
  },
  setDaSetupModelConfig() {
    self.daSetupModelConfig = !self.daSetupModelConfig;
  },
  setDaSetupInteriorNum() {
    self.daSetupInteriorNum = !self.daSetupInteriorNum;
  },
  setDaSetupExteriorNum() {
    self.daSetupExteriorNum = !self.daSetupExteriorNum;
  },
  setDaSetupModelYear() {
    self.daSetupModelYear = !self.daSetupModelYear;
  },
  setDaSetupProductionDate() {
    self.daSetupProductionDate = !self.daSetupProductionDate;
  },
  setDaSetupCountryOrigin() {
    self.daSetupCountryOrigin = !self.daSetupCountryOrigin;
  },
  setDaSetupEngineDisplacement() {
    self.daSetupEngineDisplacement = !self.daSetupEngineDisplacement;
  },
  setDaSetupEngineHP() {
    self.daSetupEngineHP = !self.daSetupEngineHP;
  },
  setDaSetupEngineType() {
    self.daSetupEngineType = !self.daSetupEngineType;
  },
  setDaSetupEnginekW() {
    self.daSetupEnginekW = !self.daSetupEnginekW;
  },
  setDaSetupEngineBrand() {
    self.daSetupEngineBrand = !self.daSetupEngineBrand;
  },
  setDaSetupEngineModelYear() {
    self.daSetupEngineModelYear = !self.daSetupEngineModelYear;
  },
  setDaSetupEngineCertificationNumberEPA() {
    self.daSetupEngineCertificationNumberEPA = !self.daSetupEngineCertificationNumberEPA;
  },
  setDaSetupEngineCertificationNumberCARB() {
    self.daSetupEngineCertificationNumberCARB = !self.daSetupEngineCertificationNumberCARB;
  },
  setDaSetupEngineEmissionFamilyNameEPA() {
    self.daSetupEngineEmissionFamilyNameEPA = !self.daSetupEngineEmissionFamilyNameEPA;
  },
  setDaSetupEngineEmissionFamilyNameCARB() {
    self.daSetupEngineEmissionFamilyNameCARB = !self.daSetupEngineEmissionFamilyNameCARB;
  },
  setDaSetupBodyStyle() {
    self.daSetupBodyStyle = !self.daSetupBodyStyle;
  },
  setDaSetupFacelift() {
    self.daSetupFacelift = !self.daSetupFacelift;
  },
  setDaSetupFuelType() {
    self.daSetupFuelType = !self.daSetupFuelType;
  },
  setDaSetupNumCylinder() {
    self.daSetupNumCylinder = !self.daSetupNumCylinder;
  },
  setDaCustodianName() {
    self.daCustodianName = !self.daCustodianName;
  },
  setDaCustodianOwner() {
    self.daCustodianOwner = !self.daCustodianOwner;
  },
  setDaCustodianFirstOwner() {
    self.daCustodianFirstOwner = !self.daCustodianFirstOwner;
  },
  setDaCustodianOperator() {
    self.daCustodianOperator = !self.daCustodianOperator;
  },
  setDaCustodianCoOwner() {
    self.daCustodianCoOwner = !self.daCustodianCoOwner;
  },
  setDaCustodianActive() {
    self.daCustodianActive = !self.daCustodianActive;
  },
  setDaServiceCampaignLinesNum() {
    self.daServiceCampaignLinesNum = !self.daServiceCampaignLinesNum;
  },
  setDaServiceCampaignLinesDescription() {
    self.daServiceCampaignLinesDescription = !self.daServiceCampaignLinesDescription;
  },
  setDaServiceCampaignLinesStartDate() {
    self.daServiceCampaignLinesStartDate = !self.daServiceCampaignLinesStartDate;
  },
  setDaServiceCampaignLinesStatus() {
    self.daServiceCampaignLinesStatus = !self.daServiceCampaignLinesStatus;
  },
  setDaServiceCampaignLinesEndDate() {
    self.daServiceCampaignLinesEndDate = !self.daServiceCampaignLinesEndDate;
  },
  setDaDeviceAccessoriesSpecificationType() {
    self.daDeviceAccessoriesSpecificationType = !self.daDeviceAccessoriesSpecificationType;
  },
  setDaDeviceAccessoriesItemNum() {
    self.daDeviceAccessoriesItemNum = !self.daDeviceAccessoriesItemNum;
  },
  setDaDeviceAccessoriesDescription() {
    self.daDeviceAccessoriesDescription = !self.daDeviceAccessoriesDescription;
  },
  setDaDeviceAccessoriesComponentNum() {
    self.daDeviceAccessoriesComponentNum = !self.daDeviceAccessoriesComponentNum;
  },
  setDaDeviceAccessoriesCategory() {
    self.daDeviceAccessoriesCategory = !self.daDeviceAccessoriesCategory;
  },
  setDaDeviceAccessoriesQuantity() {
    self.daDeviceAccessoriesQuantity = !self.daDeviceAccessoriesQuantity;
  },
  setDaDeviceAccessoriesStocked() {
    self.daDeviceAccessoriesStocked = !self.daDeviceAccessoriesStocked;
  },
  setDaDeviceAccessoriesReplaced() {
    self.daDeviceAccessoriesReplaced = !self.daDeviceAccessoriesReplaced;
  },
  setDaDeviceAccessoriesStatus() {
    self.daDeviceAccessoriesStatus = !self.daDeviceAccessoriesStatus;
  },
  setDaDeviceAccessoriesTransactionDate() {
    self.daDeviceAccessoriesTransactionDate = !self.daDeviceAccessoriesTransactionDate;
  },
  setDaDeviceAccessoriesStatusDate() {
    self.daDeviceAccessoriesStatusDate = !self.daDeviceAccessoriesStatusDate;
  },
  setDaDeviceChildSpecificationType() {
    self.daDeviceChildSpecificationType = !self.daDeviceChildSpecificationType;
  },
  setDaDeviceChildItemNum() {
    self.daDeviceChildItemNum = !self.daDeviceChildItemNum;
  },
  setDaDeviceChildDescription() {
    self.daDeviceChildDescription = !self.daDeviceChildDescription;
  },
  setDaDeviceChildComponentNum() {
    self.daDeviceChildComponentNum = !self.daDeviceChildComponentNum;
  },
  setDaDeviceChildCategory() {
    self.daDeviceChildCategory = !self.daDeviceChildCategory;
  },
  setDaDeviceChildQuantity() {
    self.daDeviceChildQuantity = !self.daDeviceChildQuantity;
  },
  setDaDeviceChildStocked() {
    self.daDeviceChildStocked = !self.daDeviceChildStocked;
  },
  setDaDeviceChildReplaced() {
    self.daDeviceChildReplaced = !self.daDeviceChildReplaced;
  },
  setDaDeviceChildStatus() {
    self.daDeviceChildStatus = !self.daDeviceChildStatus;
  },
  setDaDeviceChildTransactionDate() {
    self.daDeviceChildTransactionDate = !self.daDeviceChildTransactionDate;
  },
  setDaDeviceChildStatusDate() {
    self.daDeviceChildStatusDate = !self.daDeviceChildStatusDate;
  },
  setDaDeviceComponentsSpecificationType() {
    self.daDeviceComponentsSpecificationType = !self.daDeviceComponentsSpecificationType;
  },
  setDaDeviceComponentsItemNum() {
    self.daDeviceComponentsItemNum = !self.daDeviceComponentsItemNum;
  },
  setDaDeviceComponentsDescription() {
    self.daDeviceComponentsDescription = !self.daDeviceComponentsDescription;
  },
  setDaDeviceComponentsNum() {
    self.daDeviceComponentsNum = !self.daDeviceComponentsNum;
  },
  setDaDeviceComponentsCategory() {
    self.daDeviceComponentsCategory = !self.daDeviceComponentsCategory;
  },
  setDaDeviceComponentsQuantity() {
    self.daDeviceComponentsQuantity = !self.daDeviceComponentsQuantity;
  },
  setDaDeviceComponentsStocked() {
    self.daDeviceComponentsStocked = !self.daDeviceComponentsStocked;
  },
  setDaDeviceComponentsReplaced() {
    self.daDeviceComponentsReplaced = !self.daDeviceComponentsReplaced;
  },
  setDaDeviceComponentsStatus() {
    self.daDeviceComponentsStatus = !self.daDeviceComponentsStatus;
  },
  setDaDeviceComponentsTranscationDate() {
    self.daDeviceComponentsTranscationDate = !self.daDeviceComponentsTranscationDate;
  },
  setDaDeviceComponentsStatusDate() {
    self.daDeviceComponentsStatusDate = !self.daDeviceComponentsStatusDate;
  },
  setDaOptionsSpecificationType() {
    self.daOptionsSpecificationType = !self.daOptionsSpecificationType;
  },
  setDaOptionsItemNum() {
    self.daOptionsItemNum = !self.daOptionsItemNum;
  },
  setDaOptionsDescription() {
    self.daOptionsDescription = !self.daOptionsDescription;
  },
  setDaOptionsComponentNum() {
    self.daOptionsComponentNum = !self.daOptionsComponentNum;
  },
  setDaOptionsCategory() {
    self.daOptionsCategory = !self.daOptionsCategory;
  },
  setDaOptionsQuantity() {
    self.daOptionsQuantity = !self.daOptionsQuantity;
  },
  setDaOptionsStocked() {
    self.daOptionsStocked = !self.daOptionsStocked;
  },
  setDaOptionsReplaced() {
    self.daOptionsReplaced = !self.daOptionsReplaced;
  },
  setDaOptionsStatus() {
    self.daOptionsStatus = !self.daOptionsStatus;
  },
  setDaOptionsTransactionDate() {
    self.daOptionsTransactionDate = !self.daOptionsTransactionDate;
  },
  setDaOptionsStatusDate() {
    self.daOptionsStatusDate = !self.daOptionsStatusDate;
  },
  setDaDevicePropertiesDeviceName() {
    self.daDevicePropertiesDeviceName = !self.daDevicePropertiesDeviceName;
  },
  setDaDevicePropertiesDeviceNum() {
    self.daDevicePropertiesDeviceNum = !self.daDevicePropertiesDeviceNum;
  },
  setDaDevicePropertiesPropertyGroup() {
    self.daDevicePropertiesPropertyGroup = !self.daDevicePropertiesPropertyGroup;
  },
  setDaDevicePropertiesPropertyGroupCode() {
    self.daDevicePropertiesPropertyGroupCode = !self.daDevicePropertiesPropertyGroupCode;
  },
  setDaDevicePropertiesNotes() {
    self.daDevicePropertiesNotes = !self.daDevicePropertiesNotes;
  },
  setDaWarrantyType() {
    self.daWarrantyType = !self.daWarrantyType;
  },
  setDaWarrantyDescription() {
    self.daWarrantyDescription = !self.daWarrantyDescription;
  },
  setDaWarrantStartDate() {
    self.daWarrantStartDate = !self.daWarrantStartDate;
  },
  setDaWarrantyEndDate() {
    self.daWarrantyEndDate = !self.daWarrantyEndDate;
  },
  setDaWarrantyStartUsage() {
    self.daWarrantyStartUsage = !self.daWarrantyStartUsage;
  },
  setDaWarrantyEndUsage() {
    self.daWarrantyEndUsage = !self.daWarrantyEndUsage;
  },
  setDaWorkOrderHistNum() {
    self.daWorkOrderHistNum = !self.daWorkOrderHistNum;
  },
  setDaWorkOrderHistDescription() {
    self.daWorkOrderHistDescription = !self.daWorkOrderHistDescription;
  },
  setDaWorkOrderHistCustomerAccount() {
    self.daWorkOrderHistCustomerAccount = !self.daWorkOrderHistCustomerAccount;
  },
  setDaWorkOrderHistStatus() {
    self.daWorkOrderHistStatus = !self.daWorkOrderHistStatus;
  },
  setDaWorkOrderHistWorkOrderGrp() {
    self.daWorkOrderHistWorkOrderGrp = !self.daWorkOrderHistWorkOrderGrp;
  },
  setDaWorkOrderHistType() {
    self.daWorkOrderHistType = !self.daWorkOrderHistType;
  },
  setDaWorkOrderHistStartDate() {
    self.daWorkOrderHistStartDate = !self.daWorkOrderHistStartDate;
  },
  setDaWorkOrderHistCreatedDate() {
    self.daWorkOrderHistCreatedDate = !self.daWorkOrderHistCreatedDate;
  },
  setDaWorkOrderHistEstimatedHours() {
    self.daWorkOrderHistEstimatedHours = !self.daWorkOrderHistEstimatedHours;
  },
  setDaWorkOrderHistHoursRemaining() {
    self.daWorkOrderHistHoursRemaining = !self.daWorkOrderHistHoursRemaining;
  },
  setDaClaimHistWarrantyClaim() {
    self.daClaimHistWarrantyClaim = !self.daClaimHistWarrantyClaim;
  },
  setDaClaimHistStatus() {
    self.daClaimHistStatus = !self.daClaimHistStatus;
  },
  setDaClaimHistApprovalStatus() {
    self.daClaimHistApprovalStatus = !self.daClaimHistApprovalStatus;
  },
  setDaClaimHistInvoiceStatus() {
    self.daClaimHistInvoiceStatus = !self.daClaimHistInvoiceStatus;
  },
  setDaClaimHistWarrantyClaimGrp() {
    self.daClaimHistWarrantyClaimGrp = !self.daClaimHistWarrantyClaimGrp;
  },
  setDaClaimHistType() {
    self.daClaimHistType = !self.daClaimHistType;
  },
  setDaClaimHistDevice() {
    self.daClaimHistDevice = !self.daClaimHistDevice;
  },
  setDaClaimHistPreWorkApproval() {
    self.daClaimHistPreWorkApproval = !self.daClaimHistPreWorkApproval;
  },
  setDaClaimHistUsage() {
    self.daClaimHistUsage = !self.daClaimHistUsage;
  },
  setDaServicePlanNum() {
    self.daServicePlanNum = !self.daServicePlanNum;
  },
  setDaServicePlanName() {
    self.daServicePlanName = !self.daServicePlanName;
  },
  setDaServicePlanStatus() {
    self.daServicePlanStatus = !self.daServicePlanStatus;
  },
  setDaServicePlanActivation() {
    self.daServicePlanActivation = !self.daServicePlanActivation;
  },
  setDaServicePlanReferenceDate() {
    self.daServicePlanReferenceDate = !self.daServicePlanReferenceDate;
  },
  setDaServicePlanContractNum() {
    self.daServicePlanContractNum = !self.daServicePlanContractNum;
  },
  setDaServicePlanGrp() {
    self.daServicePlanGrp = !self.daServicePlanGrp;
  },
  setDaScheduledServiceDueDate() {
    self.daScheduledServiceDueDate = !self.daScheduledServiceDueDate;
  },
  setDaScheduledServiceUsage() {
    self.daScheduledServiceUsage = !self.daScheduledServiceUsage;
  },
  setDaScheduledServiceUsageUnit() {
    self.daScheduledServiceUsageUnit = !self.daScheduledServiceUsageUnit;
  },
  setDaScheduledServiceUsageDueDate() {
    self.daScheduledServiceUsageDueDate = !self.daScheduledServiceUsageDueDate;
  },
  setDaScheduledServiceName() {
    self.daScheduledServiceName = !self.daScheduledServiceName;
  },
  setDaScheduledServiceServicePlanGrp() {
    self.daScheduledServiceServicePlanGrp = !self.daScheduledServiceServicePlanGrp;
  },
  setDaScheduledServiceStatus() {
    self.daScheduledServiceStatus = !self.daScheduledServiceStatus;
  },
  setDaScheduledServiceStopped() {
    self.daScheduledServiceStopped = !self.daScheduledServiceStopped;
  },
  setDaScheduledServiceWorkOrderNum() {
    self.daScheduledServiceWorkOrderNum = !self.daScheduledServiceWorkOrderNum;
  },
  setDaScheduledServiceWorkOrderStatus() {
    self.daScheduledServiceWorkOrderStatus = !self.daScheduledServiceWorkOrderStatus;
  },
  setDaScheduledServiceClosedDate() {
    self.daScheduledServiceClosedDate = !self.daScheduledServiceClosedDate;
  },
  setDaDocumentsName() {
    self.daDocumentsName = !self.daDocumentsName;
  },
  setDaDocumentsType() {
    self.daDocumentsType = !self.daDocumentsType;
  },
  setDaDocumentsNotes() {
    self.daDocumentsNotes = !self.daDocumentsNotes;
  },
  setDaServiceContractContractId() {
    self.daServiceContractContractId = !self.daServiceContractContractId;
  },
  setDaServiceContractDeviceId() {
    self.daServiceContractDeviceId = !self.daServiceContractDeviceId;
  },
  setDaServiceContractDeviceGroupId() {
    self.daServiceContractDeviceGroupId = !self.daServiceContractDeviceGroupId;
  },
  setDaServiceContractInvoiceContractId() {
    self.daServiceContractInvoiceContractId = !self.daServiceContractInvoiceContractId;
  },
  setDaServiceContractContractType() {
    self.daServiceContractContractType = !self.daServiceContractContractType;
  },
  setDaServiceContractCollectionStatus() {
    self.daServiceContractCollectionStatus = !self.daServiceContractCollectionStatus;
  },
  setDaServiceContractCustAccount() {
    self.daServiceContractCustAccount = !self.daServiceContractCustAccount;
  },
  setDaServiceContractName() {
    self.daServiceContractName = !self.daServiceContractName;
  },

  setDdListDevicNumber() {
    self.ddListDevicNumber = !self.ddListDevicNumber;
  },
  setDdListDeviceName() {
    self.ddListDeviceName = !self.ddListDeviceName;
  },
  setDdListItemNumber() {
    self.ddListItemNumber = !self.ddListItemNumber;
  },
  setDdListModelCodeNumber() {
    self.ddListModelCodeNumber = !self.ddListModelCodeNumber;
  },
  setDdListConfigName() {
    self.ddListConfigName = !self.ddListConfigName;
  },
  setDdListModelYear() {
    self.ddListModelYear = !self.ddListModelYear;
  },
  setDdListRegistrationNumber() {
    self.ddListRegistrationNumber = !self.ddListRegistrationNumber;
  },
  setDdListChassislNum() {
    self.ddListChassislNum = !self.ddListChassislNum;
  },
  setDdListMajorStatus() {
    self.ddListMajorStatus = !self.ddListMajorStatus;
  },
  setDdListSalesPrice() {
    self.ddListSalesPrice = !self.ddListSalesPrice;
  },
  setDdListCampaignPrice() {
    self.ddListCampaignPrice = !self.ddListCampaignPrice;
  },
  setDdGeneralDeviceNumber() {
    self.ddGeneralDeviceNumber = !self.ddGeneralDeviceNumber;
  },
  setDdGeneralDeviceName() {
    self.ddGeneralDeviceName = !self.ddGeneralDeviceName;
  },
  setDdGeneralMasterNum() {
    self.ddGeneralMasterNum = !self.ddGeneralMasterNum;
  },
  setDdGeneralRegNum() {
    self.ddGeneralRegNum = !self.ddGeneralRegNum;
  },
  setDdGeneralPrivateNum() {
    self.ddGeneralPrivateNum = !self.ddGeneralPrivateNum;
  },
  setDdGeneralMajorStatus() {
    self.ddGeneralMajorStatus = !self.ddGeneralMajorStatus;
  },
  setDdSetupBrandNum() {
    self.ddSetupBrandNum = !self.ddSetupBrandNum;
  },
  setDdSetupClassNum() {
    self.ddSetupClassNum = !self.ddSetupClassNum;
  },
  setDdSetupModelNum() {
    self.ddSetupModelNum = !self.ddSetupModelNum;
  },
  setDdSetupModelCodeNum() {
    self.ddSetupModelCodeNum = !self.ddSetupModelCodeNum;
  },
  setDdSetupModelConfig() {
    self.ddSetupModelConfig = !self.ddSetupModelConfig;
  },
  setDdSetupInteriorNum() {
    self.ddSetupInteriorNum = !self.ddSetupInteriorNum;
  },
  setDdSetupExteriorNum() {
    self.ddSetupExteriorNum = !self.ddSetupExteriorNum;
  },
  setDdSetupModelYear() {
    self.ddSetupModelYear = !self.ddSetupModelYear;
  },
  setDdSetupProductionDate() {
    self.ddSetupProductionDate = !self.ddSetupProductionDate;
  },
  setDdSetupCountryOrigin() {
    self.ddSetupCountryOrigin = !self.ddSetupCountryOrigin;
  },
  setDdSetupEngineDisplacement() {
    self.ddSetupEngineDisplacement = !self.ddSetupEngineDisplacement;
  },
  setDdSetupEngineHP() {
    self.ddSetupEngineHP = !self.ddSetupEngineHP;
  },
  setDdSetupEngineType() {
    self.ddSetupEngineType = !self.ddSetupEngineType;
  },
  setDdSetupEnginekW() {
    self.ddSetupEnginekW = !self.ddSetupEnginekW;
  },
  setDdSetupEngineBrand() {
    self.ddSetupEngineBrand = !self.ddSetupEngineBrand;
  },
  setDdSetupEngineModelYear() {
    self.ddSetupEngineModelYear = !self.ddSetupEngineModelYear;
  },
  setDdSetupEngineCertificationNumberEPA() {
    self.ddSetupEngineCertificationNumberEPA = !self.ddSetupEngineCertificationNumberEPA;
  },
  setDdSetupEngineCertificationNumberCARB() {
    self.ddSetupEngineCertificationNumberCARB = !self.ddSetupEngineCertificationNumberCARB;
  },
  setDdSetupEngineEmissionFamilyNameEPA() {
    self.ddSetupEngineEmissionFamilyNameEPA = !self.ddSetupEngineEmissionFamilyNameEPA;
  },
  setDdSetupEngineEmissionFamilyNameCARB() {
    self.ddSetupEngineEmissionFamilyNameCARB = !self.ddSetupEngineEmissionFamilyNameCARB;
  },
  setDdSetupBodyStyle() {
    self.ddSetupBodyStyle = !self.ddSetupBodyStyle;
  },
  setDdSetupFacelift() {
    self.ddSetupFacelift = !self.ddSetupFacelift;
  },
  setDdSetupFuelType() {
    self.ddSetupFuelType = !self.ddSetupFuelType;
  },
  setDdSetupNumCylinder() {
    self.ddSetupNumCylinder = !self.ddSetupNumCylinder;
  },
  setDdCustodianName() {
    self.ddCustodianName = !self.ddCustodianName;
  },
  setDdCustodianOwner() {
    self.ddCustodianOwner = !self.ddCustodianOwner;
  },
  setDdCustodianFirstOwner() {
    self.ddCustodianFirstOwner = !self.ddCustodianFirstOwner;
  },
  setDdCustodianOperator() {
    self.ddCustodianOperator = !self.ddCustodianOperator;
  },
  setDdCustodianCoOwner() {
    self.ddCustodianCoOwner = !self.ddCustodianCoOwner;
  },
  setDdCustodianActive() {
    self.ddCustodianActive = !self.ddCustodianActive;
  },
  setDdServiceCampaignLinesNum() {
    self.ddServiceCampaignLinesNum = !self.ddServiceCampaignLinesNum;
  },
  setDdServiceCampaignLinesDescription() {
    self.ddServiceCampaignLinesDescription = !self.ddServiceCampaignLinesDescription;
  },
  setDdServiceCampaignLinesStartDate() {
    self.ddServiceCampaignLinesStartDate = !self.ddServiceCampaignLinesStartDate;
  },
  setDdServiceCampaignLinesStatus() {
    self.ddServiceCampaignLinesStatus = !self.ddServiceCampaignLinesStatus;
  },
  setDdServiceCampaignLinesEndDate() {
    self.ddServiceCampaignLinesEndDate = !self.ddServiceCampaignLinesEndDate;
  },
  setDdDeviceAccessoriesSpecificationType() {
    self.ddDeviceAccessoriesSpecificationType = !self.ddDeviceAccessoriesSpecificationType;
  },
  setDdDeviceAccessoriesItemNum() {
    self.ddDeviceAccessoriesItemNum = !self.ddDeviceAccessoriesItemNum;
  },
  setDdDeviceAccessoriesDescription() {
    self.ddDeviceAccessoriesDescription = !self.ddDeviceAccessoriesDescription;
  },
  setDdDeviceAccessoriesComponentNum() {
    self.ddDeviceAccessoriesComponentNum = !self.ddDeviceAccessoriesComponentNum;
  },
  setDdDeviceAccessoriesCategory() {
    self.ddDeviceAccessoriesCategory = !self.ddDeviceAccessoriesCategory;
  },
  setDdDeviceAccessoriesQuantity() {
    self.ddDeviceAccessoriesQuantity = !self.ddDeviceAccessoriesQuantity;
  },
  setDdDeviceAccessoriesStocked() {
    self.ddDeviceAccessoriesStocked = !self.ddDeviceAccessoriesStocked;
  },
  setDdDeviceAccessoriesReplaced() {
    self.ddDeviceAccessoriesReplaced = !self.ddDeviceAccessoriesReplaced;
  },
  setDdDeviceAccessoriesStatus() {
    self.ddDeviceAccessoriesStatus = !self.ddDeviceAccessoriesStatus;
  },
  setDdDeviceAccessoriesTransactionDate() {
    self.ddDeviceAccessoriesTransactionDate = !self.ddDeviceAccessoriesTransactionDate;
  },
  setDdDeviceAccessoriesStatusDate() {
    self.ddDeviceAccessoriesStatusDate = !self.ddDeviceAccessoriesStatusDate;
  },
  setDdDeviceChildSpecificationType() {
    self.ddDeviceChildSpecificationType = !self.ddDeviceChildSpecificationType;
  },
  setDdDeviceChildItemNum() {
    self.ddDeviceChildItemNum = !self.ddDeviceChildItemNum;
  },
  setDdDeviceChildDescription() {
    self.ddDeviceChildDescription = !self.ddDeviceChildDescription;
  },
  setDdDeviceChildComponentNum() {
    self.ddDeviceChildComponentNum = !self.ddDeviceChildComponentNum;
  },
  setDdDeviceChildCategory() {
    self.ddDeviceChildCategory = !self.ddDeviceChildCategory;
  },
  setDdDeviceChildQuantity() {
    self.ddDeviceChildQuantity = !self.ddDeviceChildQuantity;
  },
  setDdDeviceChildStocked() {
    self.ddDeviceChildStocked = !self.ddDeviceChildStocked;
  },
  setDdDeviceChildReplaced() {
    self.ddDeviceChildReplaced = !self.ddDeviceChildReplaced;
  },
  setDdDeviceChildStatus() {
    self.ddDeviceChildStatus = !self.ddDeviceChildStatus;
  },
  setDdDeviceChildTransactionDate() {
    self.ddDeviceChildTransactionDate = !self.ddDeviceChildTransactionDate;
  },
  setDdDeviceChildStatusDate() {
    self.ddDeviceChildStatusDate = !self.ddDeviceChildStatusDate;
  },
  setDdDeviceComponentsSpecificationType() {
    self.ddDeviceComponentsSpecificationType = !self.ddDeviceComponentsSpecificationType;
  },
  setDdDeviceComponentsItemNum() {
    self.ddDeviceComponentsItemNum = !self.ddDeviceComponentsItemNum;
  },
  setDdDeviceComponentsDescription() {
    self.ddDeviceComponentsDescription = !self.ddDeviceComponentsDescription;
  },
  setDdDeviceComponentsNum() {
    self.ddDeviceComponentsNum = !self.ddDeviceComponentsNum;
  },
  setDdDeviceComponentsCategory() {
    self.ddDeviceComponentsCategory = !self.ddDeviceComponentsCategory;
  },
  setDdDeviceComponentsQuantity() {
    self.ddDeviceComponentsQuantity = !self.ddDeviceComponentsQuantity;
  },
  setDdDeviceComponentsStocked() {
    self.ddDeviceComponentsStocked = !self.ddDeviceComponentsStocked;
  },
  setDdDeviceComponentsReplaced() {
    self.ddDeviceComponentsReplaced = !self.ddDeviceComponentsReplaced;
  },
  setDdDeviceComponentsStatus() {
    self.ddDeviceComponentsStatus = !self.ddDeviceComponentsStatus;
  },
  setDdDeviceComponentsTranscationDate() {
    self.ddDeviceComponentsTranscationDate = !self.ddDeviceComponentsTranscationDate;
  },
  setDdDeviceComponentsStatusDate() {
    self.ddDeviceComponentsStatusDate = !self.ddDeviceComponentsStatusDate;
  },
  setDdOptionsSpecificationType() {
    self.ddOptionsSpecificationType = !self.ddOptionsSpecificationType;
  },
  setDdOptionsItemNum() {
    self.ddOptionsItemNum = !self.ddOptionsItemNum;
  },
  setDdOptionsDescription() {
    self.ddOptionsDescription = !self.ddOptionsDescription;
  },
  setDdOptionsComponentNum() {
    self.ddOptionsComponentNum = !self.ddOptionsComponentNum;
  },
  setDdOptionsCategory() {
    self.ddOptionsCategory = !self.ddOptionsCategory;
  },
  setDdOptionsQuantity() {
    self.ddOptionsQuantity = !self.ddOptionsQuantity;
  },
  setDdOptionsStocked() {
    self.ddOptionsStocked = !self.ddOptionsStocked;
  },
  setDdOptionsReplaced() {
    self.ddOptionsReplaced = !self.ddOptionsReplaced;
  },
  setDdOptionsStatus() {
    self.ddOptionsStatus = !self.ddOptionsStatus;
  },
  setDdOptionsTransactionDate() {
    self.ddOptionsTransactionDate = !self.ddOptionsTransactionDate;
  },
  setDdOptionsStatusDate() {
    self.ddOptionsStatusDate = !self.ddOptionsStatusDate;
  },
  setDdDevicePropertiesDeviceName() {
    self.ddDevicePropertiesDeviceName = !self.ddDevicePropertiesDeviceName;
  },
  setDdDevicePropertiesDeviceNum() {
    self.ddDevicePropertiesDeviceNum = !self.ddDevicePropertiesDeviceNum;
  },
  setDdDevicePropertiesPropertyGroup() {
    self.ddDevicePropertiesPropertyGroup = !self.ddDevicePropertiesPropertyGroup;
  },
  setDdDevicePropertiesPropertyGroupCode() {
    self.ddDevicePropertiesPropertyGroupCode = !self.ddDevicePropertiesPropertyGroupCode;
  },
  setDdDevicePropertiesNotes() {
    self.ddDevicePropertiesNotes = !self.ddDevicePropertiesNotes;
  },
  setDdWarrantyType() {
    self.ddWarrantyType = !self.ddWarrantyType;
  },
  setDdWarrantyDescription() {
    self.ddWarrantyDescription = !self.ddWarrantyDescription;
  },
  setDdWarrantStartDate() {
    self.ddWarrantStartDate = !self.ddWarrantStartDate;
  },
  setDdWarrantyEndDate() {
    self.ddWarrantyEndDate = !self.ddWarrantyEndDate;
  },
  setDdWarrantyStartUsage() {
    self.ddWarrantyStartUsage = !self.ddWarrantyStartUsage;
  },
  setDdWarrantyEndUsage() {
    self.ddWarrantyEndUsage = !self.ddWarrantyEndUsage;
  },
  setDdWorkOrderHistNum() {
    self.ddWorkOrderHistNum = !self.ddWorkOrderHistNum;
  },
  setDdWorkOrderHistDescription() {
    self.ddWorkOrderHistDescription = !self.ddWorkOrderHistDescription;
  },
  setDdWorkOrderHistCustomerAccount() {
    self.ddWorkOrderHistCustomerAccount = !self.ddWorkOrderHistCustomerAccount;
  },
  setDdWorkOrderHistStatus() {
    self.ddWorkOrderHistStatus = !self.ddWorkOrderHistStatus;
  },
  setDdWorkOrderHistWorkOrderGrp() {
    self.ddWorkOrderHistWorkOrderGrp = !self.ddWorkOrderHistWorkOrderGrp;
  },
  setDdWorkOrderHistType() {
    self.ddWorkOrderHistType = !self.ddWorkOrderHistType;
  },
  setDdWorkOrderHistStartDate() {
    self.ddWorkOrderHistStartDate = !self.ddWorkOrderHistStartDate;
  },
  setDdWorkOrderHistCreatedDate() {
    self.ddWorkOrderHistCreatedDate = !self.ddWorkOrderHistCreatedDate;
  },
  setDdWorkOrderHistEstimatedHours() {
    self.ddWorkOrderHistEstimatedHours = !self.ddWorkOrderHistEstimatedHours;
  },
  setDdWorkOrderHistHoursRemaining() {
    self.ddWorkOrderHistHoursRemaining = !self.ddWorkOrderHistHoursRemaining;
  },
  setDdClaimHistWarrantyClaim() {
    self.ddClaimHistWarrantyClaim = !self.ddClaimHistWarrantyClaim;
  },
  setDdClaimHistStatus() {
    self.ddClaimHistStatus = !self.ddClaimHistStatus;
  },
  setDdClaimHistApprovalStatus() {
    self.ddClaimHistApprovalStatus = !self.ddClaimHistApprovalStatus;
  },
  setDdClaimHistInvoiceStatus() {
    self.ddClaimHistInvoiceStatus = !self.ddClaimHistInvoiceStatus;
  },
  setDdClaimHistWarrantyClaimGrp() {
    self.ddClaimHistWarrantyClaimGrp = !self.ddClaimHistWarrantyClaimGrp;
  },
  setDdClaimHistType() {
    self.ddClaimHistType = !self.ddClaimHistType;
  },
  setDdClaimHistDevice() {
    self.ddClaimHistDevice = !self.ddClaimHistDevice;
  },
  setDdClaimHistPreWorkApproval() {
    self.ddClaimHistPreWorkApproval = !self.ddClaimHistPreWorkApproval;
  },
  setDdClaimHistUsage() {
    self.ddClaimHistUsage = !self.ddClaimHistUsage;
  },
  setDdServicePlanNum() {
    self.ddServicePlanNum = !self.ddServicePlanNum;
  },
  setDdServicePlanName() {
    self.ddServicePlanName = !self.ddServicePlanName;
  },
  setDdServicePlanStatus() {
    self.ddServicePlanStatus = !self.ddServicePlanStatus;
  },
  setDdServicePlanActivation() {
    self.ddServicePlanActivation = !self.ddServicePlanActivation;
  },
  setDdServicePlanReferenceDate() {
    self.ddServicePlanReferenceDate = !self.ddServicePlanReferenceDate;
  },
  setDdServicePlanContractNum() {
    self.ddServicePlanContractNum = !self.ddServicePlanContractNum;
  },
  setDdServicePlanGrp() {
    self.ddServicePlanGrp = !self.ddServicePlanGrp;
  },
  setDdScheduledServiceDueDate() {
    self.ddScheduledServiceDueDate = !self.ddScheduledServiceDueDate;
  },
  setDdScheduledServiceUsage() {
    self.ddScheduledServiceUsage = !self.ddScheduledServiceUsage;
  },
  setDdScheduledServiceUsageUnit() {
    self.ddScheduledServiceUsageUnit = !self.ddScheduledServiceUsageUnit;
  },
  setDdScheduledServiceUsageDueDate() {
    self.ddScheduledServiceUsageDueDate = !self.ddScheduledServiceUsageDueDate;
  },
  setDdScheduledServiceName() {
    self.ddScheduledServiceName = !self.ddScheduledServiceName;
  },
  setDdScheduledServiceServicePlanGrp() {
    self.ddScheduledServiceServicePlanGrp = !self.ddScheduledServiceServicePlanGrp;
  },
  setDdScheduledServiceStatus() {
    self.ddScheduledServiceStatus = !self.ddScheduledServiceStatus;
  },
  setDdScheduledServiceStopped() {
    self.ddScheduledServiceStopped = !self.ddScheduledServiceStopped;
  },
  setDdScheduledServiceWorkOrderNum() {
    self.ddScheduledServiceWorkOrderNum = !self.ddScheduledServiceWorkOrderNum;
  },
  setDdScheduledServiceWorkOrderStatus() {
    self.ddScheduledServiceWorkOrderStatus = !self.ddScheduledServiceWorkOrderStatus;
  },
  setDdScheduledServiceClosedDate() {
    self.ddScheduledServiceClosedDate = !self.ddScheduledServiceClosedDate;
  },
  setDdDocumentsName() {
    self.ddDocumentsName = !self.ddDocumentsName;
  },
  setDdDocumentsType() {
    self.ddDocumentsType = !self.ddDocumentsType;
  },
  setDdDocumentsNotes() {
    self.ddDocumentsNotes = !self.daDocumentsNotes;
  },
  setDdServiceContractContractId() {
    self.ddServiceContractContractId = !self.ddServiceContractContractId;
  },
  setDdServiceContractDeviceId() {
    self.ddServiceContractDeviceId = !self.ddServiceContractDeviceId;
  },
  setDdServiceContractDeviceGroupId() {
    self.ddServiceContractDeviceGroupId = !self.ddServiceContractDeviceGroupId;
  },
  setDdServiceContractInvoiceContractId() {
    self.ddServiceContractInvoiceContractId = !self.ddServiceContractInvoiceContractId;
  },
  setDdServiceContractContractType() {
    self.ddServiceContractContractType = !self.ddServiceContractContractType;
  },
  setDdServiceContractCollectionStatus() {
    self.ddServiceContractCollectionStatus = !self.ddServiceContractCollectionStatus;
  },
  setDdServiceContractCustAccount() {
    self.ddServiceContractCustAccount = !self.ddServiceContractCustAccount;
  },
  setDdServiceContractName() {
    self.ddServiceContractName = !self.ddServiceContractName;
  },
  setPiReleasedGeneralItemNumber() {
    self.piReleasedGeneralItemNumber = !self.piReleasedGeneralItemNumber;
  },
  setPiReleasedGeneralProductName() {
    self.piReleasedGeneralProductName = !self.piReleasedGeneralProductName;
  },
  setPiReleasedGeneralUnit() {
    self.piReleasedGeneralUnit = !self.piReleasedGeneralUnit;
  },
  setPiReleasedGeneralListPrice() {
    self.piReleasedGeneralListPrice = !self.piReleasedGeneralListPrice;
  },
  setPiReleasedGeneralPartsImage() {
    self.piReleasedGeneralPartsImage = !self.piReleasedGeneralPartsImage;
  },
  setPiReleasedSetupNetWeight() {
    self.piReleasedSetupNetWeight = !self.piReleasedSetupNetWeight;
  },
  setPiReleasedSetupTareWeight() {
    self.piReleasedSetupTareWeight = !self.piReleasedSetupTareWeight;
  },
  setPiReleasedSetupGrossWeight() {
    self.piReleasedSetupGrossWeight = !self.piReleasedSetupGrossWeight;
  },
  setPiReleasedSetupGrossDepth() {
    self.piReleasedSetupGrossDepth = !self.piReleasedSetupGrossDepth;
  },
  setPiReleasedSetupGrossWidth() {
    self.piReleasedSetupGrossWidth = !self.piReleasedSetupGrossWidth;
  },
  setPiReleasedSetupGrossHeight() {
    self.piReleasedSetupGrossHeight = !self.piReleasedSetupGrossHeight;
  },
  setPiReleasedSetupHazardousMaterial() {
    self.piReleasedSetupHazardousMaterial = !self.piReleasedSetupHazardousMaterial;
  },
  setPiReleasedSetupBlocked() {
    self.piReleasedSetupBlocked = !self.piReleasedSetupBlocked;
  },
  setPiReleasedSetupReasonCode() {
    self.piReleasedSetupReasonCode = !self.piReleasedSetupReasonCode;
  },
  setPiReleasedSetupBrand() {
    self.piReleasedSetupBrand = !self.piReleasedSetupBrand;
  },
  setPiReleasedSetupClass() {
    self.piReleasedSetupClass = !self.piReleasedSetupClass;
  },
  setPiReleasedSetupModel() {
    self.piReleasedSetupModel = !self.piReleasedSetupModel;
  },
  setPiReleasedItemChangeHeaderItemChange() {
    self.piReleasedItemChangeHeaderItemChange = !self.piReleasedItemChangeHeaderItemChange;
  },
  setPiReleasedItemChangeHeaderItemNumber() {
    self.piReleasedItemChangeHeaderItemNumber = !self.piReleasedItemChangeHeaderItemNumber;
  },
  setPiReleasedItemChangeHeaderName() {
    self.piReleasedItemChangeHeaderName = !self.piReleasedItemChangeHeaderName;
  },
  setPiReleasedItemChangeHeaderProductName() {
    self.piReleasedItemChangeHeaderProductName = !self.piReleasedItemChangeHeaderProductName;
  },
  setPiReleasedItemChangeHeaderDescription() {
    self.piReleasedItemChangeHeaderDescription = !self.piReleasedItemChangeHeaderDescription;
  },
  setPiReleasedItemChangeHeaderStatus() {
    self.piReleasedItemChangeHeaderStatus = !self.piReleasedItemChangeHeaderStatus;
  },
  setPiReleasedItemChangeLineItemQty() {
    self.piReleasedItemChangeLineItemQty = !self.piReleasedItemChangeLineItemQty;
  },
  setPiReleasedItemChangeLineItemNumber() {
    self.piReleasedItemChangeLineItemNumber = !self.piReleasedItemChangeLineItemNumber;
  },
  setPiReleasedItemChangeLineName() {
    self.piReleasedItemChangeLineName = !self.piReleasedItemChangeLineName;
  },
  setPiReleasedItemChangeLineUnit() {
    self.piReleasedItemChangeLineUnit = !self.piReleasedItemChangeLineUnit;
  },
  setPiReleasedItemChangeLineUniDirectional() {
    self.piReleasedItemChangeLineUniDirectional = !self.piReleasedItemChangeLineUniDirectional;
  },
  setPiReleasedOnHandQtyItemNumber() {
    self.piReleasedOnHandQtyItemNumber = !self.piReleasedOnHandQtyItemNumber;
  },
  setPiReleasedOnHandQtyName() {
    self.piReleasedOnHandQtyName = !self.piReleasedOnHandQtyName;
  },
  setPiReleasedOnHandQtyConfiguration() {
    self.piReleasedOnHandQtyConfiguration = !self.piReleasedOnHandQtyConfiguration;
  },
  setPiReleasedOnHandQtySize() {
    self.piReleasedOnHandQtySize = !self.piReleasedOnHandQtySize;
  },
  setPiReleasedOnHandQtyColor() {
    self.piReleasedOnHandQtyColor = !self.piReleasedOnHandQtyColor;
  },
  setPiReleasedOnHandQtyStyle() {
    self.piReleasedOnHandQtyStyle = !self.piReleasedOnHandQtyStyle;
  },
  setPiReleasedOnHandQtySerialNumber() {
    self.piReleasedOnHandQtySerialNumber = !self.piReleasedOnHandQtySerialNumber;
  },
  setPiReleasedOnHandQtySite() {
    self.piReleasedOnHandQtySite = !self.piReleasedOnHandQtySite;
  },
  setPiReleasedOnHandQtyWareHouse() {
    self.piReleasedOnHandQtyWareHouse = !self.piReleasedOnHandQtyWareHouse;
  },
  setPiReleasedOnHandQtyLocation() {
    self.piReleasedOnHandQtyLocation = !self.piReleasedOnHandQtyLocation;
  },
  setPiReleasedOnHandQtyLicensePlate() {
    self.piReleasedOnHandQtyLicensePlate = !self.piReleasedOnHandQtyLicensePlate;
  },
  setPiReleasedOnHandQtyQty() {
    self.piReleasedOnHandQtyQty = !self.piReleasedOnHandQtyQty;
  },
  setPiReleasedAlternativeItemsNumber() {
    self.piReleasedAlternativeItemsNumber = !self.piReleasedAlternativeItemsNumber;
  },
  setPiReleasedAlternativeItemsProductName() {
    self.piReleasedAlternativeItemsProductName = !self.piReleasedAlternativeItemsProductName;
  },
  setPiReleasedAlternativeItemsAlternativeConfig() {
    self.piReleasedAlternativeItemsAlternativeConfig = !self.piReleasedAlternativeItemsAlternativeConfig;
  },
  setPiReleasedAlternativeItemsSize() {
    self.piReleasedAlternativeItemsSize = !self.piReleasedAlternativeItemsSize;
  },
  setPiReleasedAlternativeItemsColor() {
    self.piReleasedAlternativeItemsColor = !self.piReleasedAlternativeItemsColor;
  },
  setPiReleasedAlternativeItemsStyle() {
    self.piReleasedAlternativeItemsStyle = !self.piReleasedAlternativeItemsStyle;
  },
  setPiReleasedSuppItemsSuppItem() {
    self.piReleasedSuppItemsSuppItem = !self.piReleasedSuppItemsSuppItem;
  },
  setPiReleasedSuppItemsProductName() {
    self.piReleasedSuppItemsProductName = !self.piReleasedSuppItemsProductName;
  },
  setPiReleasedSuppItemsConfig() {
    self.piReleasedSuppItemsConfig = !self.piReleasedSuppItemsConfig;
  },
  setPiReleasedSuppItemsSize() {
    self.piReleasedSuppItemsSize = !self.piReleasedSuppItemsSize;
  },
  setPiReleasedSuppItemsColor() {
    self.piReleasedSuppItemsColor = !self.piReleasedSuppItemsColor;
  },
  setPiReleasedSuppItemsStyle() {
    self.piReleasedSuppItemsStyle = !self.piReleasedSuppItemsStyle;
  },
  setPiReleasedSuppItemsQty() {
    self.piReleasedSuppItemsQty = !self.piReleasedSuppItemsQty;
  },
  setPiReleasedSuppItemsUnit() {
    self.piReleasedSuppItemsUnit = !self.piReleasedSuppItemsUnit;
  },
  setPiReleasedSuppItemsFreeOfCharge() {
    self.piReleasedSuppItemsFreeOfCharge = !self.piReleasedSuppItemsFreeOfCharge;
  },
  setPiReleasedSuppItemsItemType() {
    self.piReleasedSuppItemsItemType = !self.piReleasedSuppItemsItemType;
  },
  setPiReleasedSuppItemsOnlyOnce() {
    self.piReleasedSuppItemsOnlyOnce = !self.piReleasedSuppItemsOnlyOnce;
  },
  setPiReleasedBomBom() {
    self.piReleasedBomBom = !self.piReleasedBomBom;
  },
  setPiReleasedBomName() {
    self.piReleasedBomName = !self.piReleasedBomName;
  },
  setPiReleasedBomItemNumber() {
    self.piReleasedBomItemNumber = !self.piReleasedBomItemNumber;
  },
  setPiReleasedBomProductName() {
    self.piReleasedBomProductName = !self.piReleasedBomProductName;
  },
  setPiReleasedBomSubBom() {
    self.piReleasedBomSubBom = !self.piReleasedBomSubBom;
  },
  setPiReleasedBomConfig() {
    self.piReleasedBomConfig = !self.piReleasedBomConfig;
  },
  setPiReleasedBomSize() {
    self.piReleasedBomSize = !self.piReleasedBomSize;
  },
  setPiReleasedBomColor() {
    self.piReleasedBomColor = !self.piReleasedBomColor;
  },
  setPiReleasedBomStyle() {
    self.piReleasedBomStyle = !self.piReleasedBomStyle;
  },
  setPiReleasedBomQty() {
    self.piReleasedBomQty = !self.piReleasedBomQty;
  },
  setPiReleasedBomFromDate() {
    self.piReleasedBomFromDate = !self.piReleasedBomFromDate;
  },
  setPiReleasedBomToDate() {
    self.piReleasedBomToDate = !self.piReleasedBomToDate;
  },
  setPiReleasedSalesPriceCurrency() {
    self.piReleasedSalesPriceCurrency = !self.piReleasedSalesPriceCurrency;
  },
  setPiReleasedSalesPriceAccountSelection() {
    self.piReleasedSalesPriceAccountSelection = !self.piReleasedSalesPriceAccountSelection;
  },
  setPiReleasedSalesPriceConfig() {
    self.piReleasedSalesPriceConfig = !self.piReleasedSalesPriceConfig;
  },
  setPiReleasedSalesPriceSize() {
    self.piReleasedSalesPriceSize = !self.piReleasedSalesPriceSize;
  },
  setPiReleasedSalesPriceColor() {
    self.piReleasedSalesPriceColor = !self.piReleasedSalesPriceColor;
  },
  setPiReleasedSalesPriceStyle() {
    self.piReleasedSalesPriceStyle = !self.piReleasedSalesPriceStyle;
  },
  setPiReleasedSalesPriceFromDate() {
    self.piReleasedSalesPriceFromDate = !self.piReleasedSalesPriceFromDate;
  },
  setPiReleasedSalesPriceToDate() {
    self.piReleasedSalesPriceToDate = !self.piReleasedSalesPriceToDate;
  },
  setPiReleasedSalesPriceFrom() {
    self.piReleasedSalesPriceFrom = !self.piReleasedSalesPriceFrom;
  },
  setPiReleasedSalesPriceTo() {
    self.piReleasedSalesPriceTo = !self.piReleasedSalesPriceTo;
  },
  setPiReleasedSalesPricePrice() {
    self.piReleasedSalesPricePrice = !self.piReleasedSalesPricePrice;
  },
  setPiReleasedLineDiscountCurrency() {
    self.piReleasedLineDiscountCurrency = !self.piReleasedLineDiscountCurrency;
  },
  setPiReleasedLineDiscountAccountSelection() {
    self.piReleasedLineDiscountAccountSelection = !self.piReleasedLineDiscountAccountSelection;
  },
  setPiReleasedLineDiscountPartRelation() {
    self.piReleasedLineDiscountPartRelation = !self.piReleasedLineDiscountPartRelation;
  },
  setPiReleasedLineDiscountConfig() {
    self.piReleasedLineDiscountConfig = !self.piReleasedLineDiscountConfig;
  },
  setPiReleasedLineDiscountSize() {
    self.piReleasedLineDiscountSize = !self.piReleasedLineDiscountSize;
  },
  setPiReleasedLineDiscountColor() {
    self.piReleasedLineDiscountColor = !self.piReleasedLineDiscountColor;
  },
  setPiReleasedLineDiscountStyle() {
    self.piReleasedLineDiscountStyle = !self.piReleasedLineDiscountStyle;
  },
  setPiReleasedLineDiscountFromDate() {
    self.piReleasedLineDiscountFromDate = !self.piReleasedLineDiscountFromDate;
  },
  setPiReleasedLineDiscountToDate() {
    self.piReleasedLineDiscountToDate = !self.piReleasedLineDiscountToDate;
  },
  setPiReleasedLineDiscountFrom() {
    self.piReleasedLineDiscountFrom = !self.piReleasedLineDiscountFrom;
  },
  setPiReleasedLineDiscountTo() {
    self.piReleasedLineDiscountTo = !self.piReleasedLineDiscountTo;
  },
  setPiReleasedLineDiscountAmountInTransactionCurrency() {
    self.piReleasedLineDiscountAmountInTransactionCurrency = !self.piReleasedLineDiscountAmountInTransactionCurrency;
  },
  setPiReleasedLineDiscountDiscountPct1() {
    self.piReleasedLineDiscountDiscountPct1 = !self.piReleasedLineDiscountDiscountPct1;
  },
  setPiReleasedLineDiscountDiscountPct2() {
    self.piReleasedLineDiscountDiscountPct2 = !self.piReleasedLineDiscountDiscountPct2;
  },
  setPiSupplierGeneralBrand() {
    self.piSupplierGeneralBrand = !self.piSupplierGeneralBrand;
  },
  setPiSupplierGeneralDiscontinued() {
    self.piSupplierGeneralDiscontinued = !self.piSupplierGeneralDiscontinued;
  },
  setPiSupplierGeneralBlockedPrdctCreation() {
    self.piSupplierGeneralBlockedPrdctCreation = !self.piSupplierGeneralBlockedPrdctCreation;
  },
  setPiSupplierGeneralHeight() {
    self.piSupplierGeneralHeight = !self.piSupplierGeneralHeight;
  },
  setPiSupplierGeneralNetWeight() {
    self.piSupplierGeneralNetWeight = !self.piSupplierGeneralNetWeight;
  },
  setPiSupplierGeneralSuppItemNum() {
    self.piSupplierGeneralSuppItemNum = !self.piSupplierGeneralSuppItemNum;
  },
  setPiSupplierGeneralRefurbished() {
    self.piSupplierGeneralRefurbished = !self.piSupplierGeneralRefurbished;
  },
  setPiSupplierGeneralSalesPrice() {
    self.piSupplierGeneralSalesPrice = !self.piSupplierGeneralSalesPrice;
  },
  setPiSupplierGeneralWidth() {
    self.piSupplierGeneralWidth = !self.piSupplierGeneralWidth;
  },
  setPiSupplierGeneralSuppItemName() {
    self.piSupplierGeneralSuppItemName = !self.piSupplierGeneralSuppItemName;
  },
  setPiSupplierGeneralReturnItem() {
    self.piSupplierGeneralReturnItem = !self.piSupplierGeneralReturnItem;
  },
  setPiSupplierGeneralDepth() {
    self.piSupplierGeneralDepth = !self.piSupplierGeneralDepth;
  },
    setPiSupplierGeneralPartsImage() {
    self.piSupplierGeneralPartsImage = !self.piSupplierGeneralPartsImage;
  },
  setPiSupplierItemChangeHeaderItemChange() {
    self.piSupplierItemChangeHeaderItemChange = !self.piSupplierItemChangeHeaderItemChange;
  },
  setPiSupplierItemChangeHeaderSuppItemNum() {
    self.piSupplierItemChangeHeaderSuppItemNum = !self.piSupplierItemChangeHeaderSuppItemNum;
  },
  setPiSupplierItemChangeHeaderName() {
    self.piSupplierItemChangeHeaderName = !self.piSupplierItemChangeHeaderName;
  },
  setPiSupplierItemChangeHeaderBrandNum() {
    self.piSupplierItemChangeHeaderBrandNum = !self.piSupplierItemChangeHeaderBrandNum;
  },
  setPiSupplierItemChangeHeaderDescription() {
    self.piSupplierItemChangeHeaderDescription = !self.piSupplierItemChangeHeaderDescription;
  },
  setPiSupplierItemChangeHeaderStatus() {
    self.piSupplierItemChangeHeaderStatus = !self.piSupplierItemChangeHeaderStatus;
  },
  setPiSupplierItemChangeLineBrandNumber() {
    self.piSupplierItemChangeLineBrandNumber = !self.piSupplierItemChangeLineBrandNumber;
  },
  setPiSupplierItemChangeLineSuppItemNum() {
    self.piSupplierItemChangeLineSuppItemNum = !self.piSupplierItemChangeLineSuppItemNum;
  },
  setPiSupplierItemChangeLineName() {
    self.piSupplierItemChangeLineName = !self.piSupplierItemChangeLineName;
  },
  setPiSupplierItemChangeLineQty() {
    self.piSupplierItemChangeLineQty = !self.piSupplierItemChangeLineQty;
  },
  setPiSupplierItemChangeLineUnit() {
    self.piSupplierItemChangeLineUnit = !self.piSupplierItemChangeLineUnit;
  },
  setPiSupplierItemChangeLineUniDirectional() {
    self.piSupplierItemChangeLineUniDirectional = !self.piSupplierItemChangeLineUniDirectional;
  },
  setPiSupplierSuppItemsBrandNum() {
    self.piSupplierSuppItemsBrandNum = !self.piSupplierSuppItemsBrandNum;
  },
  setPiSupplierSuppitemsSuppItemNum() {
    self.piSupplierSuppitemsSuppItemNum = !self.piSupplierSuppitemsSuppItemNum;
  },
  setPiSupplierSuppItemsReturnItemBehavior() {
    self.piSupplierSuppItemsReturnItemBehavior = !self.piSupplierSuppItemsReturnItemBehavior;
  },
  setPiSupplierSuppItemsNum() {
    self.piSupplierSuppItemsNum = !self.piSupplierSuppItemsNum;
  },
  setPiSupplierSuppItemsItemName() {
    self.piSupplierSuppItemsItemName = !self.piSupplierSuppItemsItemName;
  },
  setPiReleasedSupplierItemBrandNum() {
    self.piReleasedSupplierItemBrandNum = !self.piReleasedSupplierItemBrandNum;
  },
  setPiReleasedSupplierItemSuppItemNum() {
    self.piReleasedSupplierItemSuppItemNum = !self.piReleasedSupplierItemSuppItemNum;
  },
  setPiReleasedSupplierItemSuppWarehouse() {
    self.piReleasedSupplierItemSuppWarehouse = !self.piReleasedSupplierItemSuppWarehouse;
  },
  setPiReleasedSupplierItemQty() {
    self.piReleasedSupplierItemQty = !self.piReleasedSupplierItemQty;
  },
  setPiReleasedSupplierItemOnHandDate() {
    self.piReleasedSupplierItemOnHandDate = !self.piReleasedSupplierItemOnHandDate;
  },
  setPiSupplierItemOnHandBrandNum() {
    self.piSupplierItemOnHandBrandNum = !self.piSupplierItemOnHandBrandNum;
  },
  setPiSupplierItemOnHandSuppItemNum() {
    self.piSupplierItemOnHandSuppItemNum = !self.piSupplierItemOnHandSuppItemNum;
  },
  setPiSupplierItemOnHandSuppWarehouse() {
    self.piSupplierItemOnHandSuppWarehouse = !self.piSupplierItemOnHandSuppWarehouse;
  },
  setPiSupplierItemOnHandQty() {
    self.piSupplierItemOnHandQty = !self.piSupplierItemOnHandQty;
  },
  setPiSupplierItemOnHandOnHandDate() {
    self.piSupplierItemOnHandOnHandDate = !self.piSupplierItemOnHandOnHandDate;
  },
  setScListNum() {
    self.scListNum = !self.scListNum;
  },
  setScListManufacturerServiceCampaign() {
    self.scListManufacturerServiceCampaign = !self.scListManufacturerServiceCampaign;
  },
  setScListDescription() {
    self.scListDescription = !self.scListDescription;
  },
  setScListBrand() {
    self.scListBrand = !self.scListBrand;
  },
  setScListStartDate() {
    self.scListStartDate = !self.scListStartDate;
  },
  setScListEndDate() {
    self.scListEndDate = !self.scListEndDate;
  },
  setScListStatus() {
    self.scListStatus = !self.scListStatus;
  },
  setScListCampaignType() {
    self.scListCampaignType = !self.scListCampaignType;
  },
  setScInfoGeneralNum() {
    self.scInfoGeneralNum = !self.scInfoGeneralNum;
  },
  setScInfoGeneralDescription() {
    self.scInfoGeneralDescription = !self.scInfoGeneralDescription;
  },
  setScInfoGeneralExtendedEndDate() {
    self.scInfoGeneralExtendedEndDate = !self.scInfoGeneralExtendedEndDate;
  },
  setScInfoGeneralNotes() {
    self.scInfoGeneralNotes = !self.scInfoGeneralNotes;
  },
  setScInfoGeneralStartDate() {
    self.scInfoGeneralStartDate = !self.scInfoGeneralStartDate;
  },
  setScInfoGeneralStatus() {
    self.scInfoGeneralStatus = !self.scInfoGeneralStatus;
  },
  setScInfoGeneralBrandNum() {
    self.scInfoGeneralBrandNum = !self.scInfoGeneralBrandNum;
  },
  setScInfoGeneralEndDate() {
    self.scInfoGeneralEndDate = !self.scInfoGeneralEndDate;
  },
  setScInfoDamageSymptomCode() {
    self.scInfoDamageSymptomCode = !self.scInfoDamageSymptomCode;
  },
  setScInfoDamageFailureCodeA() {
    self.scInfoDamageFailureCodeA = !self.scInfoDamageFailureCodeA;
  },
  setScInfoDamageCauseCOde() {
    self.scInfoDamageCauseCOde = !self.scInfoDamageCauseCOde;
  },
  setScInfoDamageFailureCodeB() {
    self.scInfoDamageFailureCodeB = !self.scInfoDamageFailureCodeB;
  },
  setScInfoDamageResolutionCode() {
    self.scInfoDamageResolutionCode = !self.scInfoDamageResolutionCode;
  },
  setScInfoDamageFailureCodeC() {
    self.scInfoDamageFailureCodeC = !self.scInfoDamageFailureCodeC;
  },
  setScInfoLinesDeviceNum() {
    self.scInfoLinesDeviceNum = !self.scInfoLinesDeviceNum;
  },
  setScInfoLinesChassisNum() {
    self.scInfoLinesChassisNum = !self.scInfoLinesChassisNum;
  },
  setScInfoLinesName() {
    self.scInfoLinesName = !self.scInfoLinesName;
  },
  setScInfoLinesServiceCampaignStatus() {
    self.scInfoLinesServiceCampaignStatus = !self.scInfoLinesServiceCampaignStatus;
  },
  setScInfoLinesServiceCampaignCloseDate() {
    self.scInfoLinesServiceCampaignCloseDate = !self.scInfoLinesServiceCampaignCloseDate;
  },
  setScInfoDocumentsName() {
    self.scInfoDocumentsName = !self.scInfoDocumentsName;
  },
  setScInfoDocumentsType() {
    self.scInfoDocumentsType = !self.scInfoDocumentsType;
  },
  setScInfoDocumentsNotes() {
    self.scInfoDocumentsNotes = !self.scInfoDocumentsNotes;
  },
  setScInfoJobListId() {
    self.scInfoJobListId = !self.scInfoJobListId
  },
  setScInfoJobListDescription() {
    self.scInfoJobListDescription = !self.scInfoJobListDescription
  },
  setScInfoJobListSymptomCode() {
    self.scInfoJobListSymptomCode = !self.scInfoJobListSymptomCode
  },
  setScInfoJobListCauseCode() {
    self.scInfoJobListCauseCode = !self.scInfoJobListCauseCode
  },
  setScInfoJobListResolutionCode() {
    self.scInfoJobListResolutionCode = !self.scInfoJobListResolutionCode
  },
  setScInfoJobListLaborsOperationNumber() {
    self.scInfoJobListLaborsOperationNumber = !self.scInfoJobListLaborsOperationNumber
  },
  setScInfoJobListLaborsDescription() {
    self.scInfoJobListLaborsDescription = !self.scInfoJobListLaborsDescription
  },
  setScInfoJobListLaborsStandardHours() {
    self.scInfoJobListLaborsStandardHours = !self.scInfoJobListLaborsStandardHours
  },
  setScInfoJobListLaborsMultiply() {
    self.scInfoJobListLaborsMultiply = !self.scInfoJobListLaborsMultiply
  },
  setScInfoJobListLaborsFixedHours() {
    self.scInfoJobListLaborsFixedHours = !self.scInfoJobListLaborsFixedHours
  },
  setScInfoJobListLaborsMainOperation() {
    self.scInfoJobListLaborsMainOperation = !self.scInfoJobListLaborsMainOperation
  },
  setScInfoJobListLaborsStart() {
    self.scInfoJobListLaborsStart = !self.scInfoJobListLaborsStart
  },
  setScInfoJobListLaborsFinish() {
    self.scInfoJobListLaborsFinish = !self.scInfoJobListLaborsFinish
  },
  setScInfoJobListPartsNumber() {
    self.scInfoJobListPartsNumber = !self.scInfoJobListPartsNumber
  },
  setScInfoJobListPartsDescription() {
    self.scInfoJobListPartsDescription = !self.scInfoJobListPartsDescription
  },
  setScInfoJobListPartsQuantity() {
    self.scInfoJobListPartsQuantity = !self.scInfoJobListPartsQuantity
  },
  setScInfoJobListPartsUnit() {
    self.scInfoJobListPartsUnit = !self.scInfoJobListPartsUnit
  },
  setScInfoJobListPartsUnitPrice() {
    self.scInfoJobListPartsUnitPrice = !self.scInfoJobListPartsUnitPrice
  },
  setScInfoJobListPartsDiscount() {
    self.scInfoJobListPartsDiscount = !self.scInfoJobListPartsDiscount
  },
  setScInfoJobListPartsDiscountPercentage() {
    self.scInfoJobListPartsDiscountPercentage = !self.scInfoJobListPartsDiscountPercentage
  },
  setScInfoJobListPartsTotalPrice() {
    self.scInfoJobListPartsTotalPrice = !self.scInfoJobListPartsTotalPrice
  },
  setPiListInvoiceNum() {
    self.piListInvoiceNum = !self.piListInvoiceNum;
  },
  setPiListSalesOrderNum() {
    self.piListSalesOrderNum = !self.piListSalesOrderNum;
  },
  setPiListInvoiceDate() {
    self.piListInvoiceDate = !self.piListInvoiceDate;
  },
  setPiListDealerReferenceNumber() {
    self.piListDealerReferenceNumber = !self.piListDealerReferenceNumber;
  },
  setPiListInvoiceAmount() {
    self.piListInvoiceAmount = !self.piListInvoiceAmount;
  },
  setPiListCurrency() {
    self.piListCurrency = !self.piListCurrency;
  },
  setPiDetailsGeneralInvoiceNum() {
    self.piDetailsGeneralInvoiceNum = !self.piDetailsGeneralInvoiceNum;
  },
  setPiDetailsGeneralInvoiceDate() {
    self.piDetailsGeneralInvoiceDate = !self.piDetailsGeneralInvoiceDate;
  },
  setPiDetailsGeneralDealerReferenceNumber() {
    self.piDetailsGeneralDealerReferenceNumber = !self.piDetailsGeneralDealerReferenceNumber;
  },
  setPiDetailsGeneralCurrency() {
    self.piDetailsGeneralCurrency = !self.piDetailsGeneralCurrency;
  },
  setPiDetailsSalesOrderNum() {
    self.piDetailsSalesOrderNum = !self.piDetailsSalesOrderNum;
  },
  setPiDetailsShipToAddress() {
    self.piDetailsShipToAddress = !self.piDetailsShipToAddress;
  },
  setPiDetailsLinesPartNum() {
    self.piDetailsLinesPartNum = !self.piDetailsLinesPartNum;
  },
  setPiDetailsLinesPartName() {
    self.piDetailsLinesPartName = !self.piDetailsLinesPartName;
  },
  setPiDetailsLinesDeviceRef() {
    self.piDetailsLinesDeviceRef = !self.piDetailsLinesDeviceRef;
  },
  setPiDetailsLinesStatus() {
    self.piDetailsLinesStatus = !self.piDetailsLinesStatus;
  },
  setPiDetailsLinesQty() {
    self.piDetailsLinesQty = !self.piDetailsLinesQty;
  },
  setPiDetailsLinesUnitPrice() {
    self.piDetailsLinesUnitPrice = !self.piDetailsLinesUnitPrice;
  },
  setPiDetailsLinesDiscount() {
    self.piDetailsLinesDiscount = !self.piDetailsLinesDiscount;
  },
  setPiDetailsLinesDiscountPct() {
    self.piDetailsLinesDiscountPct = !self.piDetailsLinesDiscountPct;
  },
  setPiDetailsLinesTotalPrice() {
    self.piDetailsLinesTotalPrice = !self.piDetailsLinesTotalPrice;
  },
  setDiListInvoiceNum() {
    self.diListInvoiceNum = !self.diListInvoiceNum;
  },
  setDiListSalesOrderNum() {
    self.diListSalesOrderNum = !self.diListSalesOrderNum;
  },
  setDiListInvoiceDate() {
    self.diListInvoiceDate = !self.diListInvoiceDate;
  },
  setDiListDealerReferenceNumber() {
    self.diListDealerReferenceNumber = !self.diListDealerReferenceNumber;
  },
  setDiListInvoiceAmount() {
    self.diListInvoiceAmount = !self.diListInvoiceAmount;
  },
  setDiListCurrency() {
    self.diListCurrency = !self.diListCurrency;
  },
  setDiListDueDate() {
    self.diListDueDate = !self.diListDueDate;
  },
  setDiDetailsGeneralInvoiceNum() {
    self.diDetailsGeneralInvoiceNum = !self.diDetailsGeneralInvoiceNum;
  },
  setDiDetailsGeneralInvoiceDate() {
    self.diDetailsGeneralInvoiceDate = !self.diDetailsGeneralInvoiceDate;
  },
  setDiDetailsGeneralDealerReferenceNumber() {
    self.diDetailsGeneralDealerReferenceNumber = !self.diDetailsGeneralDealerReferenceNumber;
  },
  setDiDetailsGeneralCurrency() {
    self.diDetailsGeneralCurrency = !self.diDetailsGeneralCurrency;
  },
  setDiDetailsGeneralDueDate() {
    self.diDetailsGeneralDueDate = !self.diDetailsGeneralDueDate;
  },
  setDiDetailsSalesOrderNum() {
    self.diDetailsSalesOrderNum = !self.diDetailsSalesOrderNum;
  },
  setDiDetailsShipToAddress() {
    self.diDetailsShipToAddress = !self.diDetailsShipToAddress;
  },
  setDiDetailsYourOrderDeviceConfigNum() {
    self.diDetailsYourOrderDeviceConfigNum = !self.diDetailsYourOrderDeviceConfigNum;
  },
  setDiDetailsYourOrderDeviceConfigName() {
    self.diDetailsYourOrderDeviceConfigName = !self.diDetailsYourOrderDeviceConfigName;
  },
  setDiDetailsYourOrderDeviceNum() {
    self.diDetailsYourOrderDeviceNum = !self.diDetailsYourOrderDeviceNum;
  },
  setDiDetailsYourOrderQty() {
    self.diDetailsYourOrderQty = !self.diDetailsYourOrderQty;
  },
  setDiDetailsYourOrderUnit() {
    self.diDetailsYourOrderUnit = !self.diDetailsYourOrderUnit;
  },
  setDiDetailsYourOrderUnitPrice() {
    self.diDetailsYourOrderUnitPrice = !self.diDetailsYourOrderUnitPrice;
  },
  setDiDetailsYourOrderDiscount() {
    self.diDetailsYourOrderDiscount = !self.diDetailsYourOrderDiscount;
  },
  setDiDetailsYourOrderDisccountPct() {
    self.diDetailsYourOrderDisccountPct = !self.diDetailsYourOrderDisccountPct;
  },
  setDiDetailsYourOrderTotalAmount() {
    self.diDetailsYourOrderTotalAmount = !self.diDetailsYourOrderTotalAmount;
  },

  setDboListReqShipDate() {
    self.dboListReqShipDate = !self.dboListReqShipDate;
  },
  setDboListConfirmedShipDate() {
    self.dboListConfirmedShipDate = !self.dboListConfirmedShipDate;
  },
  setDboListSalesOrder() {
    self.dboListSalesOrder = !self.dboListSalesOrder;
  },
  setDboListItemNum() {
    self.dboListItemNum = !self.dboListItemNum;
  },
  setDboListDescription() {
    self.dboListDescription = !self.dboListDescription;
  },
  setDboListDeviceNum() {
    self.dboListDeviceNum = !self.dboListDeviceNum;
  },
  setDboListQty() {
    self.dboListQty = !self.dboListQty;
  },
  setDboListDeliveryReminder() {
    self.dboListDeliveryReminder = !self.dboListDeliveryReminder;
  },
  setDboListUnit() {
    self.dboListUnit = !self.dboListUnit;
  },
  setDboListStatus() {
    self.dboListStatus = !self.dboListStatus;
  },
  setDboListPurchaseOrderNumber() {
    self.dboListPurchaseOrderNumber = !self.dboListPurchaseOrderNumber;
   },
  setPboListReqShipDate() {
    self.pboListReqShipDate = !self.pboListReqShipDate;
  },
  setPboListConfirmedShipDate() {
    self.pboListConfirmedShipDate = !self.pboListConfirmedShipDate;
  },
  setPboListSalesOrder() {
    self.pboListSalesOrder = !self.pboListSalesOrder;
  },
  setPboListItemNum() {
    self.pboListItemNum = !self.pboListItemNum;
  },
  setPboListDescription() {
    self.pboListDescription = !self.pboListDescription;
  },
  setPboListDeviceNum() {
    self.pboListDeviceNum = !self.pboListDeviceNum;
  },
  setPboListQty() {
    self.pboListQty = !self.pboListQty;
  },
  setPboListDeliveryReminder() {
    self.pboListDeliveryReminder = !self.pboListDeliveryReminder;
  },
  setPboListUnit() {
    self.pboListUnit = !self.pboListUnit;
  },
  setPboListStatus() {
    self.pboListStatus = !self.pboListStatus;
  },
  setProListWebOrderId() {
    self.proListWebOrderId = !self.proListWebOrderId;
  },
  setProListReturnOrderNum() {
    self.proListReturnOrderNum = !self.proListReturnOrderNum;
  },
  setPboListPurchaseOrderNumber() {
    self.pboListPurchaseOrderNumber = !self.pboListPurchaseOrderNumber;
   },
  setProListRMANum() {
    self.proListRMANum = !self.proListRMANum;
  },
  setProListReturnReasonCode() {
    self.proListReturnReasonCode = !self.proListReturnReasonCode;
  },
  setProListCreatedBy() {
    self.proListCreatedBy = !self.proListCreatedBy;
  },
  setProListCreatedDate() {
    self.proListCreatedDate = !self.proListCreatedDate;
  },
  setProListStatus() {
    self.proListStatus = !self.proListStatus;
  },
  setProSummaryGeneralRMANum() {
    self.proSummaryGeneralRMANum = !self.proSummaryGeneralRMANum;
  },
  setProSummaryGeneralReturnReasonCode() {
    self.proSummaryGeneralReturnReasonCode = !self.proSummaryGeneralReturnReasonCode;
  },
  setProSummaryGeneralDealerRefNum() {
    self.proSummaryGeneralDealerRefNum = !self.proSummaryGeneralDealerRefNum;
  },
  setProSummaryGeneralRequisition() {
    self.proSummaryGeneralRequisition = !self.proSummaryGeneralRequisition;
  },
  setProSummaryGeneralReturnOrderNum() {
    self.proSummaryGeneralReturnOrderNum = !self.proSummaryGeneralReturnOrderNum;
  },
  setProSummaryLinesPartNum() {
    self.proSummaryLinesPartNum = !self.proSummaryLinesPartNum;
  },
  setProSummaryLinesPartName() {
    self.proSummaryLinesPartName = !self.proSummaryLinesPartName;
  },
  setProSummaryLinesDeviceRef() {
    self.proSummaryLinesDeviceRef = !self.proSummaryLinesDeviceRef;
  },
  setProSummaryLinesStatus() {
    self.proSummaryLinesStatus = !self.proSummaryLinesStatus;
  },
  setProSummaryLinesQty() {
    self.proSummaryLinesQty = !self.proSummaryLinesQty;
  },
  setProSummaryLinesUnitPrice() {
    self.proSummaryLinesUnitPrice = !self.proSummaryLinesUnitPrice;
  },
  setProSummaryLinesDiscount() {
    self.proSummaryLinesDiscount = !self.proSummaryLinesDiscount;
  },
  setProSummaryLinesDiscountPct() {
    self.proSummaryLinesDiscountPct = !self.proSummaryLinesDiscountPct;
  },
  setProSummaryLinesTotalPrice() {
    self.proSummaryLinesTotalPrice = !self.proSummaryLinesTotalPrice;
  },
  setcListWebCaseId() {
    self.cListWebCaseId = !self.cListWebCaseId;
  },
  setcListCaseId() {
    self.cListCaseId = !self.cListCaseId;
  },
  setcListDescription() {
    self.cListDescription = !self.cListDescription;
  },
  setcListCreatedBy() {
    self.cListCreatedBy = !self.cListCreatedBy;
  },
  setcListCreadtedDate() {
    self.cListCreadtedDate = !self.cListCreadtedDate;
  },
  setcListStatus() {
    self.cListStatus = !self.cListStatus;
  },
  setCiGeneralCategory() {
    self.ciGeneralCategory = !self.ciGeneralCategory;
  },
  setCiGeneralStatus() {
    self.ciGeneralStatus = !self.ciGeneralStatus;
  },
  setCiGeneralCreatedDate() {
    self.ciGeneralCreatedDate = !self.ciGeneralCreatedDate;
  },
  setCiGeneralPriority() {
    self.ciGeneralPriority = !self.ciGeneralPriority;
  },
  setCiGeneralNotes() {
    self.ciGeneralNotes = !self.ciGeneralNotes;
  },
  setCiGeneralDescription() {
    self.ciGeneralDescription = !self.ciGeneralDescription;
  },
  setCiGeneralCreatedBy() {
    self.ciGeneralCreatedBy = !self.ciGeneralCreatedBy;
  },
  setCiGeneralDepartment() {
    self.ciGeneralDepartment = !self.ciGeneralDepartment;
  },
  setCiGeneralCaseResolution() {
    self.ciGeneralCaseResolution = !self.ciGeneralCaseResolution;
  },
  setCiGeneralCaseId() {
    self.ciGeneralCaseId = !self.ciGeneralCaseId;
  },

  setCiGeneralCaseProcess() {
    self.ciGeneralCaseProcess = !self.ciGeneralCaseProcess;
  },

  setCiGeneralParentCase() {
    self.ciGeneralParentCase = !self.ciGeneralParentCase;
  },

  setCiCaseLogCreatedDate() {
    self.ciCaseLogCreatedDate = !self.ciCaseLogCreatedDate;
  },
  setCiCaseLogDescription() {
    self.ciCaseLogDescription = !self.ciCaseLogDescription;
  },
  setCiCaseLogCreatedBy() {
    self.ciCaseLogCreatedBy = !self.ciCaseLogCreatedBy;
  },
  setCiCaseLogNotes() {
    self.ciCaseLogNotes = !self.ciCaseLogNotes;
  },

  setCcGeneralCategoryType() {
    self.ccGeneralCategoryType = !self.ccGeneralCategoryType;
  },
  setCcGeneralCaseCategory() {
    self.ccGeneralCaseCategory = !self.ccGeneralCaseCategory;
  },
  setCcGeneralParentCase() {
    self.ccGeneralParentCase = !self.ccGeneralParentCase;
  },
  setCcGeneralPriority() {
    self.ccGeneralPriority = !self.ccGeneralPriority;
  },
  setCcGeneralDescription() {
    self.ccGeneralDescription = !self.ccGeneralDescription;
  },
  setCcContactName() {
    self.ccContactName = !self.ccContactName;
  },
  setCcContactEmail() {
    self.ccContactEmail = !self.ccContactEmail;
  },
  setCcContactPhoneNumber() {
    self.ccContactPhoneNumber = !self.ccContactPhoneNumber;
  },
  setCcNotes() {
    self.ccNotes = !self.ccNotes;
  },
  setCcCaseLogDescription() {
    self.ccCaseLogDescription = !self.ccCaseLogDescription;
  },
  setCcCaseLogNotes() {
    self.ccCaseLogNotes = !self.ccCaseLogNotes;
  },
  setCcAttachments() {
    self.ccAttachments = !self.ccAttachments;
  },

  setDolDeliverRemainder() {
    self.dolDeliverRemainder = !self.dolDeliverRemainder;
  },
  setDolAccessoriesPartsImage() {
    self.dolAccessoriesPartsImage = !self.dolAccessoriesPartsImage;
  },
  setPartsLineDeliverRemainder() {
    self.partsLineDeliverRemainder = !self.partsLineDeliverRemainder;
  },
  setPartsLineProductCatalog() {
    self.partsLineProductCatalog = !self.partsLineProductCatalog;
  },
  setPartsLineImportLines() {
    self.partsLineImportLines = !self.partsLineImportLines;
  },
  setPartsLineAddAttachments() {
    self.partsLineAddAttachments = !self.partsLineAddAttachments;
  },
  setPiDetailsLinesSize() {
    self.piDetailsLinesSize = !self.piDetailsLinesSize;
  },
  setPiDetailsLinesColor() {
    self.piDetailsLinesColor = !self.piDetailsLinesColor;
  },
  setPiDetailsLinesConfig() {
    self.piDetailsLinesConfig = !self.piDetailsLinesConfig;
  },
  setPiDetailsLinesStyle() {
    self.piDetailsLinesStyle = !self.piDetailsLinesStyle;
  },
  setProSummaryLinesSize() {
    self.proSummaryLinesSize = !self.proSummaryLinesSize;
  },
  setProSummaryLinesColor() {
    self.proSummaryLinesColor = !self.proSummaryLinesColor;
  },
  setProSummaryLinesConfig() {
    self.proSummaryLinesConfig = !self.proSummaryLinesConfig;
  },
  setProSummaryLinesStyle() {
    self.proSummaryLinesStyle = !self.proSummaryLinesStyle;
  },
  setPqListWebQuotationId() {
    self.pqListWebQuotationId = !self.pqListWebQuotationId;
  },
  setPqListQuotationNumber() {
    self.pqListQuotationNumber = !self.pqListQuotationNumber;
  },
  setPqListDealerReferenceNumber() {
    self.pqListDealerReferenceNumber = !self.pqListDealerReferenceNumber;
  },
  setPqListOrderDate() {
    self.pqListOrderDate = !self.pqListOrderDate;
  },
  setPqListSalesOrderNumber() {
    self.pqListSalesOrderNumber = !self.pqListSalesOrderNumber;
  },
  setPqListCreatedBy() {
    self.pqListCreatedBy = !self.pqListCreatedBy;
  },
  setPqListOrderType() {
    self.pqListOrderType = !self.pqListOrderType;
  },
  setPqListStatus() {
    self.pqListStatus = !self.pqListStatus;
  },
  setPqListTotalAmount() {
    self.pqListTotalAmount = !self.pqListTotalAmount;
  },
  setPqDetailsDealerRequisition() {
    self.pqDetailsDealerRequisition = !self.pqDetailsDealerRequisition;
  },
  setPqDetailsDealerReferenceNumber() {
    self.pqDetailsDealerReferenceNumber = !self.pqDetailsDealerReferenceNumber;
  },
  setPqDetailsPartsQuotationContactEmail() {
    self.pqDetailsPartsQuotationContactEmail = !self.pqDetailsPartsQuotationContactEmail;
  },
  setPqDetailsPartsQuotationContactName() {
    self.pqDetailsPartsQuotationContactName = !self.pqDetailsPartsQuotationContactName;
  },
  setPqDetailsPartsQuotationPhoneNumber() {
    self.pqDetailsPartsQuotationPhoneNumber = !self.pqDetailsPartsQuotationPhoneNumber;
  },
  setPqDetailsQuotationNumber() {
    self.pqDetailsQuotationNumber = !self.pqDetailsQuotationNumber;
  },
  setPqDetailsStatus() {
    self.pqDetailsStatus = !self.pqDetailsStatus;
  },
  setPqDetailsQuotationDate() {
    self.pqDetailsQuotationDate = !self.pqDetailsQuotationDate;
  },
  setPqLinesPartNumber() {
    self.pqLinesPartNumber = !self.pqLinesPartNumber;
  },
  setPqLinesPartName() {
    self.pqLinesPartName = !self.pqLinesPartName;
  },
  setPqLinesDeviceReference() {
    self.pqLinesDeviceReference = !self.pqLinesDeviceReference;
  },
  setPqLinesDealerReference() {
    self.pqLinesDealerReference = !self.pqLinesDealerReference;
  },
  setPqLinesQuantity() {
    self.pqLinesQuantity = !self.pqLinesQuantity;
  },
  setPqLinesSite() {
    self.pqLinesSite = !self.pqLinesSite;
  },
  setPqLinesWarehouse() {
    self.pqLinesWarehouse = !self.pqLinesWarehouse;
  },
  setPqLinesOnHand() {
    self.pqLinesOnHand = !self.pqLinesOnHand;
  },
  setPqLinesConfig() {
    self.pqLinesConfig = !self.pqLinesConfig;
  },
  setPqLinesSize() {
    self.pqLinesSize = !self.pqLinesSize;
  },
  setPqLinesColor() {
    self.pqLinesColor = !self.pqLinesColor;
  },
  setPqLinesStyle() {
    self.pqLinesStyle = !self.pqLinesStyle;
  },
  setPqLinesUnit() {
    self.pqLinesUnit = !self.pqLinesUnit;
  },
  setPqLinesUnitPrice() {
    self.pqLinesUnitPrice = !self.pqLinesUnitPrice;
  },
  setPqLinesDiscount() {
    self.pqLinesDiscount = !self.pqLinesDiscount;
  },
  setPqLinesDiscountPercentage() {
    self.pqLinesDiscountPercentage = !self.pqLinesDiscountPercentage;
  },
  setPqLinesTotalAmount() {
    self.pqLinesTotalAmount = !self.pqLinesTotalAmount;
  },
  setPqLinesPartsQuotationCurrency() {
    self.pqLinesPartsQuotationCurrency = !self.pqLinesPartsQuotationCurrency;
  },
  setPqLinesProductCatalog() {
    self.pqLinesProductCatalog = !self.pqLinesProductCatalog;
  },
  setPqLinesImportLines() {
    self.pqLinesImportLines = !self.pqLinesImportLines;
  },
  setPqLinesAddAttachments() {
    self.pqLinesAddAttachments = !self.pqLinesAddAttachments;
  },
  setPqLinesConfirmedShipDate() {
    self.pqLinesConfirmedShipDate = !self.pqLinesConfirmedShipDate;
  },
  setPqLinesConfirmedReceiptDate() {
    self.pqLinesConfirmedReceiptDate = !self.pqLinesConfirmedReceiptDate;
  },
  setPqLinesDeliverReminder() {
    self.pqLinesDeliverReminder = !self.pqLinesDeliverReminder;
  },
  setPqCreateQuotation() {
    self.pqCreateQuotation = !self.pqCreateQuotation;
  },
  setDqListWebQuotationId() {
    self.dqListWebQuotationId = !self.dqListWebQuotationId;
  },
  setDqListQuotationNum() {
    self.dqListQuotationNum = !self.dqListQuotationNum;
  },
  setDqListSalesOrderNum() {
    self.dqListSalesOrderNum = !self.dqListSalesOrderNum;
  },
  setDqListDealerRefNum() {
    self.dqListDealerRefNum = !self.dqListDealerRefNum;
  },
  setDqListDate() {
    self.dqListDate = !self.dqListDate;
  },
  setDqListCreatedBy() {
    self.dqListCreatedBy = !self.dqListCreatedBy;
  },
  setDqListStatus() {
    self.dqListStatus = !self.dqListStatus;
  },
  setDqListTotalAmount() {
    self.dqListTotalAmount = !self.dqListTotalAmount;
  },
  setDqCreateQuotation() {
    self.dqCreateQuotation = !self.dqCreateQuotation;
  },
  setDqConfigurationCurrency() {
    self.dqConfigurationCurrency = !self.dqConfigurationCurrency;
  },
  setDqDetailsDealerReference() {
    self.dqDetailsDealerReference = !self.dqDetailsDealerReference;
  },
  setDqDetailsDealerRequisition() {
    self.dqDetailsDealerRequisition = !self.dqDetailsDealerRequisition;
  },
  setDqDetailsInvoiceAccount() {
    self.dqDetailsInvoiceAccount = !self.dqDetailsInvoiceAccount;
  },
  setDqDetailsRequestInvoice() {
    self.dqDetailsRequestInvoice = !self.dqDetailsRequestInvoice;
  },
  setDqDetailsContactName() {
    self.dqDetailsContactName = !self.dqDetailsContactName;
  },
  setDqDetailsContactEmail() {
    self.dqDetailsContactEmail = !self.dqDetailsContactEmail;
  },
  setDqDetailsContactPhoneNumber() {
    self.dqDetailsContactPhoneNumber = !self.dqDetailsContactPhoneNumber;
  },
  setDqDetailsSalesOrderNum() {
    self.dqDetailsSalesOrderNum = !self.dqDetailsSalesOrderNum;
  },
  setDqDetailsStatus() {
    self.dqDetailsStatus = !self.dqDetailsStatus;
  },
  setDqDetailsDate() {
    self.dqDetailsDate = !self.dqDetailsDate;
  },
  setDqLinesItemNumber() {
    self.dqLinesItemNumber = !self.dqLinesItemNumber;
  },
  setDqLinesDeviceName() {
    self.dqLinesDeviceName = !self.dqLinesDeviceName;
  },
  setDqLinesDeviceNumber() {
    self.dqLinesDeviceNumber = !self.dqLinesDeviceNumber;
  },
  setDqLinesConfiguration() {
    self.dqLinesConfiguration = !self.dqLinesConfiguration;
  },
  setDqLinesExterior() {
    self.dqLinesExterior = !self.dqLinesExterior;
  },
  setDqLinesInterior() {
    self.dqLinesInterior = !self.dqLinesInterior;
  },
  setDqLinesStyle() {
    self.dqLinesStyle = !self.dqLinesStyle;
  },
  setDqLinesQuantity() {
    self.dqLinesQuantity = !self.dqLinesQuantity;
  },
  setDqLinesUnit() {
    self.dqLinesUnit = !self.dqLinesUnit;
  },
  setDqLinesConfirmedShipDate() {
    self.dqLinesConfirmedShipDate = !self.dqLinesConfirmedShipDate;
  },
  setDqLinesConfirmedReceiptDate() {
    self.dqLinesConfirmedReceiptDate = !self.dqLinesConfirmedReceiptDate;
  },
  setDqLinesDeliverRemainder() {
    self.dqLinesDeliverRemainder = !self.dqLinesDeliverRemainder;
  },
  setDqConfigurationItemCode() {
    self.dqConfigurationItemCode = !self.dqConfigurationItemCode;
  },
  setDqConfigurationConfiguration() {
    self.dqConfigurationConfiguration = !self.dqConfigurationConfiguration;
  },
  setDqConfigurationExterior() {
    self.dqConfigurationExterior = !self.dqConfigurationExterior;
  },
  setDqConfigurationInterior() {
    self.dqConfigurationInterior = !self.dqConfigurationInterior;
  },
  setDqConfigurationStyle() {
    self.dqConfigurationStyle = !self.dqConfigurationStyle;
  },
  setDqConfigurationCampaign() {
    self.dqConfigurationCampaign = !self.dqConfigurationCampaign;
  },
  setDqConfigurationOrdered() {
    self.dqConfigurationOrdered = !self.dqConfigurationOrdered;
  },
  setDqConfigurationInShipping() {
    self.dqConfigurationInShipping = !self.dqConfigurationInShipping;
  },
  setDqConfigurationInStock() {
    self.dqConfigurationInStock = !self.dqConfigurationInStock;
  },
  setDqConfigurationConsignment() {
    self.dqConfigurationConsignment = !self.dqConfigurationConsignment;
  },
  setDqConfigurationRentalFleet() {
    self.dqConfigurationRentalFleet = !self.dqConfigurationRentalFleet;
  },

  createWarrantyClaimRequest(): IConfigurationWarrantyClaimRequest {
    return {
      webClaimNumLine: self.webClaimNumLine,
      claimNumLine: self.claimNumLine,
      claimNameLine: self.claimNameLine,
      deviceNumberLine: self.deviceNumberLine,
      claimTypeLine: self.claimTypeLine,
      createdDateLine: self.createdDateLine,
      createdByLine: self.createdByLine,
      claimStatusLine: self.claimStatusLine,
      approvalStatusLine: self.approvalStatusLine,
      totalClaimedAmountLine: self.totalClaimedAmountLine,
      totalApprovedAmountLine: self.totalApprovedAmountLine,
      warrantyClaimListPreWorkApproval: self.warrantyClaimListPreWorkApproval,
      warrantyClaimEmailNotification: self.warrantyClaimEmailNotification,
      warrantyClaimDownloadStatement: self.warrantyClaimDownloadStatement,
      warrantyClaimDownloadInvoice: self.warrantyClaimDownloadInvoice,

      deviceBrand: self.deviceBrand,
      deviceClass: self.deviceClass,
      deviceModel: self.deviceModel,
      deviceModelCode: self.deviceModelCode,
      deviceServiceModel: self.deviceServiceModel,
      deviceNumber: self.deviceNumber,
      deviceRegistrationNumber: self.deviceRegistrationNumber,
      deviceSerialNumber: self.deviceSerialNumber,
      deviceVINNumber: self.deviceVINNumber,
      deviceUsage: self.deviceUsage,

      claimName: self.claimName,
      claimGroup: self.claimGroup,
      causalPart: self.causalPart,
      serviceCampaignNumber: self.serviceCampaignNumber,
      returnReasonCode: self.returnReasonCode,
      externalReferenceNumber: self.externalReferenceNumber,
      claimSerialNumber: self.claimSerialNumber,
      claimSummaryContractCode: self.claimSummaryContractCode,
      claimContractCode: self.claimContractCode,
      serviceStartDate: self.serviceStartDate,
      serviceEndDate: self.serviceEndDate,
      contactName: self.contactName,
      contactEmail: self.contactEmail,
      contactPhoneNumber: self.contactPhoneNumber,
      damageSymptomCode: self.damageSymptomCode,
      damageCauseCode: self.damageCauseCode,
      damageResolutionCode: self.damageResolutionCode,
      damageCodeA: self.damageCodeA,
      damageCodeB: self.damageCodeB,
      damageCodeC: self.damageCodeC,
      detailsSymptoms: self.detailsSymptoms,
      detailsCause: self.detailsCause,
      detailsSolutions: self.detailsSolutions,
      detailsNotes: self.detailsNotes,
      detailsPreWorkApproval: self.detailsPreWorkApproval,
      operationNumber: self.operationNumber,
      laborDescription: self.laborDescription,
      laborReference: self.laborReference,
      laborStandardHours: self.laborStandardHours,
      laborActualHours: self.laborActualHours,
      laborUnitPrice: self.laborUnitPrice,
      laborTotalPrice: self.laborTotalPrice,
      laborMainOprId: self.laborMainOprId,
      partNumber: self.partNumber,
      partsDescription: self.partsDescription,
      partsReference: self.partsReference,
      partsQty: self.partsQty,
      partsUnit: self.partsUnit,
      partsUnitPrice: self.partsUnitPrice,
      partsTotalPrice: self.partsTotalPrice,
      partsLineCausalPart: self.partsLineCausalPart,
      partsPartsImage: self.partsPartsImage,
      returnablePart: self.returnablePart,
      miscCodes: self.miscCodes,
      miscCodesDescription: self.miscCodesDescription,
      miscDealerReference: self.miscDealerReference,
      miscChargesQty: self.miscChargesQty,
      miscUnitPrice: self.miscUnitPrice,
      miscTotalPrice: self.miscTotalPrice,
      currency: self.currency,
      claimNum: self.claimNum,
      claimStatus: self.claimStatus,
      approvalStatus: self.approvalStatus,
      claimSummaryClaimId: self.claimSummaryClaimId,
      claimSummaryDeviceNum: self.claimSummaryDeviceNum,
      claimSummaryVINNum: self.claimSummaryVINNum,
      claimSummaryRegistrationNum: self.claimSummaryRegistrationNum,
      claimSummarySerialNum: self.claimSummarySerialNum,
      claimSummaryClaimType: self.claimSummaryClaimType,
      claimSummaryClaimGroup: self.claimSummaryClaimGroup,
      claimSummaryUsage: self.claimSummaryUsage,
      laborApprovedQty: self.laborApprovedQty,
      partsApprovedQty: self.partsApprovedQty,
      miscApprovedQty: self.miscApprovedQty,
      claimSummaryDmgSymptomCode: self.claimSummaryDmgSymptomCode,
      claimSummaryDmgCauseCode: self.claimSummaryDmgCauseCode,
      claimSummaryDmgResolutionCode: self.claimSummaryDmgResolutionCode,
      claimSummaryDmgFailureCodeA: self.claimSummaryDmgFailureCodeA,
      claimSummaryDmgFailureCodeB: self.claimSummaryDmgFailureCodeB,
      claimSummaryDmgFailureCodeC: self.claimSummaryDmgFailureCodeC,
      claimSummaryDetailsSymptoms: self.claimSummaryDetailsSymptoms,
      claimSummaryDetailsCause: self.claimSummaryDetailsCause,
      claimSummaryDetailsSolutions: self.claimSummaryDetailsSolutions,
      claimSummaryDetailsNptes: self.claimSummaryDetailsNptes,
      claimCurrentUsageButton: self.claimCurrentUsageButton
    };
  },
  createPartsOrderRequest(): IConfigurationPartsOrderRequest {
    return {
      webOrderIdList: self.webOrderIdList,
      salesOrderNumberList: self.salesOrderNumberList,
      dealerReferenceNumberList: self.dealerReferenceNumberList,
      orderDateList: self.orderDateList,
      createdByList: self.createdByList,
      orderTypeList: self.orderTypeList,
      statusList: self.statusList,
      totalAmountList: self.totalAmountList,
      dealerRequisition: self.dealerRequisition,
      dealerReferenceNumber: self.dealerReferenceNumber,
      partsOrderPhoneNumber: self.partsOrderPhoneNumber,
      partNumberLine: self.partNumberLine,
      partNameLine: self.partNameLine,
      deviceReferenceLine: self.deviceReferenceLine,
      dealerReferenceLine: self.dealerReferenceLine,
      quantityLine: self.quantityLine,
      unitLine: self.unitLine,
      unitPriceLine: self.unitPriceLine,
      discountLine: self.discountLine,
      discountPercentageLine: self.discountPercentageLine,
      totalAmountLine: self.totalAmountLine,
      partsOrderCurrency: self.partsOrderCurrency,
      partsLineSite: self.partsLineSite,
      partsLineWarehouse: self.partsLineWarehouse,
      partsLineOnHand: self.partsLineOnHand,
      partsLineConfig: self.partsLineConfig,
      partsLineSize: self.partsLineSize,
      partsLineColor: self.partsLineColor,
      partsLineStyle: self.partsLineStyle,
      partsLineConfirmedReceiptDate: self.partsLineConfirmedReceiptDate,
      partsLineConfirmedShipDate: self.partsLineConfirmedShipDate,
      partsLinePartsImage: self.partsLinePartsImage,
      partsLineAltItemPartsImage: self.partsLineAltItemPartsImage,
      partsLineRepItemPartsImage: self.partsLineRepItemPartsImage,
      partsLineSuppItemPartsImage: self.partsLineSuppItemPartsImage,
      partsOrderContactEmail: self.partsOrderContactEmail,
      partsOrderContactName: self.partsOrderContactName,
      salesOrderNumber: self.salesOrderNumber,
      status: self.status,
      podDocStatus: self.podDocStatus,
      orderDate: self.orderDate,
      partsLineDeliverRemainder: self.partsLineDeliverRemainder,
      partsLineProductCatalog: self.partsLineProductCatalog,
      partsLineImportLines: self.partsLineImportLines,
      partsLineAddAttachments: self.partsLineAddAttachments
    };
  },

  createPartsTransactionRequest(): IConfigurationPartsTransactionRequest {
    return {
      ptListPartNumber: self.ptListPartNumber,
      ptListPartName: self.ptListPartName,
      ptListPhyiscalDate: self.ptListPhyiscalDate,
      ptListWarehouse: self.ptListWarehouse,
      ptListReference: self.ptListReference,
      ptListSalesOrderNum: self.ptListSalesOrderNum,
      ptListInvoiceNum: self.ptListInvoiceNum,
      ptListOrderLineQuantity: self.ptListOrderLineQuantity,
      ptListQty: self.ptListQty,
      ptListLineStatus: self.ptListLineStatus,
      ptListStatus: self.ptListStatus,
      ptListDeviceReference: self.ptListDeviceReference,
      ptListConfirmedShipDate: self.ptListConfirmedShipDate,
    };
  },

  createDeviceOrderRequest(): IConfigurationDeviceOrderRequest {
    return {
      deviceOrderListWebOrderId: self.deviceOrderListWebOrderId,
      deviceOrderListOrderNum: self.deviceOrderListOrderNum,
      deviceOrderListDealerRefNum: self.deviceOrderListDealerRefNum,
      deviceOrderListDate: self.deviceOrderListDate,
      deviceOrderListCreatedBy: self.deviceOrderListCreatedBy,
      deviceOrderListType: self.deviceOrderListType,
      deviceOrderListStatus: self.deviceOrderListStatus,
      deviceOrderListTotalAmount: self.deviceOrderListTotalAmount,
      dodDealerReference: self.dodDealerReference,
      dodDealerRequisition: self.dodDealerRequisition,
      dodInvoiceAccount: self.dodInvoiceAccount,
      dodRequestInvoice: self.dodRequestInvoice,
      dodContactName: self.dodContactName,
      dodContactEmail: self.dodContactEmail,
      dodContactPhoneNumber: self.dodContactPhoneNumber,
      dodSalesOrderNum: self.dodSalesOrderNum,
      dodStatus: self.dodStatus,
      dodDocStatus: self.dodDocStatus,
      dodDate: self.dodDate,
      dolItemNumber: self.dolItemNumber,
      dolDeviceName: self.dolDeviceName,
      dolDeviceNumber: self.dolDeviceNumber,
      dolConfiguration: self.dolConfiguration,
      dolExterior: self.dolExterior,
      dolInterior: self.dolInterior,
      dolStyle: self.dolStyle,
      dolQuantity: self.dolQuantity,
      dolUnit: self.dolUnit,
      dolConfirmedReceiptDate: self.dolConfirmedReceiptDate,
      dolConfirmedShipDate: self.dolConfirmedShipDate,
      docItemCode: self.docItemCode,
      docConfiguration: self.docConfiguration,
      docExterior: self.docExterior,
      docInterior: self.docInterior,
      docStyle: self.docStyle,
      docCampaign: self.docCampaign,
      docOrdered: self.docOrdered,
      docInShipping: self.docInShipping,
      docInStock: self.docInStock,
      docConsignment: self.docConsignment,
      docRentalFleet: self.docRentalFleet,
      dosCurrency: self.dosCurrency,
      dolDeliverRemainder: self.dolDeliverRemainder,
      dolAccessoriesPartsImage: self.dolAccessoriesPartsImage
    };
  },

  createDeviceInquiryRequest(): IConfigurationDeviceInquiryRequest {
    return {
      diListName: self.diListName,
      diListBrand: self.diListBrand,
      diListClass: self.diListClass,
      diListModel: self.diListModel,
      diListModelCode: self.diListModelCode,
      diListModelYear: self.diListModelYear,
      diListDeviceNum: self.diListDeviceNum,
      diListSerialNum: self.diListSerialNum,
      diListRegNum: self.diListRegNum,
      diListVINNum: self.diListVINNum,
      diListStatus: self.diListStatus,
      diGeneralDeviceNumber: self.diGeneralDeviceNumber,
      diGeneralDeviceName: self.diGeneralDeviceName,
      diGeneralMasterNum: self.diGeneralMasterNum,
      diGeneralRegNum: self.diGeneralRegNum,
      diGeneralPrivateNum: self.diGeneralPrivateNum,
      diGeneralMajorStatus: self.diGeneralMajorStatus,
      diSetupBrandNum: self.diSetupBrandNum,
      diSetupClassNum: self.diSetupClassNum,
      diSetupModelNum: self.diSetupModelNum,
      diSetupModelCodeNum: self.diSetupModelCodeNum,
      diSetupModelConfig: self.diSetupModelConfig,
      diSetupInteriorNum: self.diSetupInteriorNum,
      diSetupExteriorNum: self.diSetupExteriorNum,
      diSetupModelYear: self.diSetupModelYear,
      diSetupProductionDate: self.diSetupProductionDate,
      diSetupCountryOrigin: self.diSetupCountryOrigin,
      diSetupEngineDisplacement: self.diSetupEngineDisplacement,
      diSetupEngineHP: self.diSetupEngineHP,
      diSetupEngineType: self.diSetupEngineType,
      diSetupEngineBrand: self.diSetupEngineBrand,
      diSetupEnginekW: self.diSetupEnginekW,
      diSetupEngineModelYear: self.diSetupEngineModelYear,
      diSetupEngineCertificationNumberEPA: self.diSetupEngineCertificationNumberEPA,
      diSetupEngineEmissionFamilyNameEPA: self.diSetupEngineEmissionFamilyNameEPA,
      diSetupEngineCertificationNumberCARB: self.diSetupEngineCertificationNumberCARB,
      diSetupEngineEmissionFamilyNameCARB: self.diSetupEngineEmissionFamilyNameCARB,
      diSetupBodyStyle: self.diSetupBodyStyle,
      diSetupFacelift: self.diSetupFacelift,
      diSetupFuelType: self.diSetupFuelType,
      diSetupNumCylinder: self.diSetupNumCylinder,
      diCustodianName: self.diCustodianName,
      diCustodianOwner: self.diCustodianOwner,
      diCustodianFirstOwner: self.diCustodianFirstOwner,
      diCustodianOperator: self.diCustodianOperator,
      diCustodianCoOwner: self.diCustodianCoOwner,
      diCustodianActive: self.diCustodianActive,
      diServiceCampaignLinesNum: self.diServiceCampaignLinesNum,
      diServiceCampaignLinesDescription: self.diServiceCampaignLinesDescription,
      diServiceCampaignLinesStartDate: self.diServiceCampaignLinesStartDate,
      diServiceCampaignLinesStatus: self.diServiceCampaignLinesStatus,
      diServiceCampaignLinesEndDate: self.diServiceCampaignLinesEndDate,
      diDeviceAccessoriesSpecificationType:
        self.diDeviceAccessoriesSpecificationType,
      diDeviceAccessoriesItemNum: self.diDeviceAccessoriesItemNum,
      diDeviceAccessoriesDescription: self.diDeviceAccessoriesDescription,
      diDeviceAccessoriesComponentNum: self.diDeviceAccessoriesComponentNum,
      diDeviceAccessoriesCategory: self.diDeviceAccessoriesCategory,
      diDeviceAccessoriesQuantity: self.diDeviceAccessoriesQuantity,
      diDeviceAccessoriesStocked: self.diDeviceAccessoriesStocked,
      diDeviceAccessoriesReplaced: self.diDeviceAccessoriesReplaced,
      diDeviceAccessoriesStatus: self.diDeviceAccessoriesStatus,
      diDeviceAccessoriesTransactionDate:
        self.diDeviceAccessoriesTransactionDate,
      diDeviceAccessoriesStatusDate: self.diDeviceAccessoriesStatusDate,
      diDeviceChildSpecificationType: self.diDeviceChildSpecificationType,
      diDeviceChildItemNum: self.diDeviceChildItemNum,
      diDeviceChildDescription: self.diDeviceChildDescription,
      diDeviceChildComponentNum: self.diDeviceChildComponentNum,
      diDeviceChildCategory: self.diDeviceChildCategory,
      diDeviceChildQuantity: self.diDeviceChildQuantity,
      diDeviceChildStocked: self.diDeviceChildStocked,
      diDeviceChildReplaced: self.diDeviceChildReplaced,
      diDeviceChildStatus: self.diDeviceChildStatus,
      diDeviceChildTransactionDate: self.diDeviceChildTransactionDate,
      diDeviceChildStatusDate: self.diDeviceChildStatusDate,
      diDeviceComponentsSpecificationType:
        self.diDeviceComponentsSpecificationType,
      diDeviceComponentsItemNum: self.diDeviceComponentsItemNum,
      diDeviceComponentsDescription: self.diDeviceComponentsDescription,
      diDeviceComponentsNum: self.diDeviceComponentsNum,
      diDeviceComponentsCategory: self.diDeviceComponentsCategory,
      diDeviceComponentsQuantity: self.diDeviceComponentsQuantity,
      diDeviceComponentsStocked: self.diDeviceComponentsStocked,
      diDeviceComponentsReplaced: self.diDeviceComponentsReplaced,
      diDeviceComponentsStatus: self.diDeviceComponentsStatus,
      diDeviceComponentsTranscationDate: self.diDeviceComponentsTranscationDate,
      diDeviceComponentsStatusDate: self.diDeviceComponentsStatusDate,
      diOptionsSpecificationType: self.diOptionsSpecificationType,
      diOptionsItemNum: self.diOptionsItemNum,
      diOptionsDescription: self.diOptionsDescription,
      diOptionsComponentNum: self.diOptionsComponentNum,
      diOptionsCategory: self.diOptionsCategory,
      diOptionsQuantity: self.diOptionsQuantity,
      diOptionsStocked: self.diOptionsStocked,
      diOptionsReplaced: self.diOptionsReplaced,
      diOptionsStatus: self.diOptionsStatus,
      diOptionsTransactionDate: self.diOptionsTransactionDate,
      diOptionsStatusDate: self.diOptionsStatusDate,
      diDevicePropertiesDeviceName: self.diDevicePropertiesDeviceName,
      diDevicePropertiesDeviceNum: self.diDevicePropertiesDeviceNum,
      diDevicePropertiesPropertyGroup: self.diDevicePropertiesPropertyGroup,
      diDevicePropertiesPropertyGroupCode:
        self.diDevicePropertiesPropertyGroupCode,
      diDevicePropertiesNotes: self.diDevicePropertiesNotes,
      diWarrantyType: self.diWarrantyType,
      diWarrantyDescription: self.diWarrantyDescription,
      diWarrantStartDate: self.diWarrantStartDate,
      diWarrantyEndDate: self.diWarrantyEndDate,
      diWarrantyStartUsage: self.diWarrantyStartUsage,
      diWarrantyEndUsage: self.diWarrantyEndUsage,
      diWorkOrderHistNum: self.diWorkOrderHistNum,
      diWorkOrderHistDescription: self.diWorkOrderHistDescription,
      diWorkOrderHistCustomerAccount: self.diWorkOrderHistCustomerAccount,
      diWorkOrderHistStatus: self.diWorkOrderHistStatus,
      diWorkOrderHistWorkOrderGrp: self.diWorkOrderHistWorkOrderGrp,
      diWorkOrderHistType: self.diWorkOrderHistType,
      diWorkOrderHistStartDate: self.diWorkOrderHistStartDate,
      diWorkOrderHistCreatedDate: self.diWorkOrderHistCreatedDate,
      diWorkOrderHistEstimatedHours: self.diWorkOrderHistEstimatedHours,
      diWorkOrderHistHoursRemaining: self.diWorkOrderHistHoursRemaining,
      diClaimHistWarrantyClaim: self.diClaimHistWarrantyClaim,
      diClaimHistStatus: self.diClaimHistStatus,
      diClaimHistApprovalStatus: self.diClaimHistApprovalStatus,
      diClaimHistInvoiceStatus: self.diClaimHistInvoiceStatus,
      diClaimHistWarrantyClaimGrp: self.diClaimHistWarrantyClaimGrp,
      diClaimHistType: self.diClaimHistType,
      diClaimHistDevice: self.diClaimHistDevice,
      diClaimHistPreWorkApproval: self.diClaimHistPreWorkApproval,
      diClaimHistUsage: self.diClaimHistUsage,
      diServicePlanNum: self.diServicePlanNum,
      diServicePlanName: self.diServicePlanName,
      diServicePlanStatus: self.diServicePlanStatus,
      diServicePlanActivation: self.diServicePlanActivation,
      diServicePlanReferenceDate: self.diServicePlanReferenceDate,
      diServicePlanContractNum: self.diServicePlanContractNum,
      diServicePlanGrp: self.diServicePlanGrp,
      diScheduledServiceDueDate: self.diScheduledServiceDueDate,
      diScheduledServiceUsage: self.diScheduledServiceUsage,
      diScheduledServiceUsageUnit: self.diScheduledServiceUsageUnit,
      diScheduledServiceUsageDueDate: self.diScheduledServiceUsageDueDate,
      diScheduledServiceName: self.diScheduledServiceName,
      diScheduledServiceServicePlanGrp: self.diScheduledServiceServicePlanGrp,
      diScheduledServiceStatus: self.diScheduledServiceStatus,
      diScheduledServiceStopped: self.diScheduledServiceStopped,
      diScheduledServiceWorkOrderNum: self.diScheduledServiceWorkOrderNum,
      diScheduledServiceWorkOrderStatus: self.diScheduledServiceWorkOrderStatus,
      diScheduledServiceClosedDate: self.diScheduledServiceClosedDate,
      diDocumentsName: self.diDocumentsName,
      diDocumentsType: self.diDocumentsType,
      diDocumentsNotes: self.diDocumentsNotes,
      diServiceContractContractId: self.diServiceContractContractId,
      diServiceContractDeviceId: self.diServiceContractDeviceId,
      diServiceContractDeviceGroupId: self.diServiceContractDeviceGroupId,
      diServiceContractInvoiceContractId: self.diServiceContractInvoiceContractId,
      diServiceContractContractType: self.diServiceContractContractType,
      diServiceContractCollectionStatus: self.diServiceContractCollectionStatus,
      diServiceContractCustAccount: self.diServiceContractCustAccount,
      diServiceContractName: self.diServiceContractName
    };
  },

  createDeliveryReportRequest(): IConfigurationDeliveryReportRequest {
    return {
      drListWebId: self.drListWebId,
      drListCreatedBy: self.drListCreatedBy,
      drListCreatedDate: self.drListCreatedDate,
      drListStatus: self.drListStatus,
      drInfoHeaderWebId: self.drInfoHeaderWebId,
      drInfoHeaderReportNum: self.drInfoHeaderReportNum,
      drInfoHeaderCreatedBy: self.drInfoHeaderCreatedBy,
      drInfoHeaderCreatedDate: self.drInfoHeaderCreatedDate,
      drInfoHeaderStatus: self.drInfoHeaderStatus,
      drInfoLinesDeviceInfoVinNum: self.drInfoLinesDeviceInfoVinNum,
      drInfoLinesDeviceInfoDeviceNum: self.drInfoLinesDeviceInfoDeviceNum,
      drInfoLinesDeviceInfoSerialNum: self.drInfoLinesDeviceInfoSerialNum,
      drInfoLinesGeneralUsage: self.drInfoLinesGeneralUsage,
      drInfoLinesGeneralUsageGrp: self.drInfoLinesGeneralUsageGrp,
      drInfoLinesGeneralMjrStatus: self.drInfoLinesGeneralMjrStatus,
      drInfoLinesGeneralFinalSalesDate: self.drInfoLinesGeneralFinalSalesDate,
      drInfoLinesGeneralRegDate: self.drInfoLinesGeneralRegDate,
      drInfoLinesGeneralRefDate: self.drInfoLinesGeneralRefDate,
      drInfoLinesGeneralWarrantyType: self.drInfoLinesGeneralWarrantyType,
      drInfoLinesParentDeviceInfoVinNum: self.drInfoLinesParentDeviceInfoVinNum,
      drInfoLinesParentDeviceInfoParentDeviceNum:
        self.drInfoLinesParentDeviceInfoParentDeviceNum,
      drInfoLinesParentDeviceInfoSerialNum:
        self.drInfoLinesParentDeviceInfoSerialNum,
      drInfoLinesParentDeviceInfoUpdateParentDeviceCustodian:
        self.drInfoLinesParentDeviceInfoUpdateParentDeviceCustodian,
      drInfoLinesCustodianName: self.drInfoLinesCustodianName,
      drInfoLinesCustodianEmail: self.drInfoLinesCustodianEmail,
      drInfoLinesCustodianPhoneNum: self.drInfoLinesCustodianPhoneNum,
      drInfoLinesCustodianAddress: self.drInfoLinesCustodianAddress,
      drCreateLineVinNum: self.drCreateLineVinNum,
      drCreateLineDeviceNum: self.drCreateLineDeviceNum,
      drCreateLineSerialNum: self.drCreateLineSerialNum,
      drCreateGeneralUsage: self.drCreateGeneralUsage,
      drCreateGeneralUsageGrp: self.drCreateGeneralUsageGrp,
      drCreateGeneralMjrStatus: self.drCreateGeneralMjrStatus,
      drCreateGeneralFinalSalesDate: self.drCreateGeneralFinalSalesDate,
      drCreateGeneralRegDate: self.drCreateGeneralRegDate,
      drCreateGeneralRefDate: self.drCreateGeneralRefDate,
      drCreateGeneralWarrantyType: self.drCreateGeneralWarrantyType,
      drCreateGeneralRegNum: self.drCreateGeneralRegNum,
      drCreateParentDeviceVinNum: self.drCreateParentDeviceVinNum,
      drCreateParentDeviceNum: self.drCreateParentDeviceNum,
      drCreateParentDeviceSerialNum: self.drCreateParentDeviceSerialNum,
      drCreateParentDeviceUpdateParentDeviceCustodian:
        self.drCreateParentDeviceUpdateParentDeviceCustodian,
      drCreateCustodianName: self.drCreateCustodianName,
      drCreateCustodianEmail: self.drCreateCustodianEmail,
      drCreateCustodianPhoneNum: self.drCreateCustodianPhoneNum,
      drCreateCustodianAddress: self.drCreateCustodianAddress,
    };
  },

  createAllDevicesRequest(): IConfigurationAllDevicesRequest {
    return {
      daListDevicNumber: self.daListDevicNumber,
      daListDeviceName: self.daListDeviceName,
      daListItemNumber: self.daListItemNumber,
      daListModelCodeNumber: self.daListModelCodeNumber,
      daListConfigName: self.daListConfigName,
      daListModelYear: self.daListModelYear,
      daListRegistrationNumber: self.daListRegistrationNumber,
      daListChassislNum: self.daListChassislNum,
      daListMajorStatus: self.daListMajorStatus,
      daListSalesPrice: self.daListSalesPrice,
      daListCampaignPrice: self.daListCampaignPrice,
      daGeneralDeviceNumber: self.daGeneralDeviceNumber,
      daGeneralDeviceName: self.daGeneralDeviceName,
      daGeneralMasterNum: self.daGeneralMasterNum,
      daGeneralRegNum: self.daGeneralRegNum,
      daGeneralPrivateNum: self.daGeneralPrivateNum,
      daGeneralMajorStatus: self.daGeneralMajorStatus,
      daSetupBrandNum: self.daSetupBrandNum,
      daSetupClassNum: self.daSetupClassNum,
      daSetupModelNum: self.daSetupModelNum,
      daSetupModelCodeNum: self.daSetupModelCodeNum,
      daSetupModelConfig: self.daSetupModelConfig,
      daSetupInteriorNum: self.daSetupInteriorNum,
      daSetupExteriorNum: self.daSetupExteriorNum,
      daSetupModelYear: self.daSetupModelYear,
      daSetupProductionDate: self.daSetupProductionDate,
      daSetupCountryOrigin: self.daSetupCountryOrigin,
      daSetupEngineDisplacement: self.daSetupEngineDisplacement,
      daSetupEngineHP: self.daSetupEngineHP,
      daSetupEngineType: self.daSetupEngineType,
      daSetupEngineBrand: self.daSetupEngineBrand,
      daSetupEnginekW: self.daSetupEnginekW,
      daSetupEngineModelYear: self.daSetupEngineModelYear,
      daSetupEngineCertificationNumberEPA: self.daSetupEngineCertificationNumberEPA,
      daSetupEngineEmissionFamilyNameEPA: self.daSetupEngineEmissionFamilyNameEPA,
      daSetupEngineCertificationNumberCARB: self.daSetupEngineCertificationNumberCARB,
      daSetupEngineEmissionFamilyNameCARB: self.daSetupEngineEmissionFamilyNameCARB,
      daSetupBodyStyle: self.daSetupBodyStyle,
      daSetupFacelift: self.daSetupFacelift,
      daSetupFuelType: self.daSetupFuelType,
      daSetupNumCylinder: self.daSetupNumCylinder,
      daCustodianName: self.daCustodianName,
      daCustodianOwner: self.daCustodianOwner,
      daCustodianFirstOwner: self.daCustodianFirstOwner,
      daCustodianOperator: self.daCustodianOperator,
      daCustodianCoOwner: self.daCustodianCoOwner,
      daCustodianActive: self.daCustodianActive,
      daServiceCampaignLinesNum: self.daServiceCampaignLinesNum,
      daServiceCampaignLinesDescription: self.daServiceCampaignLinesDescription,
      daServiceCampaignLinesStartDate: self.daServiceCampaignLinesStartDate,
      daServiceCampaignLinesStatus: self.daServiceCampaignLinesStatus,
      daServiceCampaignLinesEndDate: self.daServiceCampaignLinesEndDate,
      daDeviceAccessoriesSpecificationType:
        self.daDeviceAccessoriesSpecificationType,
      daDeviceAccessoriesItemNum: self.daDeviceAccessoriesItemNum,
      daDeviceAccessoriesDescription: self.daDeviceAccessoriesDescription,
      daDeviceAccessoriesComponentNum: self.daDeviceAccessoriesComponentNum,
      daDeviceAccessoriesCategory: self.daDeviceAccessoriesCategory,
      daDeviceAccessoriesQuantity: self.daDeviceAccessoriesQuantity,
      daDeviceAccessoriesStocked: self.daDeviceAccessoriesStocked,
      daDeviceAccessoriesReplaced: self.daDeviceAccessoriesReplaced,
      daDeviceAccessoriesStatus: self.daDeviceAccessoriesStatus,
      daDeviceAccessoriesTransactionDate:
        self.daDeviceAccessoriesTransactionDate,
      daDeviceAccessoriesStatusDate: self.daDeviceAccessoriesStatusDate,
      daDeviceChildSpecificationType: self.daDeviceChildSpecificationType,
      daDeviceChildItemNum: self.daDeviceChildItemNum,
      daDeviceChildDescription: self.daDeviceChildDescription,
      daDeviceChildComponentNum: self.daDeviceChildComponentNum,
      daDeviceChildCategory: self.daDeviceChildCategory,
      daDeviceChildQuantity: self.daDeviceChildQuantity,
      daDeviceChildStocked: self.daDeviceChildStocked,
      daDeviceChildReplaced: self.daDeviceChildReplaced,
      daDeviceChildStatus: self.daDeviceChildStatus,
      daDeviceChildTransactionDate: self.daDeviceChildTransactionDate,
      daDeviceChildStatusDate: self.daDeviceChildStatusDate,
      daDeviceComponentsSpecificationType:
        self.daDeviceComponentsSpecificationType,
      daDeviceComponentsItemNum: self.daDeviceComponentsItemNum,
      daDeviceComponentsDescription: self.daDeviceComponentsDescription,
      daDeviceComponentsNum: self.daDeviceComponentsNum,
      daDeviceComponentsCategory: self.daDeviceComponentsCategory,
      daDeviceComponentsQuantity: self.daDeviceComponentsQuantity,
      daDeviceComponentsStocked: self.daDeviceComponentsStocked,
      daDeviceComponentsReplaced: self.daDeviceComponentsReplaced,
      daDeviceComponentsStatus: self.daDeviceComponentsStatus,
      daDeviceComponentsTranscationDate: self.daDeviceComponentsTranscationDate,
      daDeviceComponentsStatusDate: self.daDeviceComponentsStatusDate,
      daOptionsSpecificationType: self.daOptionsSpecificationType,
      daOptionsItemNum: self.daOptionsItemNum,
      daOptionsDescription: self.daOptionsDescription,
      daOptionsComponentNum: self.daOptionsComponentNum,
      daOptionsCategory: self.daOptionsCategory,
      daOptionsQuantity: self.daOptionsQuantity,
      daOptionsStocked: self.daOptionsStocked,
      daOptionsReplaced: self.daOptionsReplaced,
      daOptionsStatus: self.daOptionsStatus,
      daOptionsTransactionDate: self.daOptionsTransactionDate,
      daOptionsStatusDate: self.daOptionsStatusDate,
      daDevicePropertiesDeviceName: self.daDevicePropertiesDeviceName,
      daDevicePropertiesDeviceNum: self.daDevicePropertiesDeviceNum,
      daDevicePropertiesPropertyGroup: self.daDevicePropertiesPropertyGroup,
      daDevicePropertiesPropertyGroupCode:
        self.daDevicePropertiesPropertyGroupCode,
      daDevicePropertiesNotes: self.daDevicePropertiesNotes,
      daWarrantyType: self.daWarrantyType,
      daWarrantyDescription: self.daWarrantyDescription,
      daWarrantStartDate: self.daWarrantStartDate,
      daWarrantyEndDate: self.daWarrantyEndDate,
      daWarrantyStartUsage: self.daWarrantyStartUsage,
      daWarrantyEndUsage: self.daWarrantyEndUsage,
      daWorkOrderHistNum: self.daWorkOrderHistNum,
      daWorkOrderHistDescription: self.daWorkOrderHistDescription,
      daWorkOrderHistCustomerAccount: self.daWorkOrderHistCustomerAccount,
      daWorkOrderHistStatus: self.daWorkOrderHistStatus,
      daWorkOrderHistWorkOrderGrp: self.daWorkOrderHistWorkOrderGrp,
      daWorkOrderHistType: self.daWorkOrderHistType,
      daWorkOrderHistStartDate: self.daWorkOrderHistStartDate,
      daWorkOrderHistCreatedDate: self.daWorkOrderHistCreatedDate,
      daWorkOrderHistEstimatedHours: self.daWorkOrderHistEstimatedHours,
      daWorkOrderHistHoursRemaining: self.daWorkOrderHistHoursRemaining,
      daClaimHistWarrantyClaim: self.daClaimHistWarrantyClaim,
      daClaimHistStatus: self.daClaimHistStatus,
      daClaimHistApprovalStatus: self.daClaimHistApprovalStatus,
      daClaimHistInvoiceStatus: self.daClaimHistInvoiceStatus,
      daClaimHistWarrantyClaimGrp: self.daClaimHistWarrantyClaimGrp,
      daClaimHistType: self.daClaimHistType,
      daClaimHistDevice: self.daClaimHistDevice,
      daClaimHistPreWorkApproval: self.daClaimHistPreWorkApproval,
      daClaimHistUsage: self.daClaimHistUsage,
      daServicePlanNum: self.daServicePlanNum,
      daServicePlanName: self.daServicePlanName,
      daServicePlanStatus: self.daServicePlanStatus,
      daServicePlanActivation: self.daServicePlanActivation,
      daServicePlanReferenceDate: self.daServicePlanReferenceDate,
      daServicePlanContractNum: self.daServicePlanContractNum,
      daServicePlanGrp: self.daServicePlanGrp,
      daScheduledServiceDueDate: self.daScheduledServiceDueDate,
      daScheduledServiceUsage: self.daScheduledServiceUsage,
      daScheduledServiceUsageUnit: self.daScheduledServiceUsageUnit,
      daScheduledServiceUsageDueDate: self.daScheduledServiceUsageDueDate,
      daScheduledServiceName: self.daScheduledServiceName,
      daScheduledServiceServicePlanGrp: self.daScheduledServiceServicePlanGrp,
      daScheduledServiceStatus: self.daScheduledServiceStatus,
      daScheduledServiceStopped: self.daScheduledServiceStopped,
      daScheduledServiceWorkOrderNum: self.daScheduledServiceWorkOrderNum,
      daScheduledServiceWorkOrderStatus: self.daScheduledServiceWorkOrderStatus,
      daScheduledServiceClosedDate: self.daScheduledServiceClosedDate,
      daDocumentsName: self.daDocumentsName,
      daDocumentsType: self.daDocumentsType,
      daDocumentsNotes: self.daDocumentsNotes,
      daServiceContractContractId: self.daServiceContractContractId,
      daServiceContractDeviceId: self.daServiceContractDeviceId,
      daServiceContractDeviceGroupId: self.daServiceContractDeviceGroupId,
      daServiceContractInvoiceContractId: self.daServiceContractInvoiceContractId,
      daServiceContractContractType: self.daServiceContractContractType,
      daServiceContractCollectionStatus: self.daServiceContractCollectionStatus,
      daServiceContractCustAccount: self.daServiceContractCustAccount,
      daServiceContractName: self.daServiceContractName
    };
  },

  createDealerDevicesRequest(): IConfigurationDealerDevicesRequest {
    return {
      ddListDevicNumber: self.ddListDevicNumber,
      ddListDeviceName: self.ddListDeviceName,
      ddListItemNumber: self.ddListItemNumber,
      ddListModelCodeNumber: self.ddListModelCodeNumber,
      ddListConfigName: self.ddListConfigName,
      ddListModelYear: self.ddListModelYear,
      ddListRegistrationNumber: self.ddListRegistrationNumber,
      ddListChassislNum: self.ddListChassislNum,
      ddListMajorStatus: self.ddListMajorStatus,
      ddListSalesPrice: self.ddListSalesPrice,
      ddListCampaignPrice: self.ddListCampaignPrice,
      ddGeneralDeviceNumber: self.ddGeneralDeviceNumber,
      ddGeneralDeviceName: self.ddGeneralDeviceName,
      ddGeneralMasterNum: self.ddGeneralMasterNum,
      ddGeneralRegNum: self.ddGeneralRegNum,
      ddGeneralPrivateNum: self.ddGeneralPrivateNum,
      ddGeneralMajorStatus: self.ddGeneralMajorStatus,
      ddSetupBrandNum: self.ddSetupBrandNum,
      ddSetupClassNum: self.ddSetupClassNum,
      ddSetupModelNum: self.ddSetupModelNum,
      ddSetupModelCodeNum: self.ddSetupModelCodeNum,
      ddSetupModelConfig: self.ddSetupModelConfig,
      ddSetupInteriorNum: self.ddSetupInteriorNum,
      ddSetupExteriorNum: self.ddSetupExteriorNum,
      ddSetupModelYear: self.ddSetupModelYear,
      ddSetupProductionDate: self.ddSetupProductionDate,
      ddSetupCountryOrigin: self.ddSetupCountryOrigin,
      ddSetupEngineDisplacement: self.ddSetupEngineDisplacement,
      ddSetupEngineHP: self.ddSetupEngineHP,
      ddSetupEngineType: self.ddSetupEngineType,
      ddSetupEngineBrand: self.ddSetupEngineBrand,
      ddSetupEnginekW: self.ddSetupEnginekW,
      ddSetupEngineModelYear: self.ddSetupEngineModelYear,
      ddSetupEngineCertificationNumberEPA: self.ddSetupEngineCertificationNumberEPA,
      ddSetupEngineEmissionFamilyNameEPA: self.ddSetupEngineEmissionFamilyNameEPA,
      ddSetupEngineCertificationNumberCARB: self.ddSetupEngineCertificationNumberCARB,
      ddSetupEngineEmissionFamilyNameCARB: self.ddSetupEngineEmissionFamilyNameCARB,
      ddSetupBodyStyle: self.ddSetupBodyStyle,
      ddSetupFacelift: self.ddSetupFacelift,
      ddSetupFuelType: self.ddSetupFuelType,
      ddSetupNumCylinder: self.ddSetupNumCylinder,
      ddCustodianName: self.ddCustodianName,
      ddCustodianOwner: self.ddCustodianOwner,
      ddCustodianFirstOwner: self.ddCustodianFirstOwner,
      ddCustodianOperator: self.ddCustodianOperator,
      ddCustodianCoOwner: self.ddCustodianCoOwner,
      ddCustodianActive: self.ddCustodianActive,
      ddServiceCampaignLinesNum: self.ddServiceCampaignLinesNum,
      ddServiceCampaignLinesDescription: self.ddServiceCampaignLinesDescription,
      ddServiceCampaignLinesStartDate: self.ddServiceCampaignLinesStartDate,
      ddServiceCampaignLinesStatus: self.ddServiceCampaignLinesStatus,
      ddServiceCampaignLinesEndDate: self.ddServiceCampaignLinesEndDate,
      ddDeviceAccessoriesSpecificationType:
        self.ddDeviceAccessoriesSpecificationType,
      ddDeviceAccessoriesItemNum: self.ddDeviceAccessoriesItemNum,
      ddDeviceAccessoriesDescription: self.ddDeviceAccessoriesDescription,
      ddDeviceAccessoriesComponentNum: self.ddDeviceAccessoriesComponentNum,
      ddDeviceAccessoriesCategory: self.ddDeviceAccessoriesCategory,
      ddDeviceAccessoriesQuantity: self.ddDeviceAccessoriesQuantity,
      ddDeviceAccessoriesStocked: self.ddDeviceAccessoriesStocked,
      ddDeviceAccessoriesReplaced: self.ddDeviceAccessoriesReplaced,
      ddDeviceAccessoriesStatus: self.ddDeviceAccessoriesStatus,
      ddDeviceAccessoriesTransactionDate:
        self.ddDeviceAccessoriesTransactionDate,
      ddDeviceAccessoriesStatusDate: self.ddDeviceAccessoriesStatusDate,
      ddDeviceChildSpecificationType: self.ddDeviceChildSpecificationType,
      ddDeviceChildItemNum: self.ddDeviceChildItemNum,
      ddDeviceChildDescription: self.ddDeviceChildDescription,
      ddDeviceChildComponentNum: self.ddDeviceChildComponentNum,
      ddDeviceChildCategory: self.ddDeviceChildCategory,
      ddDeviceChildQuantity: self.ddDeviceChildQuantity,
      ddDeviceChildStocked: self.ddDeviceChildStocked,
      ddDeviceChildReplaced: self.ddDeviceChildReplaced,
      ddDeviceChildStatus: self.ddDeviceChildStatus,
      ddDeviceChildTransactionDate: self.ddDeviceChildTransactionDate,
      ddDeviceChildStatusDate: self.ddDeviceChildStatusDate,
      ddDeviceComponentsSpecificationType:
        self.ddDeviceComponentsSpecificationType,
      ddDeviceComponentsItemNum: self.ddDeviceComponentsItemNum,
      ddDeviceComponentsDescription: self.ddDeviceComponentsDescription,
      ddDeviceComponentsNum: self.ddDeviceComponentsNum,
      ddDeviceComponentsCategory: self.ddDeviceComponentsCategory,
      ddDeviceComponentsQuantity: self.ddDeviceComponentsQuantity,
      ddDeviceComponentsStocked: self.ddDeviceComponentsStocked,
      ddDeviceComponentsReplaced: self.ddDeviceComponentsReplaced,
      ddDeviceComponentsStatus: self.ddDeviceComponentsStatus,
      ddDeviceComponentsTranscationDate: self.ddDeviceComponentsTranscationDate,
      ddDeviceComponentsStatusDate: self.ddDeviceComponentsStatusDate,
      ddOptionsSpecificationType: self.ddOptionsSpecificationType,
      ddOptionsItemNum: self.ddOptionsItemNum,
      ddOptionsDescription: self.ddOptionsDescription,
      ddOptionsComponentNum: self.ddOptionsComponentNum,
      ddOptionsCategory: self.ddOptionsCategory,
      ddOptionsQuantity: self.ddOptionsQuantity,
      ddOptionsStocked: self.ddOptionsStocked,
      ddOptionsReplaced: self.ddOptionsReplaced,
      ddOptionsStatus: self.ddOptionsStatus,
      ddOptionsTransactionDate: self.ddOptionsTransactionDate,
      ddOptionsStatusDate: self.ddOptionsStatusDate,
      ddDevicePropertiesDeviceName: self.ddDevicePropertiesDeviceName,
      ddDevicePropertiesDeviceNum: self.ddDevicePropertiesDeviceNum,
      ddDevicePropertiesPropertyGroup: self.ddDevicePropertiesPropertyGroup,
      ddDevicePropertiesPropertyGroupCode:
        self.ddDevicePropertiesPropertyGroupCode,
      ddDevicePropertiesNotes: self.ddDevicePropertiesNotes,
      ddWarrantyType: self.ddWarrantyType,
      ddWarrantyDescription: self.ddWarrantyDescription,
      ddWarrantStartDate: self.ddWarrantStartDate,
      ddWarrantyEndDate: self.ddWarrantyEndDate,
      ddWarrantyStartUsage: self.ddWarrantyStartUsage,
      ddWarrantyEndUsage: self.ddWarrantyEndUsage,
      ddWorkOrderHistNum: self.ddWorkOrderHistNum,
      ddWorkOrderHistDescription: self.ddWorkOrderHistDescription,
      ddWorkOrderHistCustomerAccount: self.ddWorkOrderHistCustomerAccount,
      ddWorkOrderHistStatus: self.ddWorkOrderHistStatus,
      ddWorkOrderHistWorkOrderGrp: self.ddWorkOrderHistWorkOrderGrp,
      ddWorkOrderHistType: self.ddWorkOrderHistType,
      ddWorkOrderHistStartDate: self.ddWorkOrderHistStartDate,
      ddWorkOrderHistCreatedDate: self.ddWorkOrderHistCreatedDate,
      ddWorkOrderHistEstimatedHours: self.ddWorkOrderHistEstimatedHours,
      ddWorkOrderHistHoursRemaining: self.ddWorkOrderHistHoursRemaining,
      ddClaimHistWarrantyClaim: self.ddClaimHistWarrantyClaim,
      ddClaimHistStatus: self.ddClaimHistStatus,
      ddClaimHistApprovalStatus: self.ddClaimHistApprovalStatus,
      ddClaimHistInvoiceStatus: self.ddClaimHistInvoiceStatus,
      ddClaimHistWarrantyClaimGrp: self.ddClaimHistWarrantyClaimGrp,
      ddClaimHistType: self.ddClaimHistType,
      ddClaimHistDevice: self.ddClaimHistDevice,
      ddClaimHistPreWorkApproval: self.ddClaimHistPreWorkApproval,
      ddClaimHistUsage: self.ddClaimHistUsage,
      ddServicePlanNum: self.ddServicePlanNum,
      ddServicePlanName: self.ddServicePlanName,
      ddServicePlanStatus: self.ddServicePlanStatus,
      ddServicePlanActivation: self.ddServicePlanActivation,
      ddServicePlanReferenceDate: self.ddServicePlanReferenceDate,
      ddServicePlanContractNum: self.ddServicePlanContractNum,
      ddServicePlanGrp: self.ddServicePlanGrp,
      ddScheduledServiceDueDate: self.ddScheduledServiceDueDate,
      ddScheduledServiceUsage: self.ddScheduledServiceUsage,
      ddScheduledServiceUsageUnit: self.ddScheduledServiceUsageUnit,
      ddScheduledServiceUsageDueDate: self.ddScheduledServiceUsageDueDate,
      ddScheduledServiceName: self.ddScheduledServiceName,
      ddScheduledServiceServicePlanGrp: self.ddScheduledServiceServicePlanGrp,
      ddScheduledServiceStatus: self.ddScheduledServiceStatus,
      ddScheduledServiceStopped: self.ddScheduledServiceStopped,
      ddScheduledServiceWorkOrderNum: self.ddScheduledServiceWorkOrderNum,
      ddScheduledServiceWorkOrderStatus: self.ddScheduledServiceWorkOrderStatus,
      ddScheduledServiceClosedDate: self.ddScheduledServiceClosedDate,
      ddDocumentsName: self.ddDocumentsName,
      ddDocumentsType: self.ddDocumentsType,
      ddDocumentsNotes: self.ddDocumentsNotes,
      ddServiceContractContractId: self.ddServiceContractContractId,
      ddServiceContractDeviceId: self.ddServiceContractDeviceId,
      ddServiceContractDeviceGroupId: self.ddServiceContractDeviceGroupId,
      ddServiceContractInvoiceContractId: self.ddServiceContractInvoiceContractId,
      ddServiceContractContractType: self.ddServiceContractContractType,
      ddServiceContractCollectionStatus: self.ddServiceContractCollectionStatus,
      ddServiceContractCustAccount: self.ddServiceContractCustAccount,
      ddServiceContractName: self.ddServiceContractName
    };
  },

  createPartInquiryRequest(): IConfigurationPartInquiryRequest {
    return {
      piReleasedGeneralItemNumber: self.piReleasedGeneralItemNumber,
      piReleasedGeneralProductName: self.piReleasedGeneralProductName,
      piReleasedGeneralUnit: self.piReleasedGeneralUnit,
      piReleasedGeneralListPrice: self.piReleasedGeneralListPrice,
      piReleasedGeneralPartsImage: self.piReleasedGeneralPartsImage,
      piReleasedSetupNetWeight: self.piReleasedSetupNetWeight,
      piReleasedSetupTareWeight: self.piReleasedSetupTareWeight,
      piReleasedSetupGrossWeight: self.piReleasedSetupGrossWeight,
      piReleasedSetupGrossDepth: self.piReleasedSetupGrossDepth,
      piReleasedSetupGrossWidth: self.piReleasedSetupGrossWidth,
      piReleasedSetupGrossHeight: self.piReleasedSetupGrossHeight,
      piReleasedSetupHazardousMaterial: self.piReleasedSetupHazardousMaterial,
      piReleasedSetupBlocked: self.piReleasedSetupBlocked,
      piReleasedSetupReasonCode: self.piReleasedSetupReasonCode,
      piReleasedSetupBrand: self.piReleasedSetupBrand,
      piReleasedSetupClass: self.piReleasedSetupClass,
      piReleasedSetupModel: self.piReleasedSetupModel,
      piReleasedItemChangeHeaderItemChange:
        self.piReleasedItemChangeHeaderItemChange,
      piReleasedItemChangeHeaderItemNumber:
        self.piReleasedItemChangeHeaderItemNumber,
      piReleasedItemChangeHeaderName: self.piReleasedItemChangeHeaderName,
      piReleasedItemChangeHeaderProductName:
        self.piReleasedItemChangeHeaderProductName,
      piReleasedItemChangeHeaderDescription:
        self.piReleasedItemChangeHeaderDescription,
      piReleasedItemChangeHeaderStatus: self.piReleasedItemChangeHeaderStatus,
      piReleasedItemChangeLineItemQty: self.piReleasedItemChangeLineItemQty,
      piReleasedItemChangeLineItemNumber:
        self.piReleasedItemChangeLineItemNumber,
      piReleasedItemChangeLineName: self.piReleasedItemChangeLineName,
      piReleasedItemChangeLineUnit: self.piReleasedItemChangeLineUnit,
      piReleasedItemChangeLineUniDirectional:
        self.piReleasedItemChangeLineUniDirectional,
      piReleasedOnHandQtyItemNumber: self.piReleasedOnHandQtyItemNumber,
      piReleasedOnHandQtyName: self.piReleasedOnHandQtyName,
      piReleasedOnHandQtyConfiguration: self.piReleasedOnHandQtyConfiguration,
      piReleasedOnHandQtySize: self.piReleasedOnHandQtySize,
      piReleasedOnHandQtyColor: self.piReleasedOnHandQtyColor,
      piReleasedOnHandQtyStyle: self.piReleasedOnHandQtyStyle,
      piReleasedOnHandQtySerialNumber: self.piReleasedOnHandQtySerialNumber,
      piReleasedOnHandQtySite: self.piReleasedOnHandQtySite,
      piReleasedOnHandQtyWareHouse: self.piReleasedOnHandQtyWareHouse,
      piReleasedOnHandQtyLocation: self.piReleasedOnHandQtyLocation,
      piReleasedOnHandQtyLicensePlate: self.piReleasedOnHandQtyLicensePlate,
      piReleasedOnHandQtyQty: self.piReleasedOnHandQtyQty,
      piReleasedAlternativeItemsNumber: self.piReleasedAlternativeItemsNumber,
      piReleasedAlternativeItemsProductName:
        self.piReleasedAlternativeItemsProductName,
      piReleasedAlternativeItemsAlternativeConfig:
        self.piReleasedAlternativeItemsAlternativeConfig,
      piReleasedAlternativeItemsSize: self.piReleasedAlternativeItemsSize,
      piReleasedAlternativeItemsColor: self.piReleasedAlternativeItemsColor,
      piReleasedAlternativeItemsStyle: self.piReleasedAlternativeItemsStyle,
      piReleasedSuppItemsSuppItem: self.piReleasedSuppItemsSuppItem,
      piReleasedSuppItemsProductName: self.piReleasedSuppItemsProductName,
      piReleasedSuppItemsConfig: self.piReleasedSuppItemsConfig,
      piReleasedSuppItemsSize: self.piReleasedSuppItemsSize,
      piReleasedSuppItemsColor: self.piReleasedSuppItemsColor,
      piReleasedSuppItemsStyle: self.piReleasedSuppItemsStyle,
      piReleasedSuppItemsQty: self.piReleasedSuppItemsQty,
      piReleasedSuppItemsUnit: self.piReleasedSuppItemsUnit,
      piReleasedSuppItemsFreeOfCharge: self.piReleasedSuppItemsFreeOfCharge,
      piReleasedSuppItemsItemType: self.piReleasedSuppItemsItemType,
      piReleasedSuppItemsOnlyOnce: self.piReleasedSuppItemsOnlyOnce,
      piReleasedBomBom: self.piReleasedBomBom,
      piReleasedBomName: self.piReleasedBomName,
      piReleasedBomItemNumber: self.piReleasedBomItemNumber,
      piReleasedBomProductName: self.piReleasedBomProductName,
      piReleasedBomSubBom: self.piReleasedBomSubBom,
      piReleasedBomConfig: self.piReleasedBomConfig,
      piReleasedBomSize: self.piReleasedBomSize,
      piReleasedBomColor: self.piReleasedBomColor,
      piReleasedBomStyle: self.piReleasedBomStyle,
      piReleasedBomQty: self.piReleasedBomQty,
      piReleasedBomFromDate: self.piReleasedBomFromDate,
      piReleasedBomToDate: self.piReleasedBomToDate,
      piReleasedSalesPriceCurrency: self.piReleasedSalesPriceCurrency,
      piReleasedSalesPriceAccountSelection:
        self.piReleasedSalesPriceAccountSelection,
      piReleasedSalesPriceConfig: self.piReleasedSalesPriceConfig,
      piReleasedSalesPriceSize: self.piReleasedSalesPriceSize,
      piReleasedSalesPriceColor: self.piReleasedSalesPriceColor,
      piReleasedSalesPriceStyle: self.piReleasedSalesPriceStyle,
      piReleasedSalesPriceFromDate: self.piReleasedSalesPriceFromDate,
      piReleasedSalesPriceToDate: self.piReleasedSalesPriceToDate,
      piReleasedSalesPriceFrom: self.piReleasedSalesPriceFrom,
      piReleasedSalesPriceTo: self.piReleasedSalesPriceTo,
      piReleasedSalesPricePrice: self.piReleasedSalesPricePrice,
      piReleasedLineDiscountCurrency: self.piReleasedLineDiscountCurrency,
      piReleasedLineDiscountAccountSelection:
        self.piReleasedLineDiscountAccountSelection,
      piReleasedLineDiscountPartRelation:
        self.piReleasedLineDiscountPartRelation,
      piReleasedLineDiscountConfig: self.piReleasedLineDiscountConfig,
      piReleasedLineDiscountSize: self.piReleasedLineDiscountSize,
      piReleasedLineDiscountColor: self.piReleasedLineDiscountColor,
      piReleasedLineDiscountStyle: self.piReleasedLineDiscountStyle,
      piReleasedLineDiscountFromDate: self.piReleasedLineDiscountFromDate,
      piReleasedLineDiscountToDate: self.piReleasedLineDiscountToDate,
      piReleasedLineDiscountFrom: self.piReleasedLineDiscountFrom,
      piReleasedLineDiscountTo: self.piReleasedLineDiscountTo,
      piReleasedLineDiscountAmountInTransactionCurrency:
        self.piReleasedLineDiscountAmountInTransactionCurrency,
      piReleasedLineDiscountDiscountPct1:
        self.piReleasedLineDiscountDiscountPct1,
      piReleasedLineDiscountDiscountPct2:
        self.piReleasedLineDiscountDiscountPct2,
      piSupplierGeneralBrand: self.piSupplierGeneralBrand,
      piSupplierGeneralDiscontinued: self.piSupplierGeneralDiscontinued,
      piSupplierGeneralBlockedPrdctCreation:
        self.piSupplierGeneralBlockedPrdctCreation,
      piSupplierGeneralHeight: self.piSupplierGeneralHeight,
      piSupplierGeneralNetWeight: self.piSupplierGeneralNetWeight,
      piSupplierGeneralSuppItemNum: self.piSupplierGeneralSuppItemNum,
      piSupplierGeneralRefurbished: self.piSupplierGeneralRefurbished,
      piSupplierGeneralSalesPrice: self.piSupplierGeneralSalesPrice,
      piSupplierGeneralWidth: self.piSupplierGeneralWidth,
      piSupplierGeneralSuppItemName: self.piSupplierGeneralSuppItemName,
      piSupplierGeneralReturnItem: self.piSupplierGeneralReturnItem,
      piSupplierGeneralDepth: self.piSupplierGeneralDepth,
      piSupplierGeneralPartsImage: self.piSupplierGeneralPartsImage,
      piSupplierItemChangeHeaderItemChange:
        self.piSupplierItemChangeHeaderItemChange,
      piSupplierItemChangeHeaderSuppItemNum:
        self.piSupplierItemChangeHeaderSuppItemNum,
      piSupplierItemChangeHeaderName: self.piSupplierItemChangeHeaderName,
      piSupplierItemChangeHeaderBrandNum:
        self.piSupplierItemChangeHeaderBrandNum,
      piSupplierItemChangeHeaderDescription:
        self.piSupplierItemChangeHeaderDescription,
      piSupplierItemChangeHeaderStatus: self.piSupplierItemChangeHeaderStatus,
      piSupplierItemChangeLineBrandNumber:
        self.piSupplierItemChangeLineBrandNumber,
      piSupplierItemChangeLineSuppItemNum:
        self.piSupplierItemChangeLineSuppItemNum,
      piSupplierItemChangeLineName: self.piSupplierItemChangeLineName,
      piSupplierItemChangeLineQty: self.piSupplierItemChangeLineQty,
      piSupplierItemChangeLineUnit: self.piSupplierItemChangeLineUnit,
      piSupplierItemChangeLineUniDirectional:
        self.piSupplierItemChangeLineUniDirectional,
      piSupplierSuppItemsBrandNum: self.piSupplierSuppItemsBrandNum,
      piSupplierSuppitemsSuppItemNum: self.piSupplierSuppitemsSuppItemNum,
      piSupplierSuppItemsReturnItemBehavior:
        self.piSupplierSuppItemsReturnItemBehavior,
      piSupplierSuppItemsNum: self.piSupplierSuppItemsNum,
      piSupplierSuppItemsItemName: self.piSupplierSuppItemsItemName,
      piReleasedSupplierItemBrandNum: self.piReleasedSupplierItemBrandNum,
      piReleasedSupplierItemSuppItemNum: self.piReleasedSupplierItemSuppItemNum,
      piReleasedSupplierItemSuppWarehouse: self.piReleasedSupplierItemSuppWarehouse,
      piReleasedSupplierItemQty: self.piReleasedSupplierItemQty,
      piReleasedSupplierItemOnHandDate: self.piReleasedSupplierItemOnHandDate,
      piSupplierItemOnHandBrandNum: self.piSupplierItemOnHandBrandNum,
      piSupplierItemOnHandSuppItemNum: self.piSupplierItemOnHandSuppItemNum,
      piSupplierItemOnHandSuppWarehouse: self.piSupplierItemOnHandSuppWarehouse,
      piSupplierItemOnHandQty: self.piSupplierItemOnHandQty,
      piSupplierItemOnHandOnHandDate: self.piSupplierItemOnHandOnHandDate  
    };
  },

  createServiceCampaignRequest(): IConfigurationServiceCampaignRequest {
    return {
      scListNum: self.scListNum,
      scListManufacturerServiceCampaign: self.scListManufacturerServiceCampaign,
      scListDescription: self.scListDescription,
      scListBrand: self.scListBrand,
      scListStartDate: self.scListStartDate,
      scListEndDate: self.scListEndDate,
      scListStatus: self.scListStatus,
      scListCampaignType: self.scListCampaignType,
      scInfoGeneralNum: self.scInfoGeneralNum,
      scInfoGeneralDescription: self.scInfoGeneralDescription,
      scInfoGeneralExtendedEndDate: self.scInfoGeneralExtendedEndDate,
      scInfoGeneralNotes: self.scInfoGeneralNotes,
      scInfoGeneralStartDate: self.scInfoGeneralStartDate,
      scInfoGeneralStatus: self.scInfoGeneralStatus,
      scInfoGeneralBrandNum: self.scInfoGeneralBrandNum,
      scInfoGeneralEndDate: self.scInfoGeneralEndDate,
      scInfoDamageSymptomCode: self.scInfoDamageSymptomCode,
      scInfoDamageFailureCodeA: self.scInfoDamageFailureCodeA,
      scInfoDamageCauseCOde: self.scInfoDamageCauseCOde,
      scInfoDamageFailureCodeB: self.scInfoDamageFailureCodeB,
      scInfoDamageResolutionCode: self.scInfoDamageResolutionCode,
      scInfoDamageFailureCodeC: self.scInfoDamageFailureCodeC,
      scInfoLinesDeviceNum: self.scInfoLinesDeviceNum,
      scInfoLinesChassisNum: self.scInfoLinesChassisNum,
      scInfoLinesName: self.scInfoLinesName,
      scInfoLinesServiceCampaignStatus: self.scInfoLinesServiceCampaignStatus,
      scInfoLinesServiceCampaignCloseDate:
        self.scInfoLinesServiceCampaignCloseDate,
      scInfoDocumentsName: self.scInfoDocumentsName,
      scInfoDocumentsType: self.scInfoDocumentsType,
      scInfoDocumentsNotes: self.scInfoDocumentsNotes,
      scInfoJobListId: self.scInfoJobListId,
      scInfoJobListDescription: self.scInfoJobListDescription,
      scInfoJobListSymptomCode: self.scInfoJobListSymptomCode,
      scInfoJobListCauseCode: self.scInfoJobListCauseCode,
      scInfoJobListResolutionCode: self.scInfoJobListResolutionCode,
      scInfoJobListLaborsOperationNumber: self.scInfoJobListLaborsOperationNumber,
      scInfoJobListLaborsDescription: self.scInfoJobListLaborsDescription,
      scInfoJobListLaborsStandardHours: self.scInfoJobListLaborsStandardHours,
      scInfoJobListLaborsMultiply: self.scInfoJobListLaborsMultiply,
      scInfoJobListLaborsFixedHours: self.scInfoJobListLaborsFixedHours,
      scInfoJobListLaborsMainOperation: self.scInfoJobListLaborsMainOperation,
      scInfoJobListLaborsStart: self.scInfoJobListLaborsStart,
      scInfoJobListLaborsFinish: self.scInfoJobListLaborsFinish,
      scInfoJobListPartsNumber: self.scInfoJobListPartsNumber,
      scInfoJobListPartsDescription: self.scInfoJobListPartsDescription,
      scInfoJobListPartsQuantity: self.scInfoJobListPartsQuantity,
      scInfoJobListPartsUnit: self.scInfoJobListPartsUnit,
      scInfoJobListPartsUnitPrice: self.scInfoJobListPartsUnitPrice,
      scInfoJobListPartsDiscount: self.scInfoJobListPartsDiscount,
      scInfoJobListPartsDiscountPercentage: self.scInfoJobListPartsDiscountPercentage,
      scInfoJobListPartsTotalPrice: self.scInfoJobListPartsTotalPrice,
    };
  },

  createPartInvoicesRequest(): IConfigurationPartInvoicesRequest {
    return {
      piListInvoiceNum: self.piListInvoiceNum,
      piListSalesOrderNum: self.piListSalesOrderNum,
      piListInvoiceDate: self.piListInvoiceDate,
      piListDealerReferenceNumber: self.piListDealerReferenceNumber,
      piListInvoiceAmount: self.piListInvoiceAmount,
      piListCurrency: self.piListCurrency,
      piDetailsGeneralInvoiceNum: self.piDetailsGeneralInvoiceNum,
      piDetailsGeneralInvoiceDate: self.piDetailsGeneralInvoiceDate,
      piDetailsGeneralDealerReferenceNumber: self.piDetailsGeneralDealerReferenceNumber,
      piDetailsGeneralCurrency: self.piDetailsGeneralCurrency,
      piDetailsSalesOrderNum: self.piDetailsSalesOrderNum,
      piDetailsShipToAddress: self.piDetailsShipToAddress,
      piDetailsLinesPartNum: self.piDetailsLinesPartNum,
      piDetailsLinesPartName: self.piDetailsLinesPartName,
      piDetailsLinesDeviceRef: self.piDetailsLinesDeviceRef,
      piDetailsLinesStatus: self.piDetailsLinesStatus,
      piDetailsLinesQty: self.piDetailsLinesQty,
      piDetailsLinesUnitPrice: self.piDetailsLinesUnitPrice,
      piDetailsLinesDiscount: self.piDetailsLinesDiscount,
      piDetailsLinesDiscountPct: self.piDetailsLinesDiscountPct,
      piDetailsLinesTotalPrice: self.piDetailsLinesTotalPrice,
      piDetailsLinesSize: self.piDetailsLinesSize,
      piDetailsLinesColor: self.piDetailsLinesColor,
      piDetailsLinesConfig: self.piDetailsLinesConfig,
      piDetailsLinesStyle: self.piDetailsLinesStyle,
    };
  },

  createDeviceInvoicesRequest(): IConfigurationDeviceInvoicesRequest {
    return {
      diListInvoiceNum: self.diListInvoiceNum,
      diListSalesOrderNum: self.diListSalesOrderNum,
      diListInvoiceDate: self.diListInvoiceDate,
      diListDealerReferenceNumber: self.diListDealerReferenceNumber,
      diListInvoiceAmount: self.diListInvoiceAmount,
      diListCurrency: self.diListCurrency,
      diListDueDate: self.diListDueDate,
      diDetailsGeneralInvoiceNum: self.diDetailsGeneralInvoiceNum,
      diDetailsGeneralInvoiceDate: self.diDetailsGeneralInvoiceDate,
      diDetailsGeneralDealerReferenceNumber: self.diDetailsGeneralDealerReferenceNumber,
      diDetailsGeneralCurrency: self.diDetailsGeneralCurrency,
      diDetailsGeneralDueDate: self.diDetailsGeneralDueDate,
      diDetailsSalesOrderNum: self.diDetailsSalesOrderNum,
      diDetailsShipToAddress: self.diDetailsShipToAddress,
      diDetailsYourOrderDeviceConfigNum: self.diDetailsYourOrderDeviceConfigNum,
      diDetailsYourOrderDeviceConfigName: self.diDetailsYourOrderDeviceConfigName,
      diDetailsYourOrderDeviceNum: self.diDetailsYourOrderDeviceNum,
      diDetailsYourOrderQty: self.diDetailsYourOrderQty,
      diDetailsYourOrderUnit: self.diDetailsYourOrderUnit,
      diDetailsYourOrderUnitPrice: self.diDetailsYourOrderUnitPrice,
      diDetailsYourOrderDiscount: self.diDetailsYourOrderDiscount,
      diDetailsYourOrderDisccountPct: self.diDetailsYourOrderDisccountPct,
      diDetailsYourOrderTotalAmount: self.diDetailsYourOrderTotalAmount,
    };
  },

  createDeviceBackOrderRequest(): IConfigurationDeviceBackOrderRequest {
    return {
      dboListReqShipDate: self.dboListReqShipDate,
      dboListConfirmedShipDate: self.dboListConfirmedShipDate,
      dboListSalesOrder: self.dboListSalesOrder,
      dboListItemNum: self.dboListItemNum,
      dboListDescription: self.dboListDescription,
      dboListDeviceNum: self.dboListDeviceNum,
      dboListQty: self.dboListQty,
      dboListDeliveryReminder: self.dboListDeliveryReminder,
      dboListUnit: self.dboListUnit,
      dboListStatus: self.dboListStatus,
      deviceBackOrderDeleteOption: self.deviceBackOrderDeleteOption,
      dboListPurchaseOrderNumber: self.dboListPurchaseOrderNumber
    };
  },

  createPartBackOrderRequest(): IConfigurationPartBackOrderRequest {
    return {
      pboListReqShipDate: self.pboListReqShipDate,
      pboListConfirmedShipDate: self.pboListConfirmedShipDate,
      pboListSalesOrder: self.pboListSalesOrder,
      pboListItemNum: self.pboListItemNum,
      pboListDescription: self.pboListDescription,
      pboListDeviceNum: self.pboListDeviceNum,
      pboListQty: self.pboListQty,
      pboListDeliveryReminder: self.pboListDeliveryReminder,
      pboListUnit: self.pboListUnit,
      pboListStatus: self.pboListStatus,
      partsBackOrderDeleteOption: self.partsBackOrderDeleteOption,
      pboListPurchaseOrderNumber: self.pboListPurchaseOrderNumber
    };
  },

  createPartReturnOrderRequest(): IConfigurationPartReturnOrderRequest {
    return {
      proListWebOrderId: self.proListWebOrderId,
      proListReturnOrderNum: self.proListReturnOrderNum,
      proListRMANum: self.proListRMANum,
      proListReturnReasonCode: self.proListReturnReasonCode,
      proListCreatedBy: self.proListCreatedBy,
      proListCreatedDate: self.proListCreatedDate,
      proListStatus: self.proListStatus,
      proSummaryGeneralRMANum: self.proSummaryGeneralRMANum,
      proSummaryGeneralReturnReasonCode: self.proSummaryGeneralReturnReasonCode,
      proSummaryGeneralDealerRefNum: self.proSummaryGeneralDealerRefNum,
      proSummaryGeneralRequisition: self.proSummaryGeneralRequisition,
      proSummaryGeneralReturnOrderNum: self.proSummaryGeneralReturnOrderNum,
      proSummaryLinesPartNum: self.proSummaryLinesPartNum,
      proSummaryLinesPartName: self.proSummaryLinesPartName,
      proSummaryLinesDeviceRef: self.proSummaryLinesDeviceRef,
      proSummaryLinesStatus: self.proSummaryLinesStatus,
      proSummaryLinesQty: self.proSummaryLinesQty,
      proSummaryLinesUnitPrice: self.proSummaryLinesUnitPrice,
      proSummaryLinesDiscount: self.proSummaryLinesDiscount,
      proSummaryLinesDiscountPct: self.proSummaryLinesDiscountPct,
      proSummaryLinesTotalPrice: self.proSummaryLinesTotalPrice,
      proSummaryLinesSize: self.proSummaryLinesSize,
      proSummaryLinesColor: self.proSummaryLinesColor,
      proSummaryLinesConfig: self.proSummaryLinesConfig,
      proSummaryLinesStyle: self.proSummaryLinesStyle,
    };
  },

  createCaseRequest(): IConfigurationCaseRequest {
    return {
      cListWebCaseId: self.cListWebCaseId,
      cListCaseId: self.cListCaseId,
      cListDescription: self.cListDescription,
      cListCreatedBy: self.cListCreatedBy,
      cListCreadtedDate: self.cListCreadtedDate,
      cListStatus: self.cListStatus,
      ciGeneralCategory: self.ciGeneralCategory,
      ciGeneralStatus: self.ciGeneralStatus,
      ciGeneralCreatedDate: self.ciGeneralCreatedDate,
      ciGeneralPriority: self.ciGeneralPriority,
      ciGeneralNotes: self.ciGeneralNotes,
      ciGeneralDescription: self.ciGeneralDescription,
      ciGeneralCreatedBy: self.ciGeneralCreatedBy,
      ciGeneralDepartment: self.ciGeneralDepartment,
      ciGeneralCaseResolution: self.ciGeneralCaseResolution,
      ciGeneralCaseId: self.ciGeneralCaseId,
      ciGeneralCaseProcess: self.ciGeneralCaseProcess,
      ciGeneralParentCase: self.ciGeneralParentCase,
      ciCaseLogCreatedDate: self.ciCaseLogCreatedDate,
      ciCaseLogDescription: self.ciCaseLogDescription,
      ciCaseLogCreatedBy: self.ciCaseLogCreatedBy,
      ciCaseLogNotes: self.ciCaseLogNotes,
      ccGeneralCategoryType: self.ccGeneralCategoryType,
      ccGeneralCaseCategory: self.ccGeneralCaseCategory,
      ccGeneralParentCase: self.ccGeneralParentCase,
      ccGeneralPriority: self.ccGeneralPriority,
      ccGeneralDescription: self.ccGeneralDescription,
      ccContactName: self.ccContactName,
      ccContactEmail: self.ccContactEmail,
      ccContactPhoneNumber: self.ccContactPhoneNumber,
      ccNotes: self.ccNotes,
      ccCaseLogDescription: self.ccCaseLogDescription,
      ccCaseLogNotes: self.ccCaseLogNotes,
      ccAttachments: self.ccAttachments,
    };
  },

  createPartsQuotationRequest(): IConfigurationPartsQuotationRequest {
    return {
  pqListWebQuotationId: self.pqListWebQuotationId,
  pqListQuotationNumber: self.pqListQuotationNumber,
  pqListDealerReferenceNumber: self.pqListDealerReferenceNumber,
  pqListOrderDate: self.pqListOrderDate,
  pqListSalesOrderNumber: self.pqListSalesOrderNumber,
  pqListCreatedBy: self.pqListCreatedBy,
  pqListOrderType: self.pqListOrderType,
  pqListStatus: self.pqListStatus,
  pqListTotalAmount: self.pqListTotalAmount,
  pqDetailsDealerRequisition: self.pqDetailsDealerRequisition,
  pqDetailsDealerReferenceNumber: self.pqDetailsDealerReferenceNumber,
  pqDetailsPartsQuotationContactEmail: self.pqDetailsPartsQuotationContactEmail,
  pqDetailsPartsQuotationContactName: self.pqDetailsPartsQuotationContactName,
  pqDetailsPartsQuotationPhoneNumber: self.pqDetailsPartsQuotationPhoneNumber,
  pqDetailsQuotationNumber: self.pqDetailsQuotationNumber,
  pqDetailsStatus: self.pqDetailsStatus,
  pqDetailsQuotationDate: self.pqDetailsQuotationDate,
  pqLinesPartNumber: self.pqLinesPartNumber,
  pqLinesPartName: self.pqLinesPartName,
  pqLinesDeviceReference: self.pqLinesDeviceReference,
  pqLinesDealerReference: self.pqLinesDealerReference,
  pqLinesQuantity: self.pqLinesQuantity,
  pqLinesSite: self.pqLinesSite,
  pqLinesWarehouse: self.pqLinesWarehouse,
  pqLinesOnHand: self.pqLinesOnHand,
  pqLinesConfig: self.pqLinesConfig,
  pqLinesSize: self.pqLinesSize,
  pqLinesColor: self.pqLinesColor,
  pqLinesStyle: self.pqLinesStyle,
  pqLinesUnit: self.pqLinesUnit,
  pqLinesUnitPrice: self.pqLinesUnitPrice,
  pqLinesDiscount: self.pqLinesDiscount,
  pqLinesDiscountPercentage: self.pqLinesDiscountPercentage,
  pqLinesTotalAmount: self.pqLinesTotalAmount,
  pqLinesPartsQuotationCurrency: self.pqLinesPartsQuotationCurrency,
  pqLinesProductCatalog: self.pqLinesProductCatalog,
  pqLinesImportLines: self.pqLinesImportLines,
  pqLinesAddAttachments: self.pqLinesAddAttachments,
  pqLinesConfirmedShipDate: self.pqLinesConfirmedShipDate,
  pqLinesConfirmedReceiptDate: self.pqLinesConfirmedReceiptDate,
  pqLinesDeliverReminder: self.pqLinesDeliverReminder,
  pqCreateQuotation: self.pqCreateQuotation
    };
  },

  createDeviceQuotationRequest(): IConfigurationDeviceQuotationRequest {
    return {
      dqListWebQuotationId: self.dqListWebQuotationId,
      dqListQuotationNum: self.dqListQuotationNum,
      dqListSalesOrderNum: self.dqListSalesOrderNum,
      dqListDealerRefNum: self.dqListDealerRefNum,
      dqListDate: self.dqListDate,
      dqListCreatedBy: self.dqListCreatedBy,
      dqListStatus: self.dqListStatus,
      dqListTotalAmount: self.dqListTotalAmount,
      dqCreateQuotation: self.dqCreateQuotation,
      dqConfigurationCurrency: self.dqConfigurationCurrency,
      dqDetailsDealerReference: self.dqDetailsDealerReference,
      dqDetailsDealerRequisition: self.dqDetailsDealerRequisition,
      dqDetailsInvoiceAccount: self.dqDetailsInvoiceAccount,
      dqDetailsRequestInvoice: self.dqDetailsRequestInvoice,
      dqDetailsContactName: self.dqDetailsContactName,
      dqDetailsContactEmail: self.dqDetailsContactEmail,
      dqDetailsContactPhoneNumber: self.dqDetailsContactPhoneNumber,
      dqDetailsSalesOrderNum: self.dqDetailsSalesOrderNum,
      dqDetailsStatus: self.dqDetailsStatus,
      dqDetailsDate: self.dqDetailsDate,
      dqLinesItemNumber: self.dqLinesItemNumber,
      dqLinesDeviceName: self.dqLinesDeviceName,
      dqLinesDeviceNumber: self.dqLinesDeviceNumber,
      dqLinesConfiguration: self.dqLinesConfiguration,
      dqLinesExterior: self.dqLinesExterior,
      dqLinesInterior: self.dqLinesInterior,
      dqLinesStyle: self.dqLinesStyle,
      dqLinesQuantity: self.dqLinesQuantity,
      dqLinesUnit: self.dqLinesUnit,
      dqLinesConfirmedShipDate: self.dqLinesConfirmedShipDate,
      dqLinesConfirmedReceiptDate: self.dqLinesConfirmedReceiptDate,
      dqLinesDeliverRemainder: self.dqLinesDeliverRemainder,
      dqConfigurationItemCode: self.dqConfigurationItemCode,
      dqConfigurationConfiguration: self.dqConfigurationConfiguration,
      dqConfigurationExterior: self.dqConfigurationExterior,
      dqConfigurationInterior: self.dqConfigurationInterior,
      dqConfigurationStyle: self.dqConfigurationStyle,
      dqConfigurationCampaign: self.dqConfigurationCampaign,
      dqConfigurationOrdered: self.dqConfigurationOrdered,
      dqConfigurationInShipping: self.dqConfigurationInShipping,
      dqConfigurationInStock: self.dqConfigurationInStock,
      dqConfigurationConsignment: self.dqConfigurationConsignment,
      dqConfigurationRentalFleet: self.dqConfigurationRentalFleet
    };
  },

  clear() {
    applySnapshot(self, {});
    sessionStorage.clear();
    localStorage.removeItem("dealerConfiguration");
  },
});
