import React, { Fragment, lazy, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { HashRouter, Router } from 'react-router-dom';

import BaseRouting from './BaseRouting';
import history from './config/history';
import MessageQueue from './modules/base/components/MessageQueue';
import { activity } from './modules/base/models/Activity';
import { config } from './modules/base/models/Config';
import { AzureAuthenticationContext } from './modules/userManagement/azure/azure-authentication-context';
import { user } from './modules/userManagement/models/User';
import Loader from './sharedComponents/Loader';

const LogOutDialog = lazy(() => import('./modules/userManagement/views/components/LogOutDialog'));
const LoginDialog = lazy(() => import('./modules/userManagement/views/components/LoginDialog'));
const LoginDialogB2C = lazy(() => import('./modules/userManagement/views/components/LoginDialogB2C'));
const LoginDialogOkta = lazy(() => import('./modules/userManagement/views/components/LoginDialogOkta'));


const App = () => {
  activity.registerActivity();

  useEffect(() => {
    document.onmousedown = activity.registerActivity;
    document.onkeypress = activity.registerActivity;
    document.onscroll = activity.registerActivity;
    user.timeoutToken();
    if (user.userLoginB2C) {
    const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();
    authenticationModule.loadAuthModule();
    }
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Helmet>
        <title>{config.title || 'Dealer Portal'}</title>
        <link rel='icon' type='image/png' href={config.favIcon || 'favicon.ico'} sizes='256x256' />
      </Helmet>
      {config.useHashRouting ? (
        <HashRouter>
          <BaseRouting />
          <LoginDialog />
          <LoginDialogB2C />
          <LoginDialogOkta />
        </HashRouter>
      ) : (
        <Router history={history}>
          <BaseRouting />
          <LoginDialog />
          <LoginDialogB2C />
          <LoginDialogOkta />
        </Router>
      )}
      <LogOutDialog />
      <MessageQueue />
    </Suspense>
  );
};

export default App;
