import { OktaAuthOptions } from '@okta/okta-auth-js';
import { config } from '../../base/models/Config';

export const getOIDCConfig: () => OktaAuthOptions = () => {
  const oktaClientId = config.oktaClientId as string;
  const oktaIssuer = config.oktaIssuer as string;
  const oktaRedirectUri = config.oktaRedirectUri as string;

  return {
      clientId: oktaClientId,
      issuer: oktaIssuer,
      redirectUri: `${window.location.origin}/${oktaRedirectUri}`,
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      postLogoutRedirectUri:  `${window.location.origin}/${oktaRedirectUri}`,
      tokenManager: {
        autoRenew: true
      },
      services: {
        autoRenew: true,
        autoRemove: true,
        syncStorage: true,
      }
  };
};
