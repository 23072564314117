import A365DealerPortal from './A365DealerPortal';

const dealerPortal = new A365DealerPortal();
// @ts-ignore
const env = window._env_;

dealerPortal.setBaseUrl(env.A365_BASE_URL);

dealerPortal.setTheme({
  mainColor: env.A365_THEME_MAIN_COLOR || null,
  secondaryColor: env.A365_THEME_SECONDARY_COLOR || null,
  errorColor: env.A365_THEME_ERROR_COLOR || null,
  mainButtonColor: env.A365_THEME_MAIN_BUTTON_COLOR || null,
  secondaryButtonColor: env.A365_THEME_SECONDARY_BUTTON_COLOR || null,
  errorButtonColor: env.A365_THEME_ERROR_BUTTON_COLOR || null,
  mainFieldColor: env.A365_THEME_MAIN_FIELD_COLOR || null,
  secondaryFieldColor: env.A365_THEME_SECONDARY_FIELD_COLOR || null,
  errorFieldColor: env.A365_THEME_ERROR_FIELD_COLOR || null,
  logo: env.A365_THEME_LOGO || null,
  headerLogoStyles: env.A365_THEME_HEADER_LOGO_STYLE || null,
  loginLogoStyles: env.A365_THEME_LOGIN_LOGO_STYLE || null,
  loginImage: env.A365_THEME_LOGIN_IMAGE || null,
  frontPagePartsImage: env.A365_THEME_FRONT_PAGE_PARTS_IMAGE || null,
  frontPageDeviceImage: env.A365_THEME_FRONT_PAGE_DEVICE_IMAGE || null,
  frontPageServiceImage: env.A365_THEME_FRONT_PAGE_SERVICE_IMAGE || null,
  frontPageWarrantyImage: env.A365_THEME_FRONT_PAGE_WARRANTY_IMAGE || null,
  frontPageAdminImage: env.A365_THEME_FRONT_PAGE_ADMIN_IMAGE || null,
  frontPageCustomerIamge: env.A365_THEME_FRONT_PAGE_CUSTOMER_IMAGE || null,
  menuLogo: env.A365_THEME_MENU_LOGO || null,
  backgroundLogo: env.A365_THEME_BACKGROUND_LOGO || null
});

dealerPortal.setContactInfo({
  title: env.A365_CONTACT_INFO_TITLE || null,
  telephone: env.A365_CONTACT_INFO_TELEPHONE || null,
  email: env.A365_CONTACT_INFO_EMAIL || null,
  address: {
    name: env.A365_CONTACT_INFO_ADDRESS_NAME || null,
    addressLine: env.A365_CONTACT_INFO_ADDRESS_ADDRESSLINE || null,
    city: env.A365_CONTACT_INFO_ADDRESS_CITY || null,
    country: env.A365_CONTACT_INFO_ADDRESS_COUNTRY || null
  }
});

dealerPortal.setLanguages(env.A365_LANGUAGES || null);
dealerPortal.setDefaultNameSpace(env.A365_LANG_NAMESPACE || null);

dealerPortal.privacyLink(env.A365_PRIVACY_LINK || null);
dealerPortal.privacyText(env.A365_PRIVACY_TEXT || null);

dealerPortal.setTitle(env.A365_TITLE || '');
dealerPortal.setFavIcon(env.A365_FAVICON || '');
dealerPortal.setClientId(env.A365_B2C_DIRECTORY_APP_CLIENT_ID);
dealerPortal.setAuthority(env.A365_B2C_AUTHORITY);
dealerPortal.setPostLogoutRedirectUri(env.A365_B2C_POST_LOGOUT_REDIRECT_URI);
dealerPortal.setKnownAuthorities(env.A365_B2C_KNOWN_AUTHORITIES);
dealerPortal.setRedirectURI(env.A365_B2C_REDIRECT_URI);
dealerPortal.setScope(env.A365_B2C_SCOPES);

dealerPortal.setDisplayPortalLoginButton(env.A365_DISPLAY_LOGIN_BUTTON || 'true');
dealerPortal.setADOption(env.A365_AD_OPTION);

//Okta
dealerPortal.setOktaClientId(env.A365_OKTA_CLIENTID);
dealerPortal.setOktaIssuer(env.A365_OKTA_ISSUER);
dealerPortal.setOktaRedirectUri(env.A365_OKTA_REDIRECT_URI);
dealerPortal.setOktaOption(env.A365_OKTA_OPTION);

dealerPortal.render();
